import React, { useState } from 'react';
import { CancelOutlined, PlayCircleOutline, RefreshRounded } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem, SxProps } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useAppSelector } from 'reduxState/hooks';
import { useUpdateFeedsMutation } from 'reduxState/store/applicationManagement/api';
import { Feed } from 'reduxState/store/applicationManagement/types';
import { selectUserEmail } from 'reduxState/store/user/slice';

type Props = {
  selectedFeeds: Feed[];
  appId: string;
  children: React.ReactNode;
};

const styles: SxProps = {
  '&.disable:hover ': {
    backgroundColor: red[50],
    color: red[600],
    '& .MuiListItemIcon-root': { color: red[600] },
  },
  '&.enable:hover ': {
    backgroundColor: green[50],
    color: green[600],
    '& .MuiListItemIcon-root': { color: green[600] },
  },
};

export default function FeedActionMenu({ selectedFeeds, appId, children }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorEl);

  const [updateFeeds, result] = useUpdateFeedsMutation();
  const userEmail = useAppSelector(selectUserEmail);

  const handleRegenFeeds = () => {
    updateFeeds({
      appId,
      feeds: selectedFeeds
        .filter(f => !f.disabled)
        .map(f => ({ ...f, isPrioritized: true, modifiedAuthor: userEmail })),
    })
      .unwrap()
      .then(() => toast.success('Success!', { autoClose: 1000 }))
      .catch(() => toast.error(JSON.stringify(result.error)));

    closeMenu();
  };

  const handleDisableOrEnable = (shouldDisable: boolean) => {
    updateFeeds({
      appId,
      feeds: selectedFeeds.map(f => ({ ...f, disabled: shouldDisable, modifiedAuthor: userEmail })),
    })
      .unwrap()
      .then(() => toast.success('Success!', { autoClose: 1000 }))
      .catch(() => toast.error(JSON.stringify(result.error)));

    closeMenu();
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const isSingleFeed = selectedFeeds.length === 1;
  const feed = selectedFeeds[0];

  return (
    <div>
      <div onClick={e => setAnchorEl(e.currentTarget)}>{children}</div>
      <Menu
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={closeMenu}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <ConfirmationModal
          titleText="Rebuild feed(s)?"
          contentText="Building feeds is taxing on the system and should be done only when necessary. Disabled feeds will not be rebuilt."
          onConfirm={() => handleRegenFeeds()}
        >
          <MenuItem disabled={isSingleFeed && feed.isPrioritized}>
            <ListItemIcon>
              <RefreshRounded />
            </ListItemIcon>
            Regenerate
          </MenuItem>
        </ConfirmationModal>
        <ConfirmationModal
          titleText="Enable feed(s)?"
          contentText="This will have a direct impact on the appliction's users."
          onConfirm={() => handleDisableOrEnable(false)}
        >
          <MenuItem disabled={isSingleFeed && !feed.disabled} className="enable" sx={styles}>
            <ListItemIcon>
              <PlayCircleOutline />
            </ListItemIcon>
            Enable
          </MenuItem>
        </ConfirmationModal>
        <ConfirmationModal
          titleText="Disable feed(s)?"
          contentText="This will have a direct impact on the appliction's users."
          onConfirm={() => handleDisableOrEnable(true)}
        >
          <MenuItem disabled={isSingleFeed && feed.disabled} className="disable" sx={styles}>
            <ListItemIcon>
              <CancelOutlined />
            </ListItemIcon>
            Disable
          </MenuItem>
        </ConfirmationModal>
      </Menu>
    </div>
  );
}
