export const isPrimeNumber = (number: number): boolean => {
  if (number <= 1) return false;
  if (number === 2) return true;
  if (number % 2 === 0) return false;

  const squareRoot = Math.sqrt(number);
  for (let i = 3; i <= squareRoot; i += 2) {
    if (number % i === 0) {
      return false;
    }
  }

  return true;
};

export const generateNumberAbbreviationLabel = (tickValue: number, maxValueLength: number): string => {
  if (tickValue === 0) {
    return `${tickValue}`;
  }

  // HighestValue < $999
  if (maxValueLength <= 3) {
    return `${tickValue}`;
  }

  // HighestValue < $999k
  if (maxValueLength <= 6) {
    const newTickValue = tickValue / 1000;
    return `${newTickValue}k`;
  } else {
    // HighestValue > $1m
    const newTickValue = tickValue / 1000000;
    return `${newTickValue}m`;
  }
};

export const highestDivisorInRange = (number: number, start: number, end: number): number => {
  for (let i = Math.min(number, end); i >= start; i--) {
    if (number % i === 0) {
      return i;
    }
  }

  return -1;
};

export const generateDayLabels = (index: number, xAxisLabels: string[], xAxisLabelsLength: number): string | null => {
  const label = xAxisLabels[index];

  if (xAxisLabelsLength < 14) return label; // Always return label when length < 14
  if (index === 0 || index === xAxisLabelsLength - 1) return label; // Always return first/last plot;

  const previousLabelMonth = xAxisLabels[index - 1].split(' ')[1];
  const currentLabelStringArray = xAxisLabels[index].split(' ');
  const currentLabelDay = currentLabelStringArray[0];
  const currentLabelMonth = currentLabelStringArray[1];

  // Always add label when a there is a new month
  if (previousLabelMonth !== currentLabelMonth && currentLabelDay === '1') return label;

  if (xAxisLabelsLength <= 31) {
    const highestDivisor = highestDivisorInRange(xAxisLabelsLength - 1, 1, 5);
    return index % highestDivisor === 0 ? label : null;
  }

  if (xAxisLabelsLength <= 150) {
    if (currentLabelDay === '1' || currentLabelDay === '15') return label;
    return null;
  }

  if (currentLabelDay === '1') return label;
  return null;
};

export const generateMonthLabels = (
  index: number,
  xAxisLabels: string[],
  xAxisLabelsLength: number,
  lengthIsPrimeNumber: boolean,
): string | null => {
  const label = xAxisLabels[index];
  if (xAxisLabelsLength < 14) return label; // Always return label when length < 14
  if (index === 0 || index === xAxisLabelsLength - 1) return label; // Always return first/last plot;

  const previousLabelYear = xAxisLabels[index - 1].split(` '`)[1] || '';
  const currentLabel = xAxisLabels[index];
  const currentLabelMonth = xAxisLabels[index].split(` '`)[0];

  // Always Add label when the year changes
  if (previousLabelYear && currentLabel !== previousLabelYear && currentLabelMonth === 'Jan') return label;

  // returns the 'middle' datapoint when number is prime number
  if (lengthIsPrimeNumber) {
    const middleXAxisIndex = xAxisLabelsLength / 2;
    return index === middleXAxisIndex - 1 ? label : null;
  }
  if (xAxisLabelsLength <= 50) {
    const highestDivisor = highestDivisorInRange(xAxisLabelsLength - 1, 1, 5);
    return index % highestDivisor === 0 ? label : null;
  }

  if (xAxisLabelsLength < 100) {
    const highestDivisor = highestDivisorInRange(xAxisLabelsLength - 1, 1, 20);
    return index % highestDivisor === 0 ? label : null;
  }

  const highestDivisor = highestDivisorInRange(xAxisLabelsLength - 1, 1, 50);
  return index % highestDivisor === 0 ? label : null;
};
