import React, { FC } from 'react';
import { Button } from '@mui/material';
import './Error.scss';

interface ErrorProps {
  retry?: () => void;
  errorStatusCode?: number;
}

const Error: FC<React.PropsWithChildren<ErrorProps>> = ({ errorStatusCode, retry }) => {
  const getErrorMessage = (errorStatusCode: number | undefined): string => {
    switch (errorStatusCode) {
      case 403:
        return 'You do not have permission to access this page.';
      default:
        return 'Something went wrong.';
    }
  };

  return (
    <div className="error">
      <span>{getErrorMessage(errorStatusCode)}</span>
      <br />
      <span>
        Please contact <a href="mailto:support@wildlink.me">support@wildlink.me</a> for more information.
      </span>
      <br />
      {retry && errorStatusCode !== 403 && (
        <Button variant="contained" className="retry" onClick={retry}>
          Retry
        </Button>
      )}
    </div>
  );
};

export default Error;
