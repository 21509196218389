import React, { FC } from 'react';
import { Table, TableBody, TableContainer, TableRow, TableHead, LinearProgress } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PerformanceSummaryColumnHeader } from 'components/PerformanceSummary/PerformanceSummaryColumnHeader/PerformanceSummaryColumnHeader';
import PerformanceSummaryItemV2 from 'components/PerformanceSummary/PerformanceSummaryItem/PerformanceSummaryItem';
import { useAppDispatch, useAppSelector } from 'reduxState/hooks';
import { initialNonAPIColumns } from 'reduxState/store/performanceSummary/constants'; // TODO: temporary import
import { performanceSummaryFilterValuesSelector } from 'reduxState/store/performanceSummary/selectors';
import { setFilterValues } from 'reduxState/store/performanceSummary/slice';
import { PerformanceSummaryColumn } from 'reduxState/store/performanceSummary/types';
import { PerformanceSummaryReportSumByApp, PerformanceSummaryReport } from 'reduxState/store/performanceSummary/utils';
import './PerformanceSummaryTable.scss';

interface PerformanceSummaryTableProps {
  adminAppId: string;
  selectedApps: string[];
  flattenedAppGroupPerformanceSummary: PerformanceSummaryReport[] | PerformanceSummaryReportSumByApp[];
  viewByApplication: boolean;
  currency: string;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}

const PerformanceSummaryTable: FC<React.PropsWithChildren<PerformanceSummaryTableProps>> = ({
  adminAppId,
  selectedApps,
  flattenedAppGroupPerformanceSummary,
  viewByApplication,
  currency,
  isLoading,
  error,
}) => {
  const dispatch = useAppDispatch();
  const { sortBy, sortOrder, groupBy } = useAppSelector(performanceSummaryFilterValuesSelector);

  const onSortChange = (queryName: string) => {
    if (sortBy === queryName) {
      dispatch(
        setFilterValues({
          sortOrder: sortOrder === 'desc' ? 'asc' : 'desc',
        }),
      );
    } else {
      dispatch(
        setFilterValues({
          sortBy: queryName,
          sortOrder: 'desc',
        }),
      );
    }
  };

  // TODO: temporary, needed columns to display. This will be adjusted when we tie in the backend
  const performanceSummaryHeaders = initialNonAPIColumns.reduce(
    (acc: PerformanceSummaryColumn[], column: PerformanceSummaryColumn, index: number) => {
      // replace ReportDate with Application Name, since viewByApplication doesn't need report date
      if (viewByApplication && column.accessor === 'ReportDate') {
        acc.push({
          accessor: 'ApplicationName',
          name: 'Application Name',
          visible: true,
          queryName: 'application_name',
        });
      } else if (column.visible) {
        acc.push({ ...column });
      }

      return acc;
    },
    [],
  );

  const appGroupPerformanceSummary =
    flattenedAppGroupPerformanceSummary && selectedApps.length !== 0 ? flattenedAppGroupPerformanceSummary : [];
  return (
    <TableContainer className="table-container app-group-table-container">
      <Table>
        <TableHead className="table-header">
          <TableRow className="table-header-row">
            {performanceSummaryHeaders.map(({ accessor, name, queryName }) => (
              <PerformanceSummaryColumnHeader
                key={accessor}
                accessor={accessor}
                name={name}
                queryName={queryName}
                sortBy={sortBy}
                sortOrder={sortOrder}
                groupBy={groupBy}
                sortPerformanceSummary={onSortChange}
                adminAppId={adminAppId}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            appGroupPerformanceSummary.map((stat, index) => (
              <PerformanceSummaryItemV2
                key={`${index}`}
                summaryItem={stat}
                columns={performanceSummaryHeaders}
                groupBy={groupBy}
                currency={currency}
              />
            ))}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress />}
      {!isLoading && appGroupPerformanceSummary.length === 0 && (
        <div className="no-stats">
          {error
            ? 'Failed to retrieve summary, please try again.'
            : 'No performance summaries for selected dates and/or apps.'}
        </div>
      )}
    </TableContainer>
  );
};

export default PerformanceSummaryTable;
