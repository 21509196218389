import { MultipleSelectOption } from 'components/MultipleSelectDropdown/MultipleSelectDropdown';

export enum GroupedCountries {
  US = 'US + US Territories',
  SOUTH_EAST_ASIA = 'South East Asia',
  EUROPE = 'Europe',
  ALL_COUNTRIES = 'All Countries',
}
export const countryCodeOptions: MultipleSelectOption[] = [
  { key: 8, value: 'AL', label: '8 - AL - Albania', group: 'Countries' },
  { key: 12, value: 'DZ', label: '12 - DZ - Algeria', group: 'Countries' },
  { key: 16, value: 'AS', label: '16 - AS - American Samoa', group: 'Countries' },
  { key: 20, value: 'AD', label: '20 - AD - Andorra', group: 'Countries' },
  { key: 24, value: 'AO', label: '24 - AO - Angola', group: 'Countries' },
  { key: 660, value: 'AI', label: '660 - AI - Anguilla', group: 'Countries' },
  { key: 10, value: 'AQ', label: '10 - AQ - Antarctica', group: 'Countries' },
  { key: 28, value: 'AG', label: '28 - AG - Antigua and Barbuda', group: 'Countries' },
  { key: 32, value: 'AR', label: '32 - AR - Argentina', group: 'Countries' },
  { key: 51, value: 'AM', label: '51 - AM - Armenia', group: 'Countries' },
  { key: 533, value: 'AW', label: '533 - AW - Aruba', group: 'Countries' },
  { key: 36, value: 'AU', label: '36 - AU - Australia', group: 'Countries' },
  { key: 40, value: 'AT', label: '40 - AT - Austria', group: 'Countries' },
  { key: 31, value: 'AZ', label: '31 - AZ - Azerbaijan', group: 'Countries' },
  { key: 44, value: 'BS', label: '44 - BS - Bahamas', group: 'Countries' },
  { key: 48, value: 'BH', label: '48 - BH - Bahrain', group: 'Countries' },
  { key: 50, value: 'BD', label: '50 - BD -  Bangadesh', group: 'Countries' },
  { key: 52, value: 'BB', label: '52 - BB -Barbados', group: 'Countries' },
  { key: 112, value: 'BY', label: '112 - BY - Belarus', group: 'Countries' },
  { key: 56, value: 'BE', label: '56 - BE - Belgium', group: 'Countries' },
  { key: 84, value: 'BZ', label: '84 - BZ - Belize', group: 'Countries' },
  { key: 204, value: 'BJ', label: '204 - BJ - Benin', group: 'Countries' },
  { key: 60, value: 'BM', label: '60 - BM - Bermuda', group: 'Countries' },
  { key: 64, value: 'BT', label: '64 - BT - Bhutan', group: 'Countries' },
  { key: 68, value: 'BO', label: '68 - BO - Bolivia', group: 'Countries' },
  { key: 70, value: 'BA', label: '70 - BA - Bosnia and Herzegovina', group: 'Countries' },
  { key: 72, value: 'BW', label: '72 - BW - Botswana', group: 'Countries' },
  { key: 74, value: 'BV', label: '74 - BV Bouvet Island', group: 'Countries' },
  { key: 76, value: 'BR', label: '76 - BR - Brazil', group: 'Countries' },
  { key: 86, value: 'IO', label: '86 - IO - British Indian Ocean Territory', group: 'Countries' },
  { key: 96, value: 'BN', label: '96 - BN - Brunei Darussalam', group: 'Countries' },
  { key: 100, value: 'BG', label: '100 - BG - Bulgaria', group: 'Countries' },
  { key: 854, value: 'BF', label: '854 - BF - Burkina Faso', group: 'Countries' },
  { key: 108, value: 'BI', label: '108 - BI - Burundi', group: 'Countries' },
  { key: 116, value: 'KH', label: '116 - KH - Cambodia', group: 'Countries' },
  { key: 120, value: 'CM', label: '120 - CM - Cameroon', group: 'Countries' },
  { key: 124, value: 'CA', label: '124 - CA - Canada', group: 'Countries' },
  { key: 132, value: 'CV', label: '132 - CV - Cape Verde', group: 'Countries' },
  { key: 136, value: 'KY', label: '136 - KY - Cayman Islands', group: 'Countries' },
  { key: 140, value: 'CF', label: '140 - CF - Central African Republic', group: 'Countries' },
  { key: 148, value: 'TD', label: '148 - TD - Chad', group: 'Countries' },
  { key: 152, value: 'CL', label: '152 - CL - Chile', group: 'Countries' },
  { key: 156, value: 'CN', label: '156 - CN - China', group: 'Countries' },
  { key: 162, value: 'CX', label: '162 - CX - Christmas Island', group: 'Countries' },
  { key: 166, value: 'CC', label: '166 - CC - Cocos (Keeling) Islands', group: 'Countries' },
  { key: 170, value: 'CO', label: '170 - CO - Colombia', group: 'Countries' },
  { key: 174, value: 'KM', label: '174 - KM - Comoros', group: 'Countries' },
  { key: 178, value: 'CG', label: '178 - CG - Congo', group: 'Countries' },
  { key: 180, value: 'CD', label: '180 - CD - Democratic Republic of the Congo', group: 'Countries' },
  { key: 184, value: 'CK', label: '184 - CK - Cook Islands', group: 'Countries' },
  { key: 188, value: 'CR', label: '188 - CR - Costa Rica', group: 'Countries' },
  { key: 384, value: 'CI', label: `384 - CI - Côte d'Ivoire`, group: 'Countries' },
  { key: 191, value: 'HR', label: '191 - HR - Croatia', group: 'Countries' },
  { key: 192, value: 'CU', label: '192 - CU - Cuba', group: 'Countries' },
  { key: 196, value: 'CY', label: '196 - CY - Cyprus', group: 'Countries' },
  { key: 203, value: 'CZ', label: '203 - CZ - Czech Republic', group: 'Countries' },
  { key: 208, value: 'DK', label: '208 - DK - Denmark', group: 'Countries' },
  { key: 262, value: 'DJ', label: '262 - DJ - Djibouti', group: 'Countries' },
  { key: 212, value: 'DM', label: '212 - DM - Dominica', group: 'Countries' },
  { key: 214, value: 'DO', label: '214 - DO - Dominican Republic', group: 'Countries' },
  { key: 218, value: 'EC', label: '218 - EC - Ecuador', group: 'Countries' },
  { key: 818, value: 'EG', label: '818 - EG - Egypt', group: 'Countries' },
  { key: 222, value: 'SV', label: '222 - SV - El Salvador', group: 'Countries' },
  { key: 226, value: 'GQ', label: '226 - GQ - Equatorial Guinea', group: 'Countries' },
  { key: 232, value: 'ER', label: '232 - ER - Eritrea', group: 'Countries' },
  { key: 233, value: 'EE', label: '233 - EE - Estonia', group: 'Countries' },
  { key: 231, value: 'ET', label: '231 - ET - Ethiopia', group: 'Countries' },
  { key: 238, value: 'FK', label: '238 - FK - Falkland Islands (Malvinas)', group: 'Countries' },
  { key: 242, value: 'FJ', label: '242 - FJ - Fiji', group: 'Countries' },
  { key: 246, value: 'FI', label: '246 - FI - Finland', group: 'Countries' },
  { key: 234, value: 'FO', label: '234 - FO - Faroe Islands', group: 'Countries' },
  { key: 250, value: 'FR', label: '250 - FR - France', group: 'Countries' },
  { key: 254, value: 'GF', label: '254 - GF - French Guiana', group: 'Countries' },
  { key: 258, value: 'PF', label: '258 - PF - French Polynesia', group: 'Countries' },
  { key: 260, value: 'TF', label: '260 - TF - French Southern Territories', group: 'Countries' },
  { key: 266, value: 'GA', label: '266 - GA - Gabon', group: 'Countries' },
  { key: 270, value: 'GM', label: '270 - GM - Gambia', group: 'Countries' },
  { key: 268, value: 'GE', label: '268 - GE - Georgia', group: 'Countries' },
  { key: 276, value: 'DE', label: '276 - DE - Germany', group: 'Countries' },
  { key: 288, value: 'GH', label: '288 - GH - Ghana', group: 'Countries' },
  { key: 292, value: 'GI', label: '292 - GI - Gibraltar', group: 'Countries' },
  { key: 300, value: 'GR', label: '300 - GR - Greece', group: 'Countries' },
  { key: 304, value: 'GL', label: '304 - GL - Greenland', group: 'Countries' },
  { key: 308, value: 'GD', label: '308 - GD - Grenada', group: 'Countries' },
  { key: 312, value: 'GP', label: '312 - GP - Guadeloupe', group: 'Countries' },
  { key: 316, value: 'GU', label: '316 - GU - Guam', group: 'Countries' },
  { key: 320, value: 'GT', label: '320 - GT - Guatemala', group: 'Countries' },
  { key: 324, value: 'GN', label: '324 - GN - Guinea', group: 'Countries' },
  { key: 624, value: 'GW', label: '624 - GW - Guinea-Bissau', group: 'Countries' },
  { key: 328, value: 'GY', label: '328 - GY - Guyana', group: 'Countries' },
  { key: 332, value: 'HT', label: '332 - HT - Haiti', group: 'Countries' },
  { key: 334, value: 'HM', label: '334 - HM - Heard Island and McDonald Islands', group: 'Countries' },
  { key: 340, value: 'HN', label: '340 - HN - Honduras', group: 'Countries' },
  { key: 344, value: 'HK', label: '344 - HK - Hong Kong', group: 'Countries' },
  { key: 348, value: 'HU', label: '348 - HU - Hungary', group: 'Countries' },
  { key: 352, value: 'IS', label: '352 - IS - Iceland', group: 'Countries' },
  { key: 356, value: 'IN', label: '356 - IN - India', group: 'Countries' },
  { key: 360, value: 'ID', label: '360 - ID - Indonesia', group: 'Countries' },
  { key: 364, value: 'IR', label: '364 - IR - Iran', group: 'Countries' },
  { key: 368, value: 'IQ', label: '368 - IQ - Iraq', group: 'Countries' },
  { key: 372, value: 'IE', label: '372 - IE - Ireland', group: 'Countries' },
  { key: 376, value: 'IL', label: '376 - IL - Israel', group: 'Countries' },
  { key: 380, value: 'IT', label: '380 - IT - Italy', group: 'Countries' },
  { key: 388, value: 'JM', label: '388 - JM - Jamaica', group: 'Countries' },
  { key: 392, value: 'JP', label: '392 - JP - Japan', group: 'Countries' },
  { key: 400, value: 'JO', label: '400 - JO - Jordan', group: 'Countries' },
  { key: 398, value: 'KZ', label: '398 - KZ - Kazakhstan', group: 'Countries' },
  { key: 404, value: 'KE', label: '404 - KE - Kenya', group: 'Countries' },
  { key: 296, value: 'KI', label: '296 - KI - Kiribati', group: 'Countries' },
  { key: 410, value: 'KR', label: '410 - KR - South Korea', group: 'Countries' },
  { key: 408, value: 'KP', label: '408 - KP - North Korea', group: 'Countries' },
  { key: 414, value: 'KW', label: '414 - KW - Kuwait', group: 'Countries' },
  { key: 417, value: 'KG', label: '417 - KG - Kyrgyzstan', group: 'Countries' },
  { key: 418, value: 'LA', label: '418 - LA - Laos', group: 'Countries' },
  { key: 428, value: 'LV', label: '428 - LV - Latvia', group: 'Countries' },
  { key: 422, value: 'LB', label: '422 - LB - Lebanon', group: 'Countries' },
  { key: 426, value: 'LS', label: '426 - LS - Lesotho', group: 'Countries' },
  { key: 430, value: 'LR', label: '430 - LR - Liberia', group: 'Countries' },
  { key: 434, value: 'LY', label: '434 - LY - Libya', group: 'Countries' },
  { key: 438, value: 'LI', label: '438 - LI - Liechtenstein', group: 'Countries' },
  { key: 440, value: 'LT', label: '440 - LT - Lithuania', group: 'Countries' },
  { key: 442, value: 'LU', label: '442 - LU - Luxembourg', group: 'Countries' },
  { key: 446, value: 'MO', label: '446 - MO - Macao', group: 'Countries' },
  { key: 807, value: 'MK', label: '807 - MK - North Macedonia', group: 'Countries' },
  { key: 450, value: 'MG', label: '450 - MG - Madagascar', group: 'Countries' },
  { key: 454, value: 'MW', label: '454 - MW - Malawi', group: 'Countries' },
  { key: 458, value: 'MY', label: '458 - MY - Malaysia', group: 'Countries' },
  { key: 462, value: 'MV', label: '462 - MV - Maldives', group: 'Countries' },
  { key: 466, value: 'ML', label: '466 - ML - Mali', group: 'Countries' },
  { key: 470, value: 'MT', label: '470 - MT - Malta', group: 'Countries' },
  { key: 584, value: 'MH', label: '584 - MH - Marshall Islands', group: 'Countries' },
  { key: 474, value: 'MQ', label: '474 - MQ - Martinique', group: 'Countries' },
  { key: 478, value: 'MR', label: '478 - MR - Mauritania', group: 'Countries' },
  { key: 480, value: 'MU', label: '480 - MU - Mauritius', group: 'Countries' },
  { key: 175, value: 'YT', label: '175 - YT - Mayotte', group: 'Countries' },
  { key: 484, value: 'MX', label: '484 - MX - Mexico', group: 'Countries' },
  { key: 583, value: 'FM', label: '583 - FM - Micronesia', group: 'Countries' },
  { key: 498, value: 'MD', label: '498 - MD - Moldova', group: 'Countries' },
  { key: 492, value: 'MC', label: '492 - MC - Monaco', group: 'Countries' },
  { key: 496, value: 'MN', label: '496 - MN - Mongolia', group: 'Countries' },
  { key: 500, value: 'MS', label: '500 - MS - Montserrat', group: 'Countries' },
  { key: 504, value: 'MA', label: '504 - MA - Morocco', group: 'Countries' },
  { key: 508, value: 'MZ', label: '508 - MZ - Mozambique', group: 'Countries' },
  { key: 104, value: 'MM', label: '104 - MM - Myanmar', group: 'Countries' },
  { key: 516, value: 'NA', label: '516 - NA - Namibia', group: 'Countries' },
  { key: 520, value: 'NR', label: '520 - NR - Nauru', group: 'Countries' },
  { key: 524, value: 'NP', label: '524 - NP - Nepal', group: 'Countries' },
  { key: 528, value: 'NL', label: '528 - NL - Netherlands', group: 'Countries' },
  { key: 530, value: 'AN', label: '530 - AN', group: 'Countries' },
  { key: 540, value: 'NC', label: '540 - NC - New Caledonia', group: 'Countries' },
  { key: 554, value: 'NZ', label: '554 - NZ - New Zealand', group: 'Countries' },
  { key: 558, value: 'NI', label: '558 - NI - Nicaragua', group: 'Countries' },
  { key: 562, value: 'NE', label: '562 - NE - Niger', group: 'Countries' },
  { key: 566, value: 'NG', label: '566 - NG - Nigeria', group: 'Countries' },
  { key: 570, value: 'NU', label: '570 - NU - Niue', group: 'Countries' },
  { key: 574, value: 'NF', label: '574 - NF - Norfolk Island', group: 'Countries' },
  { key: 580, value: 'MP', label: '580 - MP - Northern Mariana Islands', group: 'Countries' },
  { key: 578, value: 'NO', label: '578 - NO - Norway', group: 'Countries' },
  { key: 512, value: 'OM', label: '512 - OM - Oman', group: 'Countries' },
  { key: 586, value: 'PK', label: '586 - PK - Pakistan', group: 'Countries' },
  { key: 585, value: 'PW', label: '585 - PW - Palau', group: 'Countries' },
  { key: 275, value: 'PS', label: '275 - PS - Palestine', group: 'Countries' },
  { key: 591, value: 'PA', label: '591 - PA - Panama', group: 'Countries' },
  { key: 598, value: 'PG', label: '598 - PG - Papua New Guinea', group: 'Countries' },
  { key: 600, value: 'PY', label: '600 - PY - Paraguay', group: 'Countries' },
  { key: 604, value: 'PE', label: '604 - PE - Peru', group: 'Countries' },
  { key: 608, value: 'PH', label: '608 - PH - Philippines', group: 'Countries' },
  { key: 612, value: 'PN', label: '612 - PN - Pitcairn', group: 'Countries' },
  { key: 616, value: 'PL', label: '616 - PL - Poland', group: 'Countries' },
  { key: 620, value: 'PT', label: '620 - PT - Portugal', group: 'Countries' },
  { key: 630, value: 'PR', label: '630 - PR - Puerto Rico', group: 'Countries' },
  { key: 634, value: 'QA', label: '634 - QA - Qatar', group: 'Countries' },
  { key: 638, value: 'RE', label: '638 - RE - Réunion', group: 'Countries' },
  { key: 642, value: 'RO', label: '642 - RO - Romania', group: 'Countries' },
  { key: 643, value: 'RU', label: '643 - RU - Russia', group: 'Countries' },
  { key: 646, value: 'RW', label: '646 - RW - Rwanda', group: 'Countries' },
  { key: 654, value: 'SH', label: '654 - SH - Saint Helena', group: 'Countries' },
  { key: 659, value: 'KN', label: '659 - KN - Saint Kitts and Nevis', group: 'Countries' },
  { key: 662, value: 'LC', label: '662 - LC - Saint Lucia', group: 'Countries' },
  { key: 666, value: 'PM', label: '666 - PM - Saint Pierre and Miquelon', group: 'Countries' },
  { key: 670, value: 'VC', label: '670 - VC - Saint Vincent and the Grenadines', group: 'Countries' },
  { key: 882, value: 'WS', label: '882 - WS - Samoa', group: 'Countries' },
  { key: 674, value: 'SM', label: '674 - SM - San Marino', group: 'Countries' },
  { key: 678, value: 'ST', label: '678 - ST - São Tomé and Príncipe', group: 'Countries' },
  { key: 682, value: 'SA', label: '682 - SA - Saudi Arabia', group: 'Countries' },
  { key: 686, value: 'SN', label: '686 - SN - Senegal', group: 'Countries' },
  { key: 690, value: 'SC', label: '690 - SC - Seychelles', group: 'Countries' },
  { key: 694, value: 'SL', label: '694 - SL - Sierre Leone', group: 'Countries' },
  { key: 702, value: 'SG', label: '702 - SG - Singapore', group: 'Countries' },
  { key: 703, value: 'SK', label: '703 - SK - Slovakia', group: 'Countries' },
  { key: 705, value: 'SI', label: '705 - SI - Slovania', group: 'Countries' },
  { key: 90, value: 'SB', label: '90 - SB - Solomon Islands', group: 'Countries' },
  { key: 706, value: 'SO', label: '706 - SO - Somalia', group: 'Countries' },
  { key: 710, value: 'ZA', label: '710 - ZA - South Africa', group: 'Countries' },
  { key: 239, value: 'GS', label: '239 - GS - South Georgia and the South Sandwich Islands', group: 'Countries' },
  { key: 724, value: 'ES', label: '724 - ES - Spain', group: 'Countries' },
  { key: 144, value: 'LK', label: '144 - LK - Sri Lanka', group: 'Countries' },
  { key: 736, value: 'SD', label: '736 - SD - Sudan', group: 'Countries' },
  { key: 740, value: 'SR', label: '740 - SR - Suriname', group: 'Countries' },
  { key: 744, value: 'SJ', label: '744 - SJ - Svalbard and Jan Mayen', group: 'Countries' },
  { key: 748, value: 'SZ', label: '748 - SZ - Eswatini', group: 'Countries' },
  { key: 752, value: 'SE', label: '752 - SE - Sweden', group: 'Countries' },
  { key: 756, value: 'CH', label: '756 - CH - Switzerland', group: 'Countries' },
  { key: 760, value: 'SY', label: '760 - SY - Syria', group: 'Countries' },
  { key: 158, value: 'TW', label: '158 - TW - Taiwan', group: 'Countries' },
  { key: 762, value: 'TJ', label: '762 - TJ - Tajikstan', group: 'Countries' },
  { key: 834, value: 'TZ', label: '834 - TZ - Tanzania', group: 'Countries' },
  { key: 764, value: 'TH', label: '764 - TH - Thailand', group: 'Countries' },
  { key: 626, value: 'TL', label: '626 - TL - Timor-Leste', group: 'Countries' },
  { key: 768, value: 'TG', label: '768 - TG - Togo', group: 'Countries' },
  { key: 772, value: 'TK', label: '772 - TK - Tokelau', group: 'Countries' },
  { key: 776, value: 'TO', label: '776 - TO - Tonga', group: 'Countries' },
  { key: 780, value: 'TT', label: '780 - TT - Trinidad and Tobago - TT', group: 'Countries' },
  { key: 788, value: 'TN', label: '788 - TN - Tunisia', group: 'Countries' },
  { key: 792, value: 'TR', label: '792 - TR - Turkey', group: 'Countries' },
  { key: 795, value: 'TM', label: '795 - TM - Turkmenistan', group: 'Countries' },
  { key: 796, value: 'TC', label: '796 - TC - Turks and Caicos Islands', group: 'Countries' },
  { key: 798, value: 'TV', label: '798 - TV - Tuvalu', group: 'Countries' },
  { key: 800, value: 'UG', label: '800 - UG - Uganda', group: 'Countries' },
  { key: 804, value: 'UA', label: '804 - UA - Ukraine', group: 'Countries' },
  { key: 784, value: 'AE', label: '784 - AE - United Arab Emirates', group: 'Countries' },
  { key: 826, value: 'GB', label: '826 - GB - United Kingdom', group: 'Countries' },
  { key: 840, value: 'US', label: '840 - US - United States', group: 'Countries' },
  { key: 581, value: 'UM', label: '581 - UM - United States Minor Outlying Islands', group: 'Countries' },
  { key: 858, value: 'UY', label: '858 - UY - Uruguay', group: 'Countries' },
  { key: 860, value: 'UZ', label: '860 - UZ - Uzbekistan', group: 'Countries' },
  { key: 548, value: 'VU', label: '548 - VU - Vanuatu', group: 'Countries' },
  { key: 336, value: 'VA', label: '336 - VA - Vatican City', group: 'Countries' },
  { key: 862, value: 'VE', label: '862 - VE - Venezuela', group: 'Countries' },
  { key: 704, value: 'VN', label: '704 - VN - Vietnam', group: 'Countries' },
  { key: 92, value: 'VG', label: '92 - VG - British Virgin Islands', group: 'Countries' },
  { key: 850, value: 'VI', label: '850 - VI - U.S Virgin Islands', group: 'Countries' },
  { key: 876, value: 'WF', label: '876 - WF - Wallis and Futuna', group: 'Countries' },
  { key: 732, value: 'EH', label: '732 - EH - Western Sahara', group: 'Countries' },
  { key: 887, value: 'YE', label: '887 - YE - Yemen', group: 'Countries' },
  { key: 891, value: 'YU', label: '891 - YU - Yugoslavia', group: 'Countries' },
  { key: 894, value: 'ZM', label: '894 - ZM - Zambia', group: 'Countries' },
  { key: 716, value: 'ZW', label: '716 - ZW - Zimbabwe', group: 'Countries' },
  { key: 4, value: 'AF', label: '4 - AF - Afghanistan', group: 'Countries' },
  { key: 688, value: 'RS', label: '688 - RS - Serbia', group: 'Countries' },
  { key: 248, value: 'AX', label: '248 - AX - Aland Islands', group: 'Countries' },
  { key: 535, value: 'BQ', label: '535 - BQ - Caribbean Netherlands', group: 'Countries' },
  { key: 831, value: 'GG', label: '831 - GG - Guernsey', group: 'Countries' },
  { key: 832, value: 'JE', label: '832 - JE - Jersey', group: 'Countries' },
  { key: 531, value: 'CW', label: '531 - CW - Curaçao', group: 'Countries' },
  { key: 833, value: 'IM', label: '833 - IM - Isle of Man', group: 'Countries' },
  { key: 652, value: 'BL', label: '652 - BL - Saint Barthélemy ', group: 'Countries' },
  { key: 663, value: 'MF', label: '663 - MF - Saint Martin', group: 'Countries' },
  { key: 534, value: 'SX', label: '534 - SX - Sint Maarten', group: 'Countries' },
  { key: 499, value: 'ME', label: '499 - ME - Montenegro', group: 'Countries' },
  { key: 728, value: 'SS', label: '728 - SS - South Sudan', group: 'Countries' },
  { key: 900, value: 'XK', label: '900 - XK - Kosovo', group: 'Countries' },
];

export const groupedCountryCodeOptions = [
  {
    key: 999999998,
    value: GroupedCountries.ALL_COUNTRIES,
    label: GroupedCountries.ALL_COUNTRIES,
    group: 'Grouped Countries',
  },
];

// South East Asia
export const seaCountryCodeValues = ['BN', 'KH', 'TL', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'VN'];
// US + US Territories
export const unitedStateCountryCodeValues = ['AS', 'GU', 'MP', 'PR', 'VI'];
// Europe Country Codes
export const europeCountryCodeValues = [
  'AL',
  'AD',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UA',
  'GB',
  'VA',
];

export const allCountryCodeValues = [
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FJ',
  'FI',
  'FO',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KR',
  'KP',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'AN',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'SH',
  'KN',
  'LC',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'YU',
  'ZM',
  'ZW',
  'AF',
  'RS',
  'AX',
  'BQ',
  'GG',
  'JE',
  'CW',
  'IM',
  'BL',
  'MF',
  'SX',
  'ME',
  'SS',
  'XK',
];
