import React, { FC, ReactElement } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { generateMultiAxisChartData } from './helpers/multiAxisChartData';
import { generateMultiAxisChartOptions } from './helpers/multiAxisChartOptions';
import { DoubleAxisChartData } from '../types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface MultiAxisLineChartProps {
  data: DoubleAxisChartData;
  applicationCurrency: string;
  groupBy: string;
}

/**
 * Represents a Double Axis Line Chart with a X and Y axis that will show a comparisons between two data sets [y1 and y2]
 * over time. It will generate X axis labels based off application currency type and groupBy. The chart can display either
 * currency amounts or general total amounts. Like "Total Clicks"
 *
 * This component is currently only being used in Performance Summary. And can only represent data for `day` and `month` groupBy values.
 *
 * Data Object
 * @param data.labels - An array of strings that will be used for the X-Axis
 * @param data.y1 - An Object that contains the following properties to represent the Y1 axis: dataPlots, label, maxValue, stepSize
 * @param data.y2 - An Object that contains the following properties to represent the Y2 axis: dataPlots, label, maxValue, stepSize
 *
 * For y1/y2 objects
 * @param data.y1.dataPlots - An array of numbers
 * @param data.y1.label - A string to represent what the numerical values from dataPlots represent
 * @param data.y1.maxValue - A number that will be the maximum value for the chart for the respective Y-axis
 * @param data.y1.stepSize - A number that will generate a horizontal line across the chart until it hits the 'maxValue'. For example,
 * if step size is 200 & maxValue is 1000 , it will create a horizontal line every 200 units until it reaches 1000.
 *
 * @param param.applicationCurrency - String value that represents currency type, for example: "USD"
 * @param param.groupBy - String value to represent group by, either 'day' | 'month'
 * @example
 * <MultiAxisLineChart
 *  data={{
 *    labels: ['15-Jan', '16-Jan', '17-Jan'],
 *    y1: {
 *      dataPlots: [4, 400, 300]
 *      label: 'Gross Commissions'
 *      maxValue: 500,
 *      stepSize: 100,
 *    },
 *     y2: {
 *      dataPlots: [100, 500, 900]
 *      label: 'Gross Sales'
 *      maxValue: 1000,
 *      stepSize: 200,
 *    },
 *  }}
 *  applicationCurrency="USD"
 *  groupBy="day"
 * />
 */

const MultiAxisLineChart: FC<React.PropsWithChildren<MultiAxisLineChartProps>> = ({
  data,
  applicationCurrency,
  groupBy,
}): ReactElement => {
  const { labels, y1, y2 } = data;

  const y1ChartData = {
    label: y1.label,
    dataPlots: y1.dataPlots,
  };
  const y2ChartData = {
    label: y2.label,
    dataPlots: y2.dataPlots,
  };

  const y1ChartOptions = {
    maxValue: y1.maxValue,
    stepSize: y1.stepSize,
  };
  const y2ChartOptions = {
    maxValue: y2.maxValue,
    stepSize: y2.stepSize,
  };
  const chartData = generateMultiAxisChartData(labels, y1ChartData, y2ChartData);
  const chartOptions = generateMultiAxisChartOptions(
    y1ChartOptions,
    y2ChartOptions,
    chartData,
    labels,
    applicationCurrency,
    groupBy,
  );

  return <Line options={chartOptions} data={chartData} />;
};

export default MultiAxisLineChart;
