import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxState';
import { PerformanceSummaryState } from './types';

export const selectPerformanceSummaryState = (state: RootState): PerformanceSummaryState => state.performanceSummary;

export const statSummaryColumnsSelector = createSelector(
  selectPerformanceSummaryState,
  (_: RootState, groupBy: 'selectedMerchant' | 'groupByMerchant' | 'apiDateColumns' | 'nonAPIDateColumns') => groupBy,
  (performanceSummaryState, groupBy) => performanceSummaryState.columns[groupBy],
);

export const performanceSummaryFilterValuesSelector = createSelector(
  selectPerformanceSummaryState,
  (performanceSummaryState: PerformanceSummaryState) => performanceSummaryState.filterValues,
);
export const performanceSummaryActiveLineChartOptionSelector = createSelector(
  selectPerformanceSummaryState,
  (performanceSummaryState: PerformanceSummaryState) => performanceSummaryState.activeLineChartOption,
);

export const performanceSummaryDidUserChangeDatesSelector = createSelector(
  selectPerformanceSummaryState,
  (performanceSummaryState: PerformanceSummaryState) => performanceSummaryState.didUserChangeDates,
);
// end of new selectors

export const loadingSelector = createSelector(
  selectPerformanceSummaryState,
  (performanceSummaryState: PerformanceSummaryState) => performanceSummaryState.isLoading,
);

export const errorSelector = createSelector(
  selectPerformanceSummaryState,
  (performanceSummaryState: PerformanceSummaryState) => performanceSummaryState.error,
);
