import { createSelector } from '@reduxjs/toolkit';
import merchantApi from './api';
import { ActiveDomain, ActiveDomainMerchantWithActiveDomainInfo } from './types';

export const selectActiveDomainsById = (appId: string, merchantId: string) =>
  createSelector(
    merchantApi.endpoints.getActiveDomains.select({ appId }),
    (result): ActiveDomainMerchantWithActiveDomainInfo | null => {
      const activeDomainByMerchant = result.data
        ? result.data.find((activeDomain: ActiveDomain) => activeDomain.Merchant.ID === parseInt(merchantId))
        : null;

      let data = null;

      if (activeDomainByMerchant) {
        data = {
          ActiveDomainID: activeDomainByMerchant.ID,
          Domain: activeDomainByMerchant.Domain,
          ...activeDomainByMerchant.Merchant,
        };
      }

      return data;
    },
  );
