import React, { FC } from 'react';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import clsx from 'clsx';

import './TableRow.scss';

interface OwnProps extends TableRowProps {
  className?: string;
}

const CustomTableRow: FC<React.PropsWithChildren<OwnProps>> = ({ children, className, ...restProps }) => {
  return (
    <TableRow className={clsx('default-table-row', className)} {...restProps}>
      {children}
    </TableRow>
  );
};

export default CustomTableRow;
