import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAppGroupCommissionColumns, initialCommissionColumns } from './constants';
import {
  AppGroupCommissionColumn,
  AppGroupCommissionState,
  CommissionColumn,
  CommissionState,
  SetCommissionColumnsPayload,
  SetAppGroupCommissionColumnsPayload,
} from './types';

const initializeCommissionColumnsState = (): CommissionColumn[] => {
  const allColumnsCopy = JSON.parse(JSON.stringify(initialCommissionColumns));
  const localStorageColumns = localStorage.getItem('commission_history_columns_state');

  if (localStorageColumns) {
    const savedCommissionColumns: CommissionColumn[] = JSON.parse(localStorageColumns);

    const allColumnsSet = new Set(allColumnsCopy.map((column: CommissionColumn) => column.accessor));
    const filteredCommissionColumns: CommissionColumn[] = [];

    savedCommissionColumns.forEach((column: CommissionColumn) => {
      if (allColumnsSet.has(column.accessor)) {
        filteredCommissionColumns.push(column);
      }
    });

    // Add any new columns to the end of the list
    allColumnsCopy.forEach((column: CommissionColumn) => {
      if (!filteredCommissionColumns.some(savedColumn => savedColumn.accessor === column.accessor)) {
        filteredCommissionColumns.push(column);
      }
    });

    return filteredCommissionColumns;
  }

  return allColumnsCopy;
};

const initializeAppGroupCommissionColumnsState = (): AppGroupCommissionColumn[] => {
  const allColumnsCopy = JSON.parse(JSON.stringify(initialAppGroupCommissionColumns));
  const localStorageColumns = localStorage.getItem('app_group_commission_history_columns_state');

  if (localStorageColumns) {
    const savedAppGroupCommissionColumns: AppGroupCommissionColumn[] = JSON.parse(localStorageColumns);

    const allColumnsSet = new Set(allColumnsCopy.map((column: AppGroupCommissionColumn) => column.accessor));
    const filteredCommissionColumns: AppGroupCommissionColumn[] = [];

    savedAppGroupCommissionColumns.forEach((column: AppGroupCommissionColumn) => {
      if (allColumnsSet.has(column.accessor)) {
        filteredCommissionColumns.push(column);
      }
    });

    // Add any new columns to the end of the list
    allColumnsCopy.forEach((column: AppGroupCommissionColumn) => {
      if (!filteredCommissionColumns.some(savedColumn => savedColumn.accessor === column.accessor)) {
        filteredCommissionColumns.push(column);
      }
    });

    return filteredCommissionColumns;
  }

  return allColumnsCopy;
};

export const initialCommissionState: CommissionState = {
  columns: initializeCommissionColumnsState(),
};

export const initialAppGroupCommissionState: AppGroupCommissionState = {
  columns: initializeAppGroupCommissionColumnsState(),
};

const commissionSlice = createSlice({
  name: 'commission',
  initialState: initialCommissionState,
  reducers: {
    setCommissionColumns(state: CommissionState, action: PayloadAction<SetCommissionColumnsPayload>) {
      state.columns = action.payload.columns;
      localStorage.setItem('commission_history_columns_state', JSON.stringify(action.payload.columns));
    },
  },
});

const appGroupCommissionSlice = createSlice({
  name: 'appGroupCommission',
  initialState: initialAppGroupCommissionState,
  reducers: {
    setAppGroupCommissionColumns(
      state: AppGroupCommissionState,
      action: PayloadAction<SetAppGroupCommissionColumnsPayload>,
    ) {
      state.columns = action.payload.columns;
      localStorage.setItem('app_group_commission_history_columns_state', JSON.stringify(action.payload.columns));
    },
  },
});

export const { setCommissionColumns } = commissionSlice.actions;
export const { setAppGroupCommissionColumns } = appGroupCommissionSlice.actions;

export const commissionReducer = commissionSlice.reducer;
export const appGroupCommissionReducer = appGroupCommissionSlice.reducer;
