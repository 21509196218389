import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import FeatureToggle from './FeatureToggle';
import { useAppSelector } from '../../reduxState/hooks';
import { allFeatureFlagsSelector } from '../../reduxState/store/featureFlag/selectors';

const TableHeader = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
})(TableCell);

export default function FeatureDashboard(): JSX.Element {
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const features = useAppSelector(allFeatureFlagsSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasWfPermissions) {
      navigate('/');
    }
  }, [hasWfPermissions, navigate]);

  const featuresList = Object.values(features);
  if (!featuresList.length) return <p style={{ textAlign: 'center' }}>There are currently no active Feature Flags.</p>;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="feature flag table">
        <TableHead>
          <TableRow>
            <TableHeader>Feature Flag</TableHeader>
            <TableHeader align="right">Enabled</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {featuresList.map(feature => (
            <TableRow key={feature.name}>
              <FeatureToggle
                name={feature.name}
                title={feature.title}
                description={feature.description}
                enabled={feature.enabled}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
