import { RestrictedUserRoles } from 'reduxState/store/user/constants';

type PageName = 'performance-summary' | 'payments' | 'merchants' | 'settings';

export const getRestrictedPages = (restrictedRole: string): PageName[] => {
  switch (restrictedRole) {
    case RestrictedUserRoles.OPERATIONS:
      return ['performance-summary', 'payments', 'settings'];
    case RestrictedUserRoles.FINANCE:
      return ['merchants', 'settings'];
    case RestrictedUserRoles.BUSINESS:
      return ['settings'];
    case RestrictedUserRoles.ADMIN:
    default: {
      return [];
    }
  }
};
