import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import './Button.scss';

interface OwnProps extends ButtonProps {
  className?: string;
}

const CustomButton: FC<React.PropsWithChildren<OwnProps>> = ({ children, className, ...restProps }) => {
  return (
    <Button className={clsx('button-default', className)} {...restProps}>
      {children}
    </Button>
  );
};

export default CustomButton;
