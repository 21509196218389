import React, { FC } from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import clsx from 'clsx';

import './TableCell.scss';

interface OwnProps extends TableCellProps {
  className?: string;
}

const CustomTableCell: FC<React.PropsWithChildren<OwnProps>> = ({ children, className, ...restProps }) => {
  return (
    <TableCell className={clsx('default-table-cell', className)} {...restProps}>
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
