import React, { FC, ReactElement, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableCell from 'components/TableCell/TableCell';
import TableRow from 'components/TableRow/TableRow';
import { parseCommissionRate } from 'helpers';
import { ActiveDomainMerchantWithActiveDomainInfo } from '../../../reduxState/store/merchant/types';

export type TableColumn = {
  name: string;
  accessor: string;
};

export interface ActiveDomainMerchantProps {
  index: number;
  style: React.CSSProperties;
  data: {
    columns: Array<TableColumn>;
    items: ActiveDomainMerchantWithActiveDomainInfo[];
  };
}

const ActiveDomainMerchantRow: FC<React.PropsWithChildren<ActiveDomainMerchantProps>> = props => {
  const {
    index,
    style,
    data: { columns, items },
  } = props;

  const navigate = useNavigate();
  const { pathname: url } = useLocation();

  const activeDomainMerchant = useMemo(() => items[index], [items, index]);

  const renderContent = (accessor: string): ReactElement | string => {
    switch (accessor) {
      case 'ID':
        return <>{activeDomainMerchant.ID}</>;
      case 'Name':
        return <>{activeDomainMerchant.Name}</>;
      case 'MaxRate':
        return <>{parseCommissionRate(activeDomainMerchant.MaxRate)}</>;
      case 'Domain':
        return <>{activeDomainMerchant.Domain}</>;
      default:
        return '';
    }
  };

  const renderCell = (accessor: string): ReactElement => {
    return <TableCell key={accessor}>{renderContent(accessor)}</TableCell>;
  };

  const goToMerchant = (): void => {
    if (activeDomainMerchant.ID) {
      const urlSegments = url.split('/');
      const lastIndexValue = urlSegments[urlSegments.length - 1];

      let finalURL;
      if (lastIndexValue === 'merchants') {
        finalURL = url + `/${activeDomainMerchant.ID}`;
      } else {
        urlSegments[urlSegments.length - 1] = activeDomainMerchant.ID.toString();
        finalURL = urlSegments.join('/');
      }
      // Only navigate if new url is different from the current url
      if (finalURL !== url) navigate(finalURL);
    }
  };

  return (
    <TableRow className="clickable" style={style} onClick={goToMerchant}>
      {columns.map((column: TableColumn) => renderCell(column.accessor))}
    </TableRow>
  );
};

export default ActiveDomainMerchantRow;
