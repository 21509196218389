import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AuthProviderWithNavigate from 'auth/AuthProviderWithNavigate';
import { SENTRY_DSN } from 'helpers/constants';
import { setupStore } from 'reduxState';
import App from './App';
import './index.css';

if (import.meta.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: SENTRY_DSN, environment: import.meta.env.NODE_ENV });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={setupStore()}>
    <Router>
      <AuthProviderWithNavigate>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </AuthProviderWithNavigate>
    </Router>
  </Provider>,
);
