import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  Droppable,
  DroppableProps,
  DropResult,
} from 'react-beautiful-dnd';
import Button from 'components/Button/Button';
import { moveItemInArray } from 'helpers';
import { CommissionColumn } from '../../../reduxState/store/commission/types';
import { PerformanceSummaryColumn } from '../../../reduxState/store/performanceSummary/types';
import { ChooseColumnsItem } from '../ChooseColumnsItem/ChooseColumnsItem';
import './ChooseColumnsDialog.scss';

export type ColumnOptions = CommissionColumn | PerformanceSummaryColumn;

interface ChooseColumnsProps<T extends ColumnOptions> {
  open: boolean;
  columns: T[];
  onClose: (columns?: T[]) => void;
}

type DroppableChildrenFn = DroppableProps['children'];

const DIALOG_TITLE_ID = 'choose-columns-title';

export const ChooseColumnsDialog = <T extends ColumnOptions>({
  open,
  columns,
  onClose,
}: ChooseColumnsProps<T>): JSX.Element => {
  const [editedColumns, setEditedColumns] = useState(columns);

  useEffect(() => {
    setEditedColumns(columns);
  }, [columns]);

  const cancel = (): void => {
    onClose();

    // timeout is to reset columns after dialog is closed
    setTimeout(() => {
      setEditedColumns(columns);
    });
  };

  const save = (): void => {
    onClose(editedColumns);
  };

  const toggleColumn = ({ accessor }: T): void => {
    const nextColumns = editedColumns.map(column => {
      if (column.accessor === accessor) {
        return {
          ...column,
          visible: !column.visible,
        };
      }

      return column;
    });

    setEditedColumns(nextColumns);
  };

  const onDragEnd = (result: DropResult): void => {
    const fromIndex = result.source.index;
    const toIndex = result.destination ? result.destination.index : fromIndex;
    const nextColumns = moveItemInArray(editedColumns, fromIndex, toIndex);
    setEditedColumns(nextColumns);
  };

  const droppableChildrenFn: DroppableChildrenFn = provided => {
    return (
      <List ref={provided.innerRef} {...provided.droppableProps}>
        <>
          {editedColumns.map((column, i) => (
            <Draggable
              draggableId={column.accessor.toString()}
              isDragDisabled={column.nonEditable}
              index={i}
              key={column.accessor}
            >
              {draggableChildrenFn(column)}
            </Draggable>
          ))}
          {provided.placeholder}
        </>
      </List>
    );
  };

  const draggableChildrenFn = (column: T): DraggableChildrenFn => (provided): ReturnType<DraggableChildrenFn> => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} role="menuitem">
        <ChooseColumnsItem
          column={column}
          onToggleColumn={toggleColumn}
          dragHandleProps={provided?.dragHandleProps || undefined} // null is not part of type
        />
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={cancel} aria-labelledby={DIALOG_TITLE_ID}>
      <DialogTitle className="choose-columns-dialog-title" id={DIALOG_TITLE_ID}>
        <span className="title">Choose the columns you see</span>
      </DialogTitle>

      <DialogContent className="choose-columns-dialog-content">
        <DialogContentText className="choose-columns-heading">Available columns</DialogContentText>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="columns">{droppableChildrenFn}</Droppable>
        </DragDropContext>
      </DialogContent>

      <DialogActions className="choose-columns-dialog-actions">
        <Button className="button-orange" variant="contained" onClick={save}>
          Save
        </Button>
        <Button className="button-orange-empty" onClick={cancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
