import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';

import './TextField.scss';

interface OwnProps {
  customLabel?: string | React.ReactNode;
  wrapperClassName?: string;
}

const CustomTextField: FC<React.PropsWithChildren<OwnProps & TextFieldProps>> = ({
  customLabel,
  className,
  wrapperClassName,
  inputProps,
  InputProps,
  InputLabelProps,
  SelectProps,
  ...restProps
}) => {
  return (
    <div className={clsx('custom-text-field-wrapper', wrapperClassName)}>
      {customLabel ? (
        <label htmlFor={restProps.name} className="custom-text-field-label">
          {customLabel}
        </label>
      ) : null}
      <TextField
        className={clsx('default-text-field', className)}
        inputProps={inputProps}
        InputProps={{ ...InputProps, className: clsx('default-input') }}
        InputLabelProps={InputLabelProps}
        SelectProps={SelectProps}
        {...restProps}
      />
    </div>
  );
};

export default CustomTextField;
