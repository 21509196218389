import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  titleText: string;
  contentText: string;
  onConfirm: () => void;
  children: React.ReactNode;
};

export default function ConfirmationModal({ onConfirm, titleText, contentText, children }: Props) {
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <>
      <div style={{ display: 'contents' }} onClick={() => setOpen(true)}>
        {children}
      </div>
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth={true} maxWidth="xs">
        <DialogTitle>{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
