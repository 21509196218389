import React, { FC, useState } from 'react';
import COLORS from 'constants/colors';
import styled from '@emotion/styled';
import { FiberManualRecord, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Menu, MenuItem, Button } from '@mui/material';
import { ChartHeaderOption } from 'reduxState/store/performanceSummary/types';

export interface LineChartHeaderProps {
  className?: string;
  applicationCurrency: string;
  chartOptions: ChartHeaderOption[];
  activeChartOption: ChartHeaderOption;
  handleChangeChartView: (chartHeaderOption: ChartHeaderOption) => void;
}

const LineChartHeader: FC<LineChartHeaderProps> = props => {
  const { applicationCurrency, chartOptions, handleChangeChartView, activeChartOption } = props;

  const [chartMenuOpen, setChartMenuOpen] = useState<boolean>(false);
  const [chartMenuAnchor, setChartMenuAnchor] = useState<HTMLElement | null>(null);
  const [chartHeaderTitle, setChartHeaderTitle] = useState<string>(activeChartOption.title);

  const handleChartHeaderMenuOpen = (event: { currentTarget: HTMLElement }): void => {
    setChartMenuOpen(true);
    setChartMenuAnchor(event.currentTarget);
  };

  const handleChartHeaderMenuClose = (): void => {
    setChartMenuOpen(false);
    setChartMenuAnchor(null);
  };

  const handleChartHeaderOptionClick = async (newChartOption: ChartHeaderOption) => {
    setChartMenuOpen(false);
    setChartHeaderTitle(newChartOption.title);
    handleChangeChartView(newChartOption);
  };

  const generateLegendLabel = (label: string): string => {
    switch (label) {
      case 'Order Count':
      case 'Merchant Click': {
        return label;
      }
      default: {
        return `${label} - ${applicationCurrency}`;
      }
    }
  };

  const labels = activeChartOption.legendLabels;

  return (
    <Styled.LineChartContainer>
      <Styled.LineChartHeaderTitle>
        <Styled.Button role="button" onClick={handleChartHeaderMenuOpen} size="large">
          <Styled.Title>{`${chartHeaderTitle}`}</Styled.Title>
          {chartMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </Styled.Button>
        <Menu role="menu" anchorEl={chartMenuAnchor} open={chartMenuOpen} onClose={handleChartHeaderMenuClose}>
          {chartOptions.map((chartOption: ChartHeaderOption, index: number) => {
            return (
              <Styled.MenuItem
                role="menuitem"
                onClick={() => handleChartHeaderOptionClick(chartOption)}
                key={chartOption.title}
              >
                {chartOption.title}
              </Styled.MenuItem>
            );
          })}
        </Menu>
      </Styled.LineChartHeaderTitle>
      <Styled.LegendContainer>
        {labels &&
          labels.map((label: string, index: number) => {
            return (
              <Styled.LabelContainer index={index} key={label}>
                <Styled.FiberManualRecord fontSize="small" index={index} />
                <Styled.LegendLabel>{generateLegendLabel(label)}</Styled.LegendLabel>
              </Styled.LabelContainer>
            );
          })}{' '}
      </Styled.LegendContainer>
    </Styled.LineChartContainer>
  );
};

const Styled = {
  LineChartContainer: styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '95%',
    margin: '20px 5px',
    fontFamily: `'Montserrat', sans-serif`,
    '@media (max-width: 1030px)': {
      flexWrap: 'wrap',
    },
  }),
  LineChartHeaderTitle: styled.div({
    '@media (max-width: 1030px)': {
      display: 'flex',
      flexBasis: '100%',
      justifyContent: 'center',
    },
  }),
  LegendContainer: styled.div({
    display: 'inherit',
    width: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1030px)': {
      flexBasis: '100%',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  }),
  LegendLabel: styled.span({
    fontFamily: 'inherit',
    fontSize: '16px',
    '@media (max-width: 460px)': {
      fontSize: '14px',
    },
  }),
  LabelContainer: styled((props: any) => <div {...props} />)(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: !props.index ? '7px' : undefined,
    '@media (max-width: 460px)': {
      flexBasis: '100%',
      justifyContent: 'center',
    },
  })),
  FiberManualRecord: styled((props: any) => <FiberManualRecord {...props} />)(props => ({
    color: !props.index ? COLORS.lightPurple : COLORS.graphBlue,
  })),
  MenuItem: styled((props: any) => <MenuItem {...props} />)({
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: '22px',
  }),
  // eslint-disable-next-line jsx-a11y/heading-has-content
  Title: styled((props: any) => <h3 {...props} />)({
    textTransform: 'capitalize',
    '@media (max-width: 460px)': {
      fontSize: '24px',
      textWrap: 'nowrap',
    },
  }),
  Button: styled((props: any) => <Button {...props} />)({
    fontFamily: `'Montserrat', sans-serif`,
    color: COLORS.darkPurple,
  }),
};

export default LineChartHeader;
