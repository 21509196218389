import { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { timeConversion } from 'helpers';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import useIsTabVisible from './useIsTabVisible';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useTimeOnPageGA(user: any, applicationId: string, pageName: string): void {
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const isVisible = useIsTabVisible();
  const [pageArrivalTime, setArrivalTime] = useState<number>(Date.now());
  const arrivalTimeRef = useRef(pageArrivalTime);

  useEffect(() => {
    arrivalTimeRef.current = pageArrivalTime;
  }, [pageArrivalTime]);

  useEffect(() => {
    return () => {
      const now = Date.now();
      !hasWfPermissions &&
        ReactGA.event('time_on_page', {
          appId: applicationId,
          userId: user!.sub,
          pageName,
          timeOnPage: timeConversion(now - arrivalTimeRef.current),
        });
    };
    // We are only making use of the callback here to send a GA event when the page is about to dismount
    // Equivalent to componentWillUnmount, since it has no dependencies
  }, [applicationId, hasWfPermissions, pageName, user]);

  useEffect(() => {
    if (isVisible) {
      setArrivalTime(Date.now());
    } else {
      const now = Date.now();
      !hasWfPermissions &&
        ReactGA.event('time_on_page', {
          appId: applicationId,
          userId: user!.sub,
          pageName,
          timeOnPage: timeConversion(now - arrivalTimeRef.current),
        });
    }
  }, [isVisible, setArrivalTime, applicationId, hasWfPermissions, pageName, user]);
}

export default useTimeOnPageGA;
