import { addDays, differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

export const getDurationDescription = (startDate: Date, endDate: Date): string => {
  const dayAfterEndDate = addDays(endDate, 1); // move end date forward by one day so that it is considered as part of the range

  const dayDifference = differenceInDays(dayAfterEndDate, startDate);
  const monthDifference = differenceInMonths(dayAfterEndDate, startDate);
  const yearDifference = differenceInYears(dayAfterEndDate, startDate);

  if (yearDifference > 0 && monthDifference >= 12) {
    return yearDifference === 1 ? `${yearDifference} year` : `${yearDifference} years`;
  }

  if (monthDifference > 0 && dayDifference >= 28) {
    return monthDifference === 1 ? `${monthDifference} month` : `${monthDifference} months`;
  }

  if (dayDifference > 0) {
    return dayDifference === 1 ? `${dayDifference} day` : `${dayDifference} days`;
  }

  return '';
};
