import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Checkbox, IconButton } from '@mui/material';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ReactGA from 'react-ga4';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { ColumnOptions } from '../ChooseColumnsDialog/ChooseColumnsDialog';
import './ChooseColumnsItem.scss';

export interface ChooseColumnsItemProps<T extends ColumnOptions> {
  column: T;
  onToggleColumn: (column: T) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ChooseColumnsItem = <T extends ColumnOptions>({
  column,
  dragHandleProps,
  onToggleColumn,
}: ChooseColumnsItemProps<T>): ReactElement<ChooseColumnsItemProps<T>> => {
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  const toggleColumn = (): void => {
    onToggleColumn(column);
    !hasWfPermissions &&
      ReactGA.event('click_column', {
        columnName: column.name,
      });
  };

  const { accessor, name, visible, nonEditable } = column;
  const labelId = `checkbox-list-label-${accessor}`;

  return (
    <ListItem
      className="choose-columns-item"
      key={accessor}
      role={undefined}
      dense
      button
      disabled={nonEditable}
      onClick={toggleColumn}
    >
      <ListItemIcon className="choose-columns-item-icon">
        <Checkbox
          edge="start"
          checked={visible}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>

      <ListItemText id={labelId} primary={name} />

      {nonEditable ? null : (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="comments" {...dragHandleProps} size="large">
            <DragIndicatorIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
