import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import CommissionHistoryReportDownload from 'components/Commission/CommissionHistoryReportDownload/CommissionHistoryReportDownload';
import Loading from 'components/Loading/Loading';
import { useGetAllCommissionDownloadsQuery } from 'reduxState/store/commission/api';
import { CommissionHistoryDownloadData } from 'reduxState/store/commission/types';
import '../../../pages/CommissionHistoryDownload/CommissionHistoryDownload.scss';

interface AppGroupCommissionHistoryDownloadProps {
  adminId: string;
  appName: string;
}

const AppGroupCommissionHistoryDownload: FC<AppGroupCommissionHistoryDownloadProps> = ({ adminId, appName }) => {
  const { user } = useAuth0();

  const { data: commissionHistoryDownloads, isFetching, error } = useGetAllCommissionDownloadsQuery({
    appId: adminId,
    userId: user!.sub!,
  });

  if (error) {
    toast.error('Failed to get commission history download links.');
  }

  if (isFetching) {
    return <Loading data-testid="loading" />;
  }

  return (
    <div className="commission-download">
      <div className="commission-download-header">
        <h2 className="commission-download-title">App Group Commission History Downloads</h2>
      </div>
      {commissionHistoryDownloads?.length
        ? commissionHistoryDownloads.map((downloadData: CommissionHistoryDownloadData, index: number) => (
          <CommissionHistoryReportDownload appName={appName} downloadData={downloadData} key={index} />
        ))
        : 'No downloads to display'}
    </div>
  );
};

export default AppGroupCommissionHistoryDownload;
