import React, { FC, useEffect, useState } from 'react';
import { GroupedCountries, countryCodeOptions, groupedCountryCodeOptions } from 'constants/countryCodes';
import { useAuth0 } from '@auth0/auth0-react';
import LockIcon from '@mui/icons-material/Lock';
import { Button, InputAdornment, Checkbox, FormControlLabel, Tooltip, IconButton } from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DropdownSearchSelect, { DropdownSelectOption } from 'components/DropdownSearchSelect/DropdownSearchSelect';
import TextInput from 'components/TextInput/TextInput';
import { getAllSplitPolicies } from 'helpers/api';
import { isErrorResponse } from 'helpers/typeGuards';
import {
  useGetAllAdminApplicationsQuery,
  useCreateClientApplicationMutation,
  useCreateNewAppGroupMutation,
} from 'reduxState/store/application/api';
import { ClientApplication, ClientApplicationOptions, NewAppGroupData } from 'reduxState/store/application/types';
import CreateAppOptions from './components/CreateAppOptions/CreateAppOptions';
import {
  applicationTypeOptions,
  createApplicationOptionItems,
  defaultCurrencyOptions,
  defaultFormData,
  defaultAppOptions,
} from './constants';
import { generateAdminAppOptions, generateSplitPolicyOptions } from './helpers/helpers';

interface CreateApplicationFormData {
  applicationName: string;
  splitPolicy: DropdownSelectOption | null;
  payoutCurrency: DropdownSelectOption | null;
  serviceableCountries: DropdownSelectOption | null;
  CJPID: string;
  nerfRate: string;
  productPageUrl?: string;

  [key: string]: CreateApplicationFormData[keyof CreateApplicationFormData];
}

/**
 * Represents a form to create a new application under an existing app group and a new app group + application. The form
 * will manually validate all required fields before submitting form. If there are any missing fields, it will display error messages
 * under the required field. If the app was created successfully, it will display a message to the user that it has been created.
 * All applications will record who created them using their email. Only WF users should have access to this form.
 *
 * @example
 * ```tsx
 *  <CreateApplication />
 * ```
 */

const serviceableCountriesOptions = [...groupedCountryCodeOptions, ...countryCodeOptions];
const CreateApplication: FC = () => {
  const [isNewPartner, setIsNewPartner] = useState(false);
  const [formData, setFormData] = useState<CreateApplicationFormData>(defaultFormData);
  const [applicationOptions, setApplicationOptions] = useState<ClientApplicationOptions>(defaultAppOptions);
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});
  const [disableMatchProjection, setDisableMatchProject] = useState(true);
  const [includeCountryInAppName, setIncludeCountryInAppName] = useState(false);
  const [applicationType, setApplicationType] = useState<DropdownSelectOption | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<DropdownSelectOption | null>(null);
  const [newCompanyName, setNewCompanyName] = useState<string>('');
  const [defaultAppCountry, setDefaultAppCountry] = useState<DropdownSelectOption | null>(null);

  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [requestSuccessful, setRequestSuccessful] = useState(false);
  const [splitPolicyOptions, setSplitPolicyOptions] = useState<DropdownSelectOption[]>([]);
  const [splitPolicyError, setSplitPolicyError] = useState<string>('');
  const [adminApplicationOptions, setAdminApplicationOptions] = useState<DropdownSelectOption[]>([]);

  const { user } = useAuth0();
  const [createClientApplication, { isLoading: isLoadingClientApp }] = useCreateClientApplicationMutation();
  const [createNewAppGroup, { isLoading: isLoadingAppGroup }] = useCreateNewAppGroupMutation();
  const { data: adminApplicationsData, error: adminApplicationsError } = useGetAllAdminApplicationsQuery();

  const isLoading = () => {
    const allLoadingStates = [isLoadingClientApp, isLoadingAppGroup];
    // Returns true if any of the requests are still loading
    return allLoadingStates.some(isLoading => isLoading);
  };

  useEffect(() => {
    const fetchSplitPolicyDropdownOptions = async () => {
      try {
        const splitPoliciesResponse = await getAllSplitPolicies();

        if (isErrorResponse(splitPoliciesResponse)) {
          throw new Error(splitPoliciesResponse.message);
        }

        const newSplitPoliciesOptions = generateSplitPolicyOptions(splitPoliciesResponse);
        setSplitPolicyOptions(newSplitPoliciesOptions);
        setSplitPolicyError('');
      } catch (error) {
        setSplitPolicyError(error);
        console.error(error);
      }
    };

    fetchSplitPolicyDropdownOptions();
  }, []);

  useEffect(() => {
    if (adminApplicationsData) {
      const newAdminApplicationOptions = generateAdminAppOptions(adminApplicationsData);
      setAdminApplicationOptions(newAdminApplicationOptions);
    } else if (adminApplicationsError) {
      console.error(adminApplicationsError);
    }
  }, [adminApplicationsData, adminApplicationsError]);

  useEffect(() => {
    if (splitPolicyError || adminApplicationsError) {
      toast.error('Failed to get Split Policy and/or Admin App options, please refresh to try again.');
    }
  }, [splitPolicyError, adminApplicationsError]);

  useEffect(() => {
    // When any of the required fields are cleared, existing application name should be cleared
    if ((isNewPartner && !newCompanyName) || (!selectedCompany && !applicationType)) {
      setFormData({
        ...formData,
        applicationName: '',
      });
    }

    setFormData({
      ...formData,
      applicationName: applicationName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewPartner, selectedCompany, applicationType, newCompanyName, includeCountryInAppName, defaultAppCountry]);

  const onApplicationOptionChange = (option: keyof ClientApplicationOptions): void => {
    const newOptions = {
      ...applicationOptions,
      [option]: !applicationOptions[option],
    };
    setApplicationOptions(newOptions);
  };

  const removeFormErrorsOnChange = (propertyString: string) => {
    const newFormErrors = { ...formErrors };
    delete newFormErrors[propertyString];
    setFormErrors(newFormErrors);
  };

  const onSearchSelectChange = (option: DropdownSelectOption | null, propertyString: string) => {
    if (formErrors[propertyString] && option) {
      removeFormErrorsOnChange(propertyString);
    }

    setFormData({
      ...formData,
      [propertyString]: option || null,
    });
  };

  const onTextInputChange = (value: string, propertyString: string) => {
    if (formErrors[propertyString] && value) {
      removeFormErrorsOnChange(propertyString);
    }

    setFormData({
      ...formData,
      [propertyString]: value,
    });
  };

  const validateFormData = () => {
    const newFormErrors: Record<string, boolean> = {};
    for (const key in formData) {
      const value = formData[key];

      // product page url is not required
      if (key === 'productPageUrl') continue;

      if (key === 'nerfRate') {
        const nerfRateValue = Number(formData[key]);
        if (nerfRateValue === 100) continue;
        if (nerfRateValue <= 0 || nerfRateValue > 100) {
          newFormErrors[key] = true;
        }
      }

      if (!value) newFormErrors[key] = true;
    }

    if (!selectedCompany?.value) {
      newFormErrors.selectedCompany = true;
    }

    if (!applicationType) {
      newFormErrors.applicationType = true;
    }

    if (isNewPartner && !newCompanyName) {
      newFormErrors.newCompanyName = true;
    }

    if (includeCountryInAppName && !defaultAppCountry) {
      newFormErrors.defaultAppCountry = true;
    }

    if (!_.isEmpty(newFormErrors)) {
      setFormErrors(newFormErrors);
      return false;
    } else {
      return true;
    }
  };

  const clearForm = () => {
    setFormData(defaultFormData);
    setApplicationType(null);
    setApplicationOptions(defaultAppOptions);
    setSelectedCompany(null);
    setNewCompanyName('');
    setDefaultAppCountry(null);
    setIncludeCountryInAppName(false);
  };

  const handleOnSubmit = async () => {
    if (!validateFormData()) {
      toast.error('Please fill out all required fields.');
      return;
    }
    setRequestErrorMessage('');

    const { applicationName, payoutCurrency, nerfRate, CJPID, splitPolicy, productPageUrl } = formData;

    let serviceableCountriesArray = [formData.serviceableCountries?.value];
    if (formData.serviceableCountries?.value === GroupedCountries.ALL_COUNTRIES) {
      serviceableCountriesArray = [];
    }

    const calculatedNerfRate = Number(nerfRate) / 100;

    if (requestSuccessful) setRequestSuccessful(false);
    if (requestErrorMessage) setRequestErrorMessage('');
    if (isNewPartner) {
      try {
        const newAppGroupData: NewAppGroupData = {
          ModifiedAuthor: user?.email || 'Unknown author',
          EntityName: newCompanyName,
          ApplicationType: applicationType?.value,
          Name: applicationName,
          Country: defaultAppCountry?.value,
          Currency: payoutCurrency?.value,
          ServiceableCountries: serviceableCountriesArray,
          NerfRate: calculatedNerfRate,
          CJPID,
          PolicyName: splitPolicy?.value,
          ApplicationOptions: applicationOptions,
          ProductPageURL: productPageUrl || '',
          includeCountryInAppName,
        };

        await createNewAppGroup({ body: newAppGroupData }).unwrap();
      } catch (error) {
        setRequestErrorMessage('Failed to create new company, please try again.');
        console.error(error);
        return;
      }
    } else {
      try {
        const newClientApplicationData: ClientApplication = {
          ModifiedAuthor: user?.email || 'Unknown author',
          EntityName: selectedCompany?.value.name,
          AdminAppID: selectedCompany?.value.id,
          ApplicationType: applicationType?.value,
          Name: applicationName,
          Country: defaultAppCountry?.value,
          Currency: payoutCurrency?.value,
          ServiceableCountries: serviceableCountriesArray,
          NerfRate: calculatedNerfRate,
          CJPID,
          PolicyName: splitPolicy?.value,
          ProductPageURL: productPageUrl || '',
          applicationOptions: applicationOptions,
          includeCountryInAppName,
        };

        await createClientApplication({ body: newClientApplicationData }).unwrap();
      } catch (error) {
        setRequestErrorMessage('Failed to create new application, please try again.');
        console.error(error);
        return;
      }
    }
    clearForm();
    setSplitPolicyError('');
    setRequestErrorMessage('');
    setRequestSuccessful(true);

    const toastMessage = isNewPartner
      ? `Successfully created ${newCompanyName} Admin and ${applicationName}`
      : `Successfully created ${applicationName}`;
    toast.success(toastMessage, {
      autoClose: 8000,
    });
  };

  const generateApplicationName = () => {
    if (!selectedCompany && !newCompanyName && !applicationType) return '';
    let newApplicationName = '';

    if (isNewPartner && applicationType && newCompanyName) {
      if (defaultAppCountry && includeCountryInAppName) {
        newApplicationName = `${newCompanyName} ${applicationType?.value} ${defaultAppCountry?.value} Client`;
      } else {
        newApplicationName = `${newCompanyName} ${applicationType?.value} Client`;
      }
    } else if (applicationType && selectedCompany?.value.name) {
      if (defaultAppCountry && includeCountryInAppName) {
        newApplicationName = `${selectedCompany?.value?.name} ${applicationType?.value} ${defaultAppCountry?.value} Client`;
      } else {
        newApplicationName = `${selectedCompany?.value?.name} ${applicationType?.value} Client`;
      }
    }

    return newApplicationName;
  };

  const applicationName = generateApplicationName();

  const matchProjectionLabel = () => {
    if (disableMatchProjection) {
      return (
        <Tooltip
          title={'Match Earning Projection must be enabled when the Nerf Rate is greater than zero.'}
          placement="right"
          sx={{ fontSize: '14px', padding: '0px' }}
        >
          <IconButton>
            <span className="flex items-center text-sm text-left text-center justify-left text-montserrat">
              Match Earning Projection
              <LockIcon className="pl-2" fontSize="medium" />
            </span>
          </IconButton>
        </Tooltip>
      );
    }

    return <span className="flex items-center text-sm text-center text-montserrat ">Match Earning Projection</span>;
  };

  return (
    <div className="flex flex-col items-center flex-1">
      <div className="max-w-[930px] min-h-[500px] flex h-fit-content w-full flex-col text-montserrat bg-white rounded-md p-[25px] shadow-md ">
        <h4 className="w-full mb-[10px]">Create Application</h4>
        <div className="flex flex-col w-full">
          <DropdownSearchSelect
            label="Company"
            value={selectedCompany}
            options={[
              { label: 'Create New Company', value: 'Create New Company', group: 'New Application' },
              ...adminApplicationOptions,
            ]}
            groupBy={(option: DropdownSelectOption) => {
              return option.group || '';
            }}
            handleChange={(option: DropdownSelectOption | null) => {
              if (option?.value === 'Create New Company') {
                setIsNewPartner(true);
              } else {
                if (isNewPartner) setIsNewPartner(false);
                if (formErrors['selectedCompany'] && option) {
                }
              }
              setSelectedCompany(option || null);
              removeFormErrorsOnChange('selectedCompany');
            }}
            hasError={formErrors.selectedCompany}
            errorMessage="Please select Company"
            placeholder="Select Option"
            noOptionsText="No Company Found"
          />
          {isNewPartner && (
            <TextInput
              label="New Company Entity Name"
              value={newCompanyName}
              placeholderText="Enter New Company Name"
              handleChange={(value: string) => {
                if (formErrors.newCompanyName && value) {
                  removeFormErrorsOnChange('newCompanyName');
                }
                setNewCompanyName(value);
              }}
              hasError={formErrors.newCompanyName}
              errorText={'Please add Entity Name'}
              helperText={formData.newCompanyName ? `Admin app name: "${formData.newCompanyName} Admin"` : ''}
            />
          )}
          <DropdownSearchSelect
            label="Application Type"
            value={applicationType}
            options={applicationTypeOptions}
            handleChange={(option: DropdownSelectOption | null) => {
              if (formErrors.applicationType && option) {
                removeFormErrorsOnChange('applicationType');
              }
              setApplicationType(option || null);
            }}
            hasError={formErrors.applicationType}
            errorMessage="Please select Application Type"
            placeholder="Select Application Type"
            noOptionsText="No Application Type Found"
          />
          <TextInput
            label="Client Application Name"
            value={formData.applicationName}
            placeholderText="Enter Client Application Name"
            handleChange={(value: string) => onTextInputChange(value, 'applicationName')}
            hasError={formErrors.applicationName}
            errorText={formErrors.applicationName ? 'Please add an client application name' : undefined}
          />
          <DropdownSearchSelect
            label="Split Policy"
            value={formData.splitPolicy}
            options={splitPolicyOptions}
            handleChange={(option: DropdownSelectOption | null) => onSearchSelectChange(option, 'splitPolicy')}
            hasError={formErrors.splitPolicy}
            errorMessage="Please select Split Policy"
            placeholder="Select a Split Policy"
            noOptionsText="No Split Policy Found"
          />
          <TextInput
            label="CJPID"
            placeholderText="Enter CJPID"
            value={formData.CJPID}
            handleChange={(value: string) => onTextInputChange(value, 'CJPID')}
            hasError={formErrors.CJPID}
            errorText={formErrors.CJPID ? 'Please add a CJPID' : undefined}
          />
          <DropdownSearchSelect
            label="Payout Currency"
            value={formData.payoutCurrency}
            options={defaultCurrencyOptions}
            handleChange={(option: DropdownSelectOption | null) => onSearchSelectChange(option, 'payoutCurrency')}
            placeholder="Select Payout Currency"
            noOptionsText="No Currency Found"
            hasError={formErrors.payoutCurrency}
            errorMessage="Please select Payout Currency"
          />
          <div className="flex flex-col items-center justify-center w-full sm:flex-row">
            <div className="w-full grow-1">
              <DropdownSearchSelect
                label="Default App Country"
                value={defaultAppCountry}
                options={countryCodeOptions}
                handleChange={(option: DropdownSelectOption | null) => {
                  if (formErrors.defaultAppCountry && option) {
                    removeFormErrorsOnChange('defaultAppCountry');
                  }
                  setDefaultAppCountry(option || null);
                }}
                placeholder="Select Default App Country"
                noOptionsText="No Country Found"
                hasError={formErrors.defaultAppCountry}
                errorMessage="Please select Default App Country"
              />
            </div>
            <div
              className={`w-full pl-2 sm:w-[400px] ${
                applicationName && defaultAppCountry && includeCountryInAppName ? 'sm:pb-[24px]' : ''
              }`}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeCountryInAppName}
                    onChange={() => {
                      setIncludeCountryInAppName(current => !current);
                    }}
                  />
                }
                label={
                  <span className="flex items-center justify-center text-sm text-center text-montserrat">
                    Include Country in App Name
                  </span>
                }
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full sm:flex-row">
            <div className="w-full">
              <TextInput
                label="Nerf Rate in Percentages"
                placeholderText="Enter valid Nerf Rate"
                value={formData.nerfRate}
                handleChange={(value: string) => {
                  const numberValue = Number(value);
                  if (formErrors.nerfRate) {
                    removeFormErrorsOnChange('nerfRate');
                  }

                  if (numberValue >= 100) {
                    setDisableMatchProject(true);
                    setApplicationOptions({
                      ...applicationOptions,
                      MatchProjection: false,
                    });
                  } else {
                    setDisableMatchProject(false);
                    setApplicationOptions({
                      ...applicationOptions,
                      MatchProjection: true,
                    });
                  }

                  setFormData({
                    ...formData,
                    nerfRate: value,
                  });
                }}
                hasError={formErrors.nerfRate}
                errorText={'Please add a valid Nerf Rate'}
                helperText={`${formData.nerfRate}% payout`}
                inputType="number"
                inputProps={{ min: '0', max: '100' }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
            <div className={`w-full pl-2 sm:w-[400px] sm:pb-[24px]`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicationOptions.MatchProjection}
                    onChange={() => {
                      onApplicationOptionChange('MatchProjection');
                    }}
                    color={disableMatchProjection ? 'error' : 'primary'}
                    disabled={disableMatchProjection}
                  />
                }
                label={matchProjectionLabel()}
              />
            </div>
          </div>
          <DropdownSearchSelect
            label="Serviceable Countries"
            value={formData.serviceableCountries}
            options={serviceableCountriesOptions}
            groupBy={(option: DropdownSelectOption) => {
              return option.group || '';
            }}
            handleChange={(option: DropdownSelectOption | null) => onSearchSelectChange(option, 'serviceableCountries')}
            hasError={formErrors.serviceableCountries}
            errorMessage="Please select Serviceable Country"
            placeholder="Select a Serviceable Country"
            noOptionsText="No Countries Found"
          />
          <TextInput
            label="Product Page URL (Optional)"
            placeholderText="Enter Product Page URL"
            value={formData.productPageUrl}
            handleChange={(value: string) => onTextInputChange(value, 'productPageUrl')}
          />
          <CreateAppOptions
            items={createApplicationOptionItems}
            options={applicationOptions}
            handleChange={onApplicationOptionChange}
          />
          <div className="flex justify-between items-center w-full h-[80px] mt-4">
            <div className="flex flex-wrap items-center justify-center w-full h-auto text-center">
              {requestErrorMessage && <p style={{ color: 'red' }}>{requestErrorMessage}</p>}
            </div>
            <Button
              className={`min-h-[50px] max-h-[50px] p-5 text-white leading-none text-montserrat font-bold hover:bg-dark-purple ${
                isLoading() ? 'bg-light-grey border-light-purple text-light-purple ' : 'bg-purple text-white'
              } `}
              onClick={handleOnSubmit}
            >
              {isLoading() ? 'Creating Application...' : 'Create Application'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateApplication;
