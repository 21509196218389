import { inRange } from 'lodash';
import { ErrorResponse } from './api';

export const isErrorResponse = (data: Record<string, any> | undefined): data is ErrorResponse => {
  if (!data) return true;
  if (
    !data.status ||
    !data.message ||
    typeof data.message !== 'string' ||
    isNaN(data.status) ||
    inRange(data.status, 200, 299)
  ) {
    return false;
  }
  return true;
};
