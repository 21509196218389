export const tableHeaders = [
  {
    key: 'Delete',
    apiAlias: null,
    value: 'Delete',
  },
  {
    key: 'ApplicationName',
    apiAlias: 'application_name',
    value: 'Application Name',
  },
  {
    key: 'ApplicationID',
    apiAlias: 'application_id',
    value: 'Application ID',
  },
  {
    key: 'MerchantName',
    apiAlias: 'merchant_name',
    value: 'Merchant Name',
  },
  {
    key: 'MerchantID',
    apiAlias: 'merchant_id',
    value: 'Merchant ID',
  },
  {
    key: 'StartDate',
    apiAlias: 'start_date',
    value: 'Start Date',
  },
  {
    key: 'EndDate',
    apiAlias: 'end_date',
    value: 'End Date',
  },
  {
    key: 'Rate',
    apiAlias: 'multiplier',
    value: 'Multiplier',
  },
  {
    key: 'CreatedDate',
    apiAlias: 'created_date',
    value: 'Created Date',
  },
];
