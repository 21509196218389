import {
  SelectedMerchantPerformanceSummary,
  PerformanceSummaryByDate,
  PerformanceSummaryGroupedByMerchant,
} from 'api/performanceSummary/types';
import { generateDoubleYAxisChartData } from 'components/Charts/helpers';
import { LabelsAndPlots, DoubleAxisChartData } from 'components/Charts/types';
import { formatUTCDate } from 'helpers/DateHelpers';
import { ChartHeaderOption } from 'reduxState/store/performanceSummary/types';
import { MerchantLabelsAndProperties } from './types';

export const getGroupedByMerchantLabelsAndProperties = (sortBy: string): MerchantLabelsAndProperties => {
  switch (sortBy) {
    case 'net_commission_amount':
      return {
        properties: ['NetCommissionAmount', 'NetSalesAmount'],
        labels: ['Net Commissions', 'Net Sales'],
      };

    case 'base_commission_amount':
      return {
        properties: ['BaseCommissionAmount', 'BoostedCommissionAmount'],
        labels: ['Base Commissions', 'Boosted Commissions'],
      };

    case 'enhanced_commission_amount': // Boosted Commission Amount
      return {
        properties: ['BoostedCommissionAmount', 'BaseCommissionAmount'],
        labels: ['Boosted Commissions', 'Base Commissions'],
      };

    case 'gross_sales_amount':
      return {
        properties: ['GrossSalesAmount', 'GrossCommissionAmount'],
        labels: ['Gross Sales', 'Gross Commissions'],
      };

    case 'net_sales_amount':
      return {
        properties: ['NetSalesAmount', 'NetCommissionAmount'],
        labels: ['Net Sales', 'Net Commissions'],
      };

    case 'merchant_clicks':
      return {
        properties: ['MerchantClicks'],
        labels: ['Merchant Clicks'],
      };

    case 'order_count':
      return {
        properties: ['OrderCount'],
        labels: ['Order Count'],
      };

    case 'merchant_id':
    case 'gross_commission_amount':
    default: {
      return {
        properties: ['GrossCommissionAmount', 'GrossSalesAmount'],
        labels: ['Gross Commissions', 'Gross Sales'],
      };
    }
  }
};

const generateMerchantLabelsAndBars = (
  stats: PerformanceSummaryGroupedByMerchant[],
  y1Property: keyof PerformanceSummaryGroupedByMerchant,
  y2Property: keyof PerformanceSummaryGroupedByMerchant,
) => {
  const xAxisLabels: string[] = [];
  const y1ValuesArray: number[] = [];
  const y2ValuesArray: number[] = [];

  stats.forEach((stat: PerformanceSummaryGroupedByMerchant) => {
    xAxisLabels.push(stat.MerchantName);

    y1ValuesArray.push(Number(stat[y1Property]) || 0);
    if (y2Property) {
      y2ValuesArray.push(Number(stat[y2Property]) || 0);
    }
  });

  return {
    xAxisLabels,
    y1ValuesArray,
    y2ValuesArray,
  };
};

const generateMonthLabelsAndPlotsV2 = (
  stats: PerformanceSummaryByDate[] | SelectedMerchantPerformanceSummary[],
  y1Property: string,
  y2Property: string,
): LabelsAndPlots => {
  const xAxisLabels: string[] = [];
  const y1ValuesArray: number[] = [];
  const y2ValuesArray: number[] = [];

  stats.forEach((stat: Record<string, any>) => {
    const { ReportDate } = stat;

    if (!ReportDate) {
      throw new Error('Failed to generate month labels for performance summary chart.');
    }

    const xAxisLabelString = formatUTCDate(new Date(ReportDate), "MMM ''yy");

    xAxisLabels.push(xAxisLabelString);

    // Default to 0 if the property doesnt exist
    y1ValuesArray.push(Number(stat[y1Property]) || 0);
    y2ValuesArray.push(Number(stat[y2Property]) || 0);
  });

  return {
    xAxisLabels,
    y1ValuesArray,
    y2ValuesArray,
  };
};

const generateDayLabelsAndPlotsV2 = (
  stats: PerformanceSummaryByDate[] | SelectedMerchantPerformanceSummary[],
  y1Property: string,
  y2Property: string,
): LabelsAndPlots => {
  const xAxisLabels: string[] = [];
  const y1ValuesArray: number[] = [];
  const y2ValuesArray: number[] = [];

  stats.forEach((stat: Record<string, any>) => {
    const { ReportDate } = stat;

    if (!ReportDate) {
      throw new Error('Failed to generate day labels for performance summary chart.');
    }

    const labelString = formatUTCDate(new Date(ReportDate), 'd MMM');

    xAxisLabels.push(labelString);

    // Default to 0 if the property doesnt exist
    y1ValuesArray.push(Number(stat[y1Property]) || 0);
    y2ValuesArray.push(Number(stat[y2Property]) || 0);
  });

  return {
    xAxisLabels,
    y1ValuesArray,
    y2ValuesArray,
  };
};

export const getMerchantBarChartData = (
  merchantPerformanceSummaries: PerformanceSummaryGroupedByMerchant[],
  labelsAndProperties: MerchantLabelsAndProperties,
): DoubleAxisChartData => {
  const { properties, labels } = labelsAndProperties;
  const [y1Property, y2Property] = properties;
  const [y1Label, y2Label] = labels;

  const merchantBarsAndLabels = generateMerchantLabelsAndBars(merchantPerformanceSummaries, y1Property, y2Property);

  return generateDoubleYAxisChartData({ ...merchantBarsAndLabels, y1Label, y2Label });
};

export const getLineChartData = (args: {
  performanceSummaries: PerformanceSummaryByDate[] | SelectedMerchantPerformanceSummary[];
  groupBy: string;
  activeChartHeaderOption: ChartHeaderOption;
  selectedMerchant: boolean;
}): DoubleAxisChartData => {
  const { performanceSummaries, groupBy, activeChartHeaderOption, selectedMerchant } = args;
  const y1Property = activeChartHeaderOption.targetProperties[0];
  let y2Property = activeChartHeaderOption.targetProperties[1];
  const [y1Label, y2Label] = activeChartHeaderOption.legendLabels;

  // Override y2Property since `WildlinkClicks` doesnt exist on SelectedMerchantPerformanceSummary
  if (y2Property === 'WildlinkClicks' && selectedMerchant) {
    y2Property = 'MerchantClicks';
  }

  let labelsAndPlots = {} as LabelsAndPlots;
  switch (groupBy) {
    case 'month': {
      labelsAndPlots = generateMonthLabelsAndPlotsV2(
        performanceSummaries,
        y1Property.toString(),
        y2Property.toString(),
      );
      break;
    }
    case 'day': {
      labelsAndPlots = generateDayLabelsAndPlotsV2(performanceSummaries, y1Property.toString(), y2Property.toString());
      break;
    }
  }

  return generateDoubleYAxisChartData({ ...labelsAndPlots, y1Label, y2Label });
};
