import React, { FC, useContext } from 'react';
import { IsMobileContext } from 'App';
import RSuiteDateRangePicker from 'rsuite/DateRangePicker';
import { DateRange } from 'rsuite/DateRangePicker';
import { dateRanges } from './constants';
import 'rsuite/dist/rsuite.min.css';
import './DateRangePicker.scss';

const { afterToday } = RSuiteDateRangePicker;

interface DateRangePickerProps {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (dateRange: DateRange | null) => void;
  onDateClear: () => void;
  className?: string;
}

const DateRangePicker: FC<React.PropsWithChildren<DateRangePickerProps>> = ({
  label,
  startDate,
  endDate,
  onDateChange,
  onDateClear,
  className,
}) => {
  const isMobile = useContext(IsMobileContext);
  return (
    <div className={`date-pickers ${className}`}>
      <div className="placeholder-text">{label}</div>
      <RSuiteDateRangePicker
        {...(isMobile && { showOneCalendar: true })}
        size="lg"
        appearance="subtle"
        shouldDisableDate={afterToday!()}
        value={startDate && endDate ? [startDate, endDate] : null}
        onChange={dateRange => onDateChange(dateRange)}
        onClean={() => onDateClear()}
        ranges={dateRanges}
        data-testid="date-picker"
      />
    </div>
  );
};

export default DateRangePicker;
