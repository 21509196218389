import { DoubleAxisChartData } from '../types';

export const getMaxValue = (numberValue: number): number => {
  const numberValueLength = numberValue.toString().split('.')[0].length;

  // covers when numberValue is a negative and when numberValue is single digit
  if (numberValue < 5) {
    return 5;
  } else if (numberValue < 100) {
    return Math.ceil(numberValue / 10) * 10;
  }

  const maxValue = Number('1'.padEnd(numberValueLength, '0'));
  return Math.ceil(numberValue / maxValue) * maxValue;
};

export const generateDoubleYAxisChartData = (chartData: {
  xAxisLabels: string[];
  y1ValuesArray: number[];
  y2ValuesArray: number[];
  y1Label: string;
  y2Label: string;
}): DoubleAxisChartData => {
  const { xAxisLabels, y1ValuesArray, y2ValuesArray, y1Label, y2Label } = chartData;
  const y1HighestValue = Math.max(...y1ValuesArray);
  const y2HighestValue = Math.max(...y2ValuesArray);

  const y1MaxValue = getMaxValue(y1HighestValue);
  const y2MaxValue = getMaxValue(y2HighestValue);

  const y1StepSize = y1MaxValue / 5;
  const y2StepSize = y2MaxValue / 5;

  const y1 = {
    dataPlots: y1ValuesArray,
    maxValue: y1MaxValue,
    stepSize: y1StepSize,
    label: y1Label,
  };
  const y2 = {
    dataPlots: y2ValuesArray,
    maxValue: y2MaxValue,
    stepSize: y2StepSize,
    label: y2Label,
  };

  return {
    labels: xAxisLabels,
    y1,
    y2,
  };
};
