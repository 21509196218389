import { PerformanceSummaryColumn } from './types';

export const initialPerformanceSummaryColumns: PerformanceSummaryColumn[] = [
  {
    accessor: 'ReportDate',
    name: 'Report Date',
    visible: true,
    nonEditable: true,
    queryName: 'date',
  },
  {
    accessor: 'MerchantID',
    name: 'Merchant ID',
    visible: true,
    queryName: 'merchant_id',
  },
  {
    accessor: 'MerchantName',
    name: 'Merchant Name',
    visible: true,
    queryName: 'merchant_name',
  },
  {
    accessor: 'GrossCommissionAmount',
    name: 'Gross Commissions',
    visible: true,
    queryName: 'gross_commission_amount',
  },
  {
    accessor: 'BaseCommissionAmount',
    name: 'Base Commissions',
    visible: false,
    queryName: 'base_commission_amount',
  },
  {
    accessor: 'BoostedCommissionAmount',
    name: 'Boosted Commissions',
    visible: false,
    queryName: 'enhanced_commission_amount', //TODO: This is a temporary value, it must be switched to boosted_commission_amount once the backend is updated.
  },
  {
    accessor: 'NetCommissionAmount',
    name: 'Net Commissions',
    visible: true,
    queryName: 'net_commission_amount',
  },
  {
    accessor: 'DevicesCreated',
    name: 'Devices',
    visible: true,
    queryName: 'devices_created',
  },
  {
    accessor: 'ShortURLsCreated',
    name: 'Links Created',
    visible: true,
    queryName: 'shorturls_created',
  },
  {
    accessor: 'WildlinkClicks',
    name: 'Clicks',
    visible: true,
    queryName: 'wildlink_clicks',
  },
  {
    accessor: 'GrossSalesAmount',
    name: 'Gross Sales',
    visible: true,
    queryName: 'gross_sales_amount',
  },
  {
    accessor: 'NetSalesAmount',
    name: 'Net Sales',
    visible: true,
    queryName: 'net_sales_amount',
  },
  {
    accessor: 'ExtensionInstalls',
    name: 'Extension Installs',
    visible: true,
    queryName: 'extension_installs',
  },
  {
    accessor: 'ExtensionUninstalls',
    name: 'Extension Uninstalls',
    visible: true,
    queryName: 'extension_uninstalls',
  },
  {
    accessor: 'MerchantClicks',
    name: 'Merchant Clicks',
    visible: true,
    queryName: 'merchant_clicks',
  },
  {
    accessor: 'OrderCount',
    name: 'Order Count',
    visible: true,
    queryName: 'order_count',
  },
];

export const initialSelectedMerchantColumns: PerformanceSummaryColumn[] = [
  {
    accessor: 'ReportDate',
    name: 'Report Date',
    visible: true,
    nonEditable: true,
    queryName: 'date',
  },
  {
    accessor: 'MerchantID',
    name: 'Merchant ID',
    visible: true,
    queryName: 'merchant_id',
  },
  {
    accessor: 'GrossCommissionAmount',
    name: 'Gross Commissions',
    visible: true,
    queryName: 'gross_commission_amount',
  },
  {
    accessor: 'BaseCommissionAmount',
    name: 'Base Commissions',
    visible: false,
    queryName: 'base_commission_amount',
  },
  {
    accessor: 'BoostedCommissionAmount',
    name: 'Boosted Commissions',
    visible: false,
    queryName: 'enhanced_commission_amount', //TODO: This is a temporary value, it must be switched to boosted_commission_amount once the backend is updated.
  },
  {
    accessor: 'NetCommissionAmount',
    name: 'Net Commissions',
    visible: true,
    queryName: 'net_commission_amount',
  },
  {
    accessor: 'GrossSalesAmount',
    name: 'Gross Sales',
    visible: true,
    queryName: 'gross_sales_amount',
  },
  {
    accessor: 'NetSalesAmount',
    name: 'Net Sales',
    visible: true,
    queryName: 'net_sales_amount',
  },
  {
    accessor: 'MerchantClicks',
    name: 'Merchant Clicks',
    visible: true,
    queryName: 'merchant_clicks',
  },
  {
    accessor: 'OrderCount',
    name: 'Order Count',
    visible: true,
    queryName: 'order_count',
  },
];

export const initialGroupByMerchantColumns: PerformanceSummaryColumn[] = [
  {
    accessor: 'MerchantID',
    name: 'Merchant ID',
    visible: true,
    nonEditable: true,
    queryName: 'merchant_id',
  },
  {
    accessor: 'MerchantName',
    name: 'Merchant Name',
    visible: true,
    nonEditable: true,
    queryName: 'merchant_name',
  },
  {
    accessor: 'GrossCommissionAmount',
    name: 'Gross Commissions',
    visible: true,
    queryName: 'gross_commission_amount',
  },
  {
    accessor: 'BaseCommissionAmount',
    name: 'Base Commissions',
    visible: false,
    queryName: 'base_commission_amount',
  },
  {
    accessor: 'BoostedCommissionAmount',
    name: 'Boosted Commissions',
    visible: false,
    queryName: 'enhanced_commission_amount', //TODO: This is a temporary value, it must be switched to boosted_commission_amount once the backend is updated.
  },
  {
    accessor: 'NetCommissionAmount',
    name: 'Net Commissions',
    visible: true,
    queryName: 'net_commission_amount',
  },
  {
    accessor: 'GrossSalesAmount',
    name: 'Gross Sales',
    visible: true,
    queryName: 'gross_sales_amount',
  },
  {
    accessor: 'NetSalesAmount',
    name: 'Net Sales',
    visible: true,
    queryName: 'net_sales_amount',
  },
  {
    accessor: 'MerchantClicks',
    name: 'Merchant Clicks',
    visible: true,
    queryName: 'merchant_clicks',
  },
  {
    accessor: 'OrderCount',
    name: 'Order Count',
    visible: true,
    queryName: 'order_count',
  },
];

export const initialNonAPIColumns: PerformanceSummaryColumn[] = [
  {
    accessor: 'ReportDate',
    name: 'Report Date',
    visible: true,
    nonEditable: true,
    queryName: 'date',
  },
  {
    accessor: 'GrossCommissionAmount',
    name: 'Gross Commissions',
    visible: true,
    queryName: 'gross_commission_amount',
  },
  {
    accessor: 'BaseCommissionAmount',
    name: 'Base Commissions',
    visible: false,
    queryName: 'base_commission_amount',
  },
  {
    accessor: 'BoostedCommissionAmount',
    name: 'Boosted Commissions',
    visible: false,
    queryName: 'enhanced_commission_amount', //TODO: This is a temporary value, it must be switched to boosted_commission_amount once the backend is updated.
  },
  {
    accessor: 'NetCommissionAmount',
    name: 'Net Commissions',
    visible: true,
    queryName: 'net_commission_amount',
  },
  {
    accessor: 'GrossSalesAmount',
    name: 'Gross Sales',
    visible: true,
    queryName: 'gross_sales_amount',
  },
  {
    accessor: 'NetSalesAmount',
    name: 'Net Sales',
    visible: true,
    queryName: 'net_sales_amount',
  },
  {
    accessor: 'ShortURLsCreated',
    name: 'Links Created',
    visible: true,
    queryName: 'shorturls_created',
  },
  {
    accessor: 'WildlinkClicks',
    name: 'Clicks',
    visible: true,
    queryName: 'wildlink_clicks',
  },
  {
    accessor: 'DevicesCreated',
    name: 'Devices',
    visible: false,
    queryName: 'devices_created',
  },
  {
    accessor: 'ExtensionInstalls',
    name: 'Extension Installs',
    visible: true,
    queryName: 'extension_installs',
  },
  {
    accessor: 'ExtensionUninstalls',
    name: 'Extension Uninstalls',
    visible: true,
    queryName: 'extension_uninstalls',
  },
  {
    accessor: 'OrderCount',
    name: 'Order Count',
    visible: true,
    queryName: 'order_count',
  },
];

export const initialAPIColumns: PerformanceSummaryColumn[] = [
  {
    accessor: 'ReportDate',
    name: 'Report Date',
    visible: true,
    nonEditable: true,
    queryName: 'date',
  },
  {
    accessor: 'GrossCommissionAmount',
    name: 'Gross Commissions',
    visible: true,
    queryName: 'gross_commission_amount',
  },
  {
    accessor: 'BaseCommissionAmount',
    name: 'Base Commissions',
    visible: false,
    queryName: 'base_commission_amount',
  },
  {
    accessor: 'BoostedCommissionAmount',
    name: 'Boosted Commissions',
    visible: false,
    queryName: 'enhanced_commission_amount', //TODO: This is a temporary value, it must be switched to boosted_commission_amount once the backend is updated.
  },
  {
    accessor: 'NetCommissionAmount',
    name: 'Net Commissions',
    visible: true,
    queryName: 'net_commission_amount',
  },
  {
    accessor: 'GrossSalesAmount',
    name: 'Gross Sales',
    visible: true,
    queryName: 'gross_sales_amount',
  },
  {
    accessor: 'NetSalesAmount',
    name: 'Net Sales',
    visible: true,
    queryName: 'net_sales_amount',
  },
  {
    accessor: 'ShortURLsCreated',
    name: 'Links Created',
    visible: true,
    queryName: 'shorturls_created',
  },
  {
    accessor: 'WildlinkClicks',
    name: 'Clicks',
    visible: true,
    queryName: 'wildlink_clicks',
  },
  {
    accessor: 'DevicesCreated',
    name: 'Devices',
    visible: true,
    queryName: 'devices_created',
  },
  {
    accessor: 'OrderCount',
    name: 'Order Count',
    visible: true,
    queryName: 'order_count',
  },
];
