import { searchAndSort } from 'helpers';
import { ActiveDomain, ActiveDomainMerchantWithActiveDomainInfo } from './types';

export const getActiveDomainInfo = (
  activeDomains: ActiveDomain[],
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  searchValue: string,
): ActiveDomainMerchantWithActiveDomainInfo[] => {
  const activeDomainsWithInfo = activeDomains.map((activeDomain: ActiveDomain) => ({
    ActiveDomainID: activeDomain.ID,
    Domain: activeDomain.Domain,
    ...activeDomain.Merchant,
    DefaultRate: activeDomain.Merchant.DefaultRate
      ? {
          ...activeDomain.Merchant.DefaultRate,
          Amount: parseFloat(activeDomain.Merchant.DefaultRate.Amount),
        }
      : null,
    MaxRate: activeDomain.Merchant.MaxRate
      ? {
          ...activeDomain.Merchant.MaxRate,
          Amount: parseFloat(activeDomain.Merchant.MaxRate.Amount),
        }
      : null,
  }));

  // sort by result value first
  let result = searchAndSort(activeDomainsWithInfo, searchValue, null, sortOrder);
  if (sortBy) {
    let orderByAccessor;
    switch (sortBy) {
      case 'DefaultRate':
      case 'MaxRate':
        orderByAccessor = `${sortBy}.Amount`;
        break;
      default:
        orderByAccessor = sortBy;
    }

    // sort by accessor next
    result = searchAndSort(result, searchValue, orderByAccessor, sortOrder);
    if (orderByAccessor !== sortBy) {
      result = searchAndSort(result, '', `${sortBy}.Kind`, sortOrder);
    }
  }

  return result as ActiveDomainMerchantWithActiveDomainInfo[];
};
