import React, { FC } from 'react';
import { searchAndSort } from 'helpers';
import {
  FeaturedMerchant,
  FeaturedMerchantSortType,
  MerchantsToSort,
  MerchantType,
  SelectedMerchants,
} from 'reduxState/store/featuredMerchant/types';
import MerchantList from '../MerchantList';

export interface FeaturedMerchantCategoryProps {
  appId: string;
  featuredMerchants: FeaturedMerchant;
  featuredMerchantType: 'Priority' | 'Generic';
  isLoading: boolean;
  merchantsToSort: MerchantsToSort;
  searchValue: string;
  selectedMerchants: SelectedMerchants;
  setFeaturedMerchants: (featuredMerchant: FeaturedMerchant) => void;
  setMerchantsToSort: (merchants: MerchantsToSort) => void;
  setSelectedMerchants: (selectedMerchants: SelectedMerchants) => void;
}

const FeaturedMerchantCategory: FC<React.PropsWithChildren<FeaturedMerchantCategoryProps>> = ({
  appId,
  featuredMerchants,
  featuredMerchantType,
  isLoading,
  merchantsToSort,
  searchValue,
  selectedMerchants,
  setFeaturedMerchants,
  setMerchantsToSort,
  setSelectedMerchants,
}) => {
  const featuredMerchantsByType = featuredMerchants[featuredMerchantType] || [];
  // List of 'Generic' or 'Priority' merchants that match the given search value
  const searchedMerchants = searchAndSort(featuredMerchantsByType, searchValue, appId, 'asc');

  const onSelect = (merchant: MerchantType): void => {
    const { ID: merchantID } = merchant;
    let merchantByID;
    const currentMerchantsByType = selectedMerchants[featuredMerchantType];

    // If merchantID already exists, remove from the selectedMerchants list
    // Else, add to the list to toggle merchant checkbox
    if (currentMerchantsByType && currentMerchantsByType[merchantID]) {
      delete currentMerchantsByType[merchantID];
    } else {
      merchantByID = { [merchantID]: merchant };
    }

    setSelectedMerchants({
      ...selectedMerchants,
      [featuredMerchantType]: { ...currentMerchantsByType, ...merchantByID },
    });
  };

  const onSort = (merchant: MerchantType, sortType: FeaturedMerchantSortType): void => {
    if (featuredMerchantsByType.length === 1) return;
    const merchantId = merchant.ID;
    const featuredMerchantIdsByType = featuredMerchantsByType.map(merchant => merchant.ID);

    const currentPosition = featuredMerchantIdsByType.indexOf(merchantId);
    const nextPosition = sortType === 'up' ? currentPosition - 1 : currentPosition + 1;
    // If the first merchant is sorted to go up
    // or the last merchant is sorted to go down
    // do nothing
    if (!(nextPosition < 0 || nextPosition === featuredMerchantIdsByType.length)) {
      // Remove merchantId from current position and place it in its new position
      featuredMerchantIdsByType.splice(currentPosition, 1);
      featuredMerchantIdsByType.splice(nextPosition, 0, merchantId);
    }
    // Creates a mapping for the sorted featuredMerchantIdsByType {merchant Id => index}
    const sortedFeaturedMerchantIdsMap: Map<number, number> = new Map(
      featuredMerchantIdsByType.map((id, index) => [id, index]),
    );

    // Sorts the merchants in the expected order
    const sortedFeaturedMerchants = featuredMerchantsByType.slice().sort((a, b) => {
      const merchantA = sortedFeaturedMerchantIdsMap.get(a.ID);
      const merchantB = sortedFeaturedMerchantIdsMap.get(b.ID);

      if (merchantA !== undefined && merchantB !== undefined) {
        return merchantA - merchantB;
      }

      // Handle cases where the IDs are not found
      if (merchantA === undefined) {
        console.error(`Invalid merchant ID for merchant ${a.ID}`);
      }

      if (merchantB === undefined) {
        console.error(`Invalid merchant ID for merchant ${b.ID}`);
      }

      return 0; // Default return value in case of error
    });

    setFeaturedMerchants({
      ...featuredMerchants,
      [featuredMerchantType]: sortedFeaturedMerchants,
    });

    setMerchantsToSort({
      ...merchantsToSort,
      [featuredMerchantType]: { [merchant.ID]: merchant },
    });
  };

  return (
    <MerchantList
      title={`${featuredMerchantType} Merchants (${searchedMerchants.length})`}
      merchants={searchedMerchants}
      onSelect={onSelect}
      selected={selectedMerchants[featuredMerchantType] || {}}
      merchantsToSort={merchantsToSort[featuredMerchantType]}
      onSort={onSort}
      isLoading={isLoading}
    />
  );
};

export default FeaturedMerchantCategory;
