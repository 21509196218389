import COLORS from 'constants/colors';
import { ChartOptions, ChartData } from 'chart.js';
import { generateNumberAbbreviationLabel } from '../../LineChart/helpers';

interface BarChartOptions {
  maxValue: number;
  stepSize: number;
}

export const generateBarChartData = (
  firstBarChartOptions: BarChartOptions,
  secondBarData: BarChartOptions,
  data: ChartData<'bar'>,
  xAxisLabels: string[],
  applicationCurrency: string,
  showSecondAxis: boolean,
): ChartOptions<'bar'> => {
  const addApplicationCurrency = (datasetLabel: string) => {
    switch (datasetLabel) {
      case 'Merchant Clicks':
      case 'Order Count':
        return false;

      default: {
        return true;
      }
    }
  };

  return {
    responsive: true, // Resizes when window grows/shrinks
    maintainAspectRatio: false, // Makes graph fit within a given space
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        padding: 13,
        titleFont: {
          family: 'Montserrat',
          size: 16,
        },
        bodyFont: {
          family: 'Montserrat',
          size: 16,
        },
        mode: 'index',
        callbacks: {
          label: context => {
            const datasetIndex = context.datasetIndex;
            const value = context.formattedValue as string;
            const datasetLabel = data.datasets[datasetIndex]?.label || '';
            if (addApplicationCurrency(datasetLabel)) return ` ${datasetLabel}: ${value} ${applicationCurrency}`;
            return ` ${datasetLabel}: ${value}`;
          },
        },
      },
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'easeInQuad',
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false, // Hides X-axis grid lines
        },
        ticks: {
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: (tickValue, index, values) => {
            const label = xAxisLabels[index]!;

            if (!label) return;

            if (label.length > 12) {
              return label.substring(0, 8) + '...';
            }
            return xAxisLabels[index];
          },
        },
      },
      [showSecondAxis ? 'y1' : 'y']: {
        display: true,
        position: 'left',
        grid: {
          drawBorder: false,
        },
        min: 0,
        max: firstBarChartOptions.maxValue,
        ticks: {
          stepSize: firstBarChartOptions.stepSize,
          color: COLORS.lightPurple,
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: tickValue => {
            const y1MaxValueLength = firstBarChartOptions.maxValue.toString().length;
            const label = generateNumberAbbreviationLabel(Number(tickValue), y1MaxValueLength);
            return label;
          },
        },
      },
      y2: {
        display: showSecondAxis,
        position: 'right',
        grid: {
          drawBorder: false,
        },
        min: 0,
        max: secondBarData.maxValue,
        ticks: {
          stepSize: secondBarData.stepSize,
          color: COLORS.graphBlue,
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: tickValue => {
            const y2MaxValueLength = secondBarData.maxValue.toString().length;
            const label = generateNumberAbbreviationLabel(Number(tickValue), y2MaxValueLength);
            return label;
          },
        },
      },
    },
  };
};
