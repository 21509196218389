import React, { FC, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { isBefore } from 'date-fns';
import { debounce, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'components/DateRangePickerUTC/DateRangePickerUTC';
import TextInput from 'components/TextInput/TextInput';
import { findErrorMessage } from 'helpers';
import { searchMerchants } from 'helpers/api';
import { formatUTCDate } from 'helpers/DateHelpers';
import { isErrorResponse } from 'helpers/typeGuards';
import { useEditBoostedOfferCampaignMutation } from 'reduxState/store/boostedOffers/api';
import { BoostedOffer } from 'reduxState/store/boostedOffers/types';

// valid multipliers must contains only numbers, and may include a decimal point
const isValidMultiplier = (str: string) => /^\d*(\.\d+)?$/.test(str) && /\d/.test(str);

interface DropdownOption {
  label: string;
  id: string;
}

interface CampaignEditData {
  ID: number;
  ApplicationID: number;
  MerchantID: number;
  Rate: number;
  StartDate: string;
  EndDate: string;
  ModifiedAuthor: string;
  Note: string;
}

interface EditFormErrors {
  merchants: string;
  multiplier: string;
  startDate: string;
  endDate: string;
  note: string;
}

interface EditBoostedOfferCampaignFormProps {
  campaignToEdit: BoostedOffer;
  setCampaignToEdit: (campaign: BoostedOffer | null) => void;
  editFormRef: React.RefObject<HTMLDivElement>;
  isEditingActiveCampaign: boolean;
}

/**
 * EditBoostedOfferCampaignForm is a React component that allows users to edit the start/end date for boosted offer campaigns,
 * as well as the merchant associated with the campaign. Users can not edit the application associated with a campaign,
 * and cannot set start date or end date to a time in the past.
 *
 * @param campaignToEdit - Data corresponding to the campaign being edited
 * @param setCampaignToEdit - callback to set the campaign being edited. Can be set to null if no campaign is being edited.
 *
 * @component
 * @example
 * return (
 *   <EditBoostedOfferCampaignForm campaignToEdit={campaign} setCampaignToEdit={setCampaignToEdit} />
 * )
 */
const EditBoostedOfferCampaignForm: FC<EditBoostedOfferCampaignFormProps> = ({
  campaignToEdit,
  setCampaignToEdit,
  editFormRef,
  isEditingActiveCampaign,
}) => {
  const { ApplicationID, ApplicationName, MerchantID, MerchantName, Rate, StartDate, EndDate } = campaignToEdit;
  const { user } = useAuth0();
  const applicationOption: DropdownOption = {
    label: ApplicationName,
    id: ApplicationID.toString(),
  };
  const [merchantOptions, setMerchantOptions] = useState<DropdownOption[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<DropdownOption | null>({
    label: MerchantName,
    id: MerchantID.toString(),
  });
  const [multiplier, setMultiplier] = useState(Rate);
  const [startDate, setStartDate] = useState<Date>(new Date(StartDate));
  const [endDate, setEndDate] = useState<Date>(new Date(EndDate));
  const [note, setNote] = useState('');
  const [isMerchantsLoading, setIsMerchantsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState<EditFormErrors>({
    merchants: '',
    multiplier: '',
    startDate: '',
    endDate: '',
    note: '',
  });
  const [editBoostedOfferCampaign, { isLoading: isEditReqLoading }] = useEditBoostedOfferCampaignMutation();

  const handleMerchantSearch = async (query: string) => {
    if (query.length < 3) {
      return setMerchantOptions([]);
    }

    setIsMerchantsLoading(true);
    const results = await searchMerchants(query, '5');

    if (isErrorResponse(results)) {
      return setError(findErrorMessage(results));
    }

    const merchantResults = results.filter(result => result.Type === 'merchant' && !result.Disabled);
    const merchantDropdownOptions: DropdownOption[] = merchantResults.map(({ ID, Value }) => ({
      id: String(ID),
      label: Value,
    }));

    setMerchantOptions(merchantDropdownOptions);
    setIsMerchantsLoading(false);
  };

  const debouncedMerchantSearch = debounce(handleMerchantSearch, 300);

  const handleOnSubmit = async () => {
    const newFormErrors: Partial<EditFormErrors> = {};

    if (!multiplier || !isValidMultiplier(multiplier)) {
      newFormErrors.multiplier =
        'Invalid Multiplier provided. Multiplier must be a number, and may include a decimal value.';
    }

    const formattedEndDate = formatUTCDate(endDate, 'yyyy-MM-dd');
    const formattedCurrentDate = formatUTCDate(new Date(Date.now()), 'yyyy-MM-dd');

    const isEndDateBeforeOrEqualToToday =
      isBefore(new Date(formattedEndDate), new Date(formattedCurrentDate)) ||
      isEqual(new Date(formattedEndDate), new Date(formattedCurrentDate));

    if (!startDate) {
      newFormErrors.startDate = 'Campaign must have a Start Date.';
    } else if (!endDate) {
      newFormErrors.endDate = 'Campaign must have an End Date.';
    } else if (!(startDate < endDate)) {
      newFormErrors.startDate = 'Start Date for the campaign being edited must be before End Date.';
    } else if (!isEditingActiveCampaign && startDate < new Date(Date.now())) {
      newFormErrors.startDate = 'Start Date for the campaign being edited can not come before today.';
    } else if (isEditingActiveCampaign && isEndDateBeforeOrEqualToToday) {
      newFormErrors.endDate = 'End Date for the campaign being edited must be after today.';
    }

    if (!selectedMerchant) {
      newFormErrors.merchants = 'A merchant must be provided to correspond with the campaign.';
    }

    if (!note.trim()) {
      newFormErrors.note = 'Note is required when editing boosted offer.';
    }

    if (!isEqual(newFormErrors, {})) {
      return setFormErrors({ ...formErrors, ...newFormErrors });
    }

    const editedCampaign: CampaignEditData = {
      ID: campaignToEdit.ID,
      ApplicationID: Number(ApplicationID),
      MerchantID: Number(selectedMerchant!.id),
      Rate: Number(multiplier),
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString(),
      ModifiedAuthor: user!.email!,
      Note: note,
    };

    setError('');

    try {
      await editBoostedOfferCampaign({
        campaign: editedCampaign,
      }).unwrap();
      toast.success('Campaign edited successfully!');
      setCampaignToEdit(null);
    } catch (errorResponse) {
      let errorMessage = 'Failed to edit boosted offer campaign.';
      const error = errorResponse.data;
      if (error?.ErrorMessage) {
        errorMessage = error.ErrorMessage[0].toUpperCase() + error.ErrorMessage.slice(1);
      }
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <div ref={editFormRef} className="flex flex-col items-center flex-1 mt-12">
      <div className="max-w-[1400px] w-full h-fit flex flex-col font-montserrat bg-white rounded-md p-6 shadow-lg">
        <h4 className="w-full mb-2.5">Edit Boosted Offer Campaign</h4>
        <div className="flex flex-col w-full">
          <Autocomplete
            className="my-1"
            id="applications"
            options={[applicationOption]}
            multiple
            disabled
            value={[applicationOption]}
            filterSelectedOptions
            renderInput={params => <TextField {...params} label="Applications" placeholder="Select all applications" />}
          />
          <Autocomplete
            className="my-1"
            id="merchants"
            disabled={isEditingActiveCampaign}
            value={selectedMerchant}
            options={[...merchantOptions]}
            loading={isMerchantsLoading}
            isOptionEqualToValue={(option, value) => Boolean(option && value && option.id === value.id)}
            filterOptions={x => x}
            renderInput={params => (
              <TextField
                {...params}
                label="Merchant"
                placeholder="Select merchant"
                error={Boolean(formErrors.merchants)}
                helperText={formErrors.merchants}
              />
            )}
            onInputChange={(e, value) => debouncedMerchantSearch(value)}
            onChange={(e, value) => {
              setSelectedMerchant(value);
              setFormErrors({ ...formErrors, merchants: '' });
            }}
          />
          <TextInput
            label="Multiplier"
            value={multiplier}
            handleChange={val => {
              setMultiplier(val);
              setFormErrors({ ...formErrors, multiplier: '' });
            }}
            hasError={Boolean(formErrors.multiplier)}
            errorText={formErrors.multiplier}
            isDisabled={isEditingActiveCampaign}
          />
          <div className="flex flex-col lg:flex-row">
            <div className="flex">
              <DateRangePicker
                startDateDisabled={isEditingActiveCampaign}
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={value => {
                  const utcDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0));
                  setStartDate(utcDate);
                  setFormErrors({ ...formErrors, startDate: '' });
                }}
                onEndDateChange={value => {
                  const utcDate = new Date(
                    Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59),
                  );
                  setEndDate(utcDate);
                  setFormErrors({ ...formErrors, endDate: '' });
                }}
                startDateError={Boolean(formErrors.startDate)}
                endDateError={Boolean(formErrors.endDate)}
                startDateHelperText={formErrors.startDate}
                endDateHelperText={formErrors.endDate}
              />
            </div>
            <div className="flex flex-grow mt-3 lg:mt-0">
              <TextField
                label="Reason for change (Required)"
                fullWidth
                value={note}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNote(event.target.value);
                  setFormErrors({ ...formErrors, note: '' });
                }}
                error={Boolean(formErrors.note)}
                helperText={formErrors.note}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-full h-20 mt-4">
            <div className="flex items-center justify-center w-full h-auto">
              {error && (
                <div>
                  <p className="text-red">{error}</p>
                </div>
              )}
            </div>
            <Button
              className="min-h-[50px] max-h-[50px] px-5 mr-2.5 bg-light-grey text-dark-grey font-semibold hover:bg-dark-purple hover:text-white"
              onClick={() => setCampaignToEdit(null)}
            >
              CANCEL
            </Button>
            <Button
              className={`min-h-[50px] max-h-[50px] px-5 font-semibold leading-snug ${
                isEditReqLoading
                  ? 'bg-light-grey2 text-light-purple'
                  : 'bg-light-purple text-white hover:bg-dark-purple'
              }`}
              disabled={isEditReqLoading}
              onClick={handleOnSubmit}
            >
              {isEditReqLoading ? 'Editing Campaign...' : 'Edit Campaign'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBoostedOfferCampaignForm;
