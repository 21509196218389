import { ChartHeaderOption } from 'reduxState/store/performanceSummary/types';

// Currently, only Properties of PerformanceSummaryByDate are targeted.
export const chartOptions: ChartHeaderOption[] = [
  {
    title: 'Commissions / Sales',
    targetProperties: ['GrossCommissionAmount', 'GrossSalesAmount'],
    legendLabels: ['Gross Commissions', 'Gross Sales'],
  },
  {
    title: 'Commissions / Clicks',
    targetProperties: ['GrossCommissionAmount', 'WildlinkClicks'],
    legendLabels: ['Gross Commissions', 'Clicks'],
  },
  {
    title: 'Commissions / Orders',
    targetProperties: ['GrossCommissionAmount', 'OrderCount'],
    legendLabels: ['Gross Commissions', 'Order Count'],
  },
];
