import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ExpandMore, UnfoldMore, DisabledByDefaultRounded } from '@mui/icons-material';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Checkbox } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { formatUTCDate } from 'helpers/DateHelpers';
import { BoostedOffer, BoostedOfferSortBy, CampaignsData, CampaignsType } from 'reduxState/store/boostedOffers/types';
import { tableHeaders } from './constants';
import TableSortLabel from '../../TableSortLabel/TableSortLabel';

interface BoostedOffersTableProps {
  campaignsData: CampaignsData;
  campaignsType: CampaignsType;
  error: string | Error;
  isLoading: boolean;
  isDeleteMode: boolean;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  sortTable: (header: BoostedOfferSortBy) => void;
  toggleCampaignToDelete: (campaign: BoostedOffer, shouldBeDeleted: boolean) => void;
  setCampaignToEdit: (campaign: BoostedOffer | null) => void;
  setIsEditingActiveCampaign: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoostedOffersTable: FC<React.PropsWithChildren<BoostedOffersTableProps>> = ({
  campaignsData,
  campaignsType,
  error,
  isLoading,
  isDeleteMode,
  sortBy,
  sortOrder,
  sortTable,
  toggleCampaignToDelete,
  setCampaignToEdit,
  setIsEditingActiveCampaign,
}) => {
  const [hoveredCampaignId, setHoveredCampaignId] = useState('');
  const { user } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);

  const canEditCampaign = hasWfPermissions && !isDeleteMode && campaignsType !== 'past';

  const renderHeader = () =>
    tableHeaders.map((header, index) => {
      const isActive = sortBy === header.key;
      switch (header.key) {
        case 'Delete':
          return isDeleteMode ? <TableCell key={index.toString()}>Delete</TableCell> : null;
        case 'ApplicationID':
        case 'StartDate':
        case 'EndDate':
          return (
            <TableCell key={index.toString()}>
              <TableSortLabel
                active={isActive}
                direction={sortOrder}
                onClick={(): void => sortTable(header.apiAlias as BoostedOfferSortBy)}
                IconComponent={isActive ? ExpandMore : UnfoldMore}
              >
                {header.value}
              </TableSortLabel>
            </TableCell>
          );
        default:
          return <TableCell key={index.toString()}>{header.value}</TableCell>;
      }
    });

  const editCampaign = (campaign: BoostedOffer) => {
    if (campaignsType === 'future') {
      setCampaignToEdit(campaign);
      setIsEditingActiveCampaign(false);
    }

    if (campaignsType === 'present') {
      setCampaignToEdit(campaign);
      setIsEditingActiveCampaign(true);
    }
  };

  const renderBody = () =>
    campaignsData[campaignsType].map((campaign, index) => {
      const msBeforeStart = new Date(campaign.StartDate).getTime() - Date.now();
      const startWithinTwoHrs = msBeforeStart <= 1000 * 60 * 60 * 2;
      return (
        <TableRow
          onMouseEnter={() => setHoveredCampaignId(campaign.ID.toString())}
          onMouseLeave={() => setHoveredCampaignId('')}
          hover={hoveredCampaignId === campaign.ID.toString()}
          key={campaign.ID.toString()}
          onClick={canEditCampaign ? () => editCampaign(campaign) : undefined}
          style={canEditCampaign ? { cursor: 'pointer' } : undefined}
        >
          {isDeleteMode && (
            <TableCell className="overflow-visible font-montserrat max-w-[300px] text-ellipsis p-3 whitespace-nowrap">
              {startWithinTwoHrs ? (
                <div className="flex items-center justify-center overflow-visible">
                  <span className="flex items-center justify-center overflow-visible tooltip">
                    <DisabledByDefaultRounded color="error" />
                    <span className="flex items-center justify-center overflow-visible tooltip-text">
                      Campaigns starting within two hours cannot be deleted
                    </span>
                  </span>
                </div>
              ) : (
                <Checkbox
                  onChange={e => toggleCampaignToDelete(campaign, e.target.checked)}
                  disabled={startWithinTwoHrs}
                  title={startWithinTwoHrs ? 'Campaigns starting within two hours cannot be deleted' : undefined}
                />
              )}
            </TableCell>
          )}
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {campaign.ApplicationName}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {campaign.ApplicationID}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {campaign.MerchantName}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {campaign.MerchantID}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {formatUTCDate(campaign.StartDate, 'M/dd/yyyy H:mm zzz')}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {formatUTCDate(campaign.EndDate, 'M/dd/yyyy H:mm zzz')}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {campaign.Rate}
          </TableCell>
          <TableCell className="font-montserrat max-w-[300px] overflow-hidden text-ellipsis p-3 whitespace-nowrap">
            {formatUTCDate(campaign.CreatedDate, 'M/dd/yyyy')}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TableContainer className="table-container mt-[30px] max-h-[400px] max-w-[1400px] overflow-auto text-center rounded-md shadow-lg">
        {error ? (
          <Paper style={{ padding: '10px' }}>
            <p>An error occurred. Please try again in a few minutes.</p>
            <p>{error instanceof Error ? error.message : error}</p>
          </Paper>
        ) : isLoading && !campaignsData[campaignsType].length ? ( // only show loading spinner if there is currently no campaigns to show, so that the table doesn't flash every time it is updated
          <Paper style={{ padding: '10px' }}>
            <Loading data-testid="loading" />
          </Paper>
        ) : !campaignsData[campaignsType].length ? (
          <Paper style={{ padding: '10px' }}>
            <p>{`There are no ${campaignsType} campaigns for the selected application(s). Try again.`}</p>
          </Paper>
        ) : (
          <Table className="bg-white">
            <TableHead
              className="sticky top-0 z-10 table-header-group bg-light-grey"
              sx={{
                '& .MuiTableCell-root': {
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: 'var(--dark-grey)',
                  textWrap: 'nowrap',
                  textTransform: 'uppercase',
                  padding: '10px',
                  borderRight: '1px solid var(--dark-grey)',
                  backgroundColor: 'var(--light-grey)',

                  '&:last-of-type': {
                    border: 'none',
                  },
                },
              }}
            >
              <TableRow>{renderHeader()}</TableRow>
            </TableHead>
            <TableBody>{renderBody()}</TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default BoostedOffersTable;
