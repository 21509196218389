import { subDays, endOfDay, startOfYear, startOfMonth } from 'date-fns';
import { RangeType } from 'rsuite/DateRangePicker';

export const dateRanges = [
  {
    label: 'last7Days',
    value: [subDays(new Date(), 6), endOfDay(new Date())],
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), endOfDay(new Date())],
  },
  {
    label: 'This year',
    value: [startOfYear(new Date()), endOfDay(new Date())],
  },
] as RangeType[];
