import React, { FC, useState } from 'react';
import COLORS from 'constants/colors';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from '@mui/material';
import { startOfDay, endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { debounce } from 'lodash';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'components/DateRangePickerUTC/DateRangePickerUTC';
import TextInput from 'components/TextInput/TextInput';
import { findErrorMessage } from 'helpers';
import { searchMerchants } from 'helpers/api';
import { isErrorResponse } from 'helpers/typeGuards';
import { useCreateBoostedOfferCampaignsMutation } from 'reduxState/store/boostedOffers/api';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { getAppOptions } from '../helpers';

const isValidMultiplier = (str: string) => /^\d*(\.\d+)?$/.test(str) && /\d/.test(str);

interface DropdownOption {
  label: string;
  id: string;
}

interface CampaignCreationData {
  ApplicationID: number;
  MerchantID: number;
  Rate: number;
  StartDate: string;
  EndDate: string;
  ModifiedAuthor: string;
}

interface FailedCampaignCreationData {
  ApplicationID: number;
  MerchantID: number;
  Error: string;
}

interface FormErrors {
  applications: string;
  merchants: string;
  multiplier: string;
  startDate: string;
  endDate: string;
}

/**
 * CreateBoostedOfferCampaignForm is a React component that allows users to create boosted offer campaigns.
 * The component provides a form to input campaign details such as applications, merchants, multiplier,
 * start date, and end date. It also displays any failed campaign creations.
 *
 * @component
 * @example
 * return (
 *   <CreateBoostedOfferCampaignForm />
 * )
 */
const CreateBoostedOfferCampaignForm: FC = () => {
  const { user } = useAuth0();
  const { data: applicationsData } = useGetUserApplicationsQuery();
  const applications = applicationsData?.applications || [];
  const [merchantOptions, setMerchantOptions] = useState<DropdownOption[]>([]);
  const [selectedAppOptions, setSelectedAppOptions] = useState<DropdownOption[]>([]);
  const [selectedMerchantOptions, setSelectedMerchantOptions] = useState<DropdownOption[]>([]);
  const [multiplier, setMultiplier] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isMerchantsLoading, setIsMerchantsLoading] = useState(false);
  const [failedCampaignsToCreate, setFailedCampaigns] = useState<FailedCampaignCreationData[]>([]);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState<FormErrors>({
    applications: '',
    merchants: '',
    multiplier: '',
    startDate: '',
    endDate: '',
  });
  const [appSearchValue, setAppSearchValue] = useState('');
  const [createBoostedOfferCampaigns, { isLoading: isCreateReqLoading }] = useCreateBoostedOfferCampaignsMutation();

  const renderFailedCampaignRow = ({ ApplicationID, MerchantID, Error }: FailedCampaignCreationData) => {
    const appName = selectedAppOptions.find(option => option.id === ApplicationID.toString())?.label;
    const merchantName = selectedMerchantOptions.find(option => option.id === MerchantID.toString())?.label;
    if (!appName || !merchantName) return <></>;
    return (
      <TableRow key={`${appName}${merchantName}`}>
        <TableCell>{appName}</TableCell>
        <TableCell>{merchantName}</TableCell>
        <TableCell>{Error}</TableCell>
      </TableRow>
    );
  };

  const clearForm = () => {
    setMerchantOptions([]);
    setSelectedAppOptions([]);
    setSelectedMerchantOptions([]);
    setMultiplier('');
    setStartDate(null);
    setEndDate(null);
    setIsMerchantsLoading(false);
    setFailedCampaigns([]);
    setError('');
    setFormErrors({
      applications: '',
      merchants: '',
      multiplier: '',
      startDate: '',
      endDate: '',
    });
    setAppSearchValue('');
  };

  const handleMerchantSearch = async (query: string) => {
    if (query.length < 3) {
      return setMerchantOptions([]);
    }

    setIsMerchantsLoading(true);
    const results = await searchMerchants(query, '5');

    if (isErrorResponse(results)) {
      return setError(findErrorMessage(results));
    }

    const merchantResults = results.filter(result => result.Type === 'merchant' && !result.Disabled);
    const merchantDropdownOptions: DropdownOption[] = merchantResults.map(({ ID, Value }) => ({
      id: String(ID),
      label: Value,
    }));

    setMerchantOptions(merchantDropdownOptions);
    setIsMerchantsLoading(false);
  };

  const debouncedMerchantSearch = debounce(handleMerchantSearch, 300);

  const handleOnSubmit = async () => {
    const newFormErrors: Partial<FormErrors> = {};

    if (!multiplier || !isValidMultiplier(multiplier)) {
      newFormErrors.multiplier =
        'Invalid Multiplier provided. Multiplier must be a number, and may include a decimal value.';
    }

    if (!startDate) {
      newFormErrors.startDate = 'Campaign must have a start date.';
    } else if (!endDate) {
      newFormErrors.endDate = 'Campaign must have an end date.';
    } else if (!(startDate < endDate)) {
      newFormErrors.startDate = 'Start Date for the campaign must be before End Date.';
    } else if (startDate < new Date(Date.now())) {
      newFormErrors.startDate = 'Start Date for the campaign can not come before the day the campaign was created.';
    }

    if (!selectedAppOptions.length) {
      newFormErrors.applications = 'At least one application must be provided.';
    }

    if (!selectedMerchantOptions.length) {
      newFormErrors.merchants = 'At least one merchant must be provided.';
    }

    if (!isEqual(newFormErrors, {})) {
      return setFormErrors({ ...formErrors, ...newFormErrors });
    }

    const campaignsToCreate: CampaignCreationData[] = [];
    selectedAppOptions.forEach(app => {
      selectedMerchantOptions.forEach(merchant => {
        campaignsToCreate.push({
          ApplicationID: Number(app.id),
          MerchantID: Number(merchant.id),
          Rate: Number(multiplier),
          StartDate: zonedTimeToUtc(startOfDay(startDate!), 'UTC').toISOString(),
          EndDate: zonedTimeToUtc(endOfDay(endDate!), 'UTC').toISOString(),
          ModifiedAuthor: user!.email!,
        });
      });
    });

    setFailedCampaigns([]);
    setError('');

    try {
      await createBoostedOfferCampaigns({
        campaigns: campaignsToCreate,
      }).unwrap();
      toast.success(`Campaign${campaignsToCreate.length > 1 ? 's' : ''} created successfully!`);
      setFailedCampaigns([]);
    } catch (errorResponse) {
      let errorMessage = 'Failed to create boosted offer campaigns.';
      const error = errorResponse.data;
      if (error?.ErrorMessage) {
        errorMessage = error.ErrorMessage[0].toUpperCase() + error.ErrorMessage.slice(1);
        if (error?.Failed) {
          setFailedCampaigns(error.Failed as FailedCampaignCreationData[]);
        }
      }
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="flex flex-col items-center flex-1 mt-12">
      <div className="max-w-[1400px] w-full h-fit flex flex-col font-montserrat bg-white rounded-md p-6 shadow-lg">
        <h4 className="w-full mb-2.5">Create Boosted Offer Campaign</h4>
        <div className="flex flex-col w-full">
          <Autocomplete
            sx={{ my: 1 }}
            id="applications"
            options={getAppOptions(applications)}
            value={selectedAppOptions}
            loading={!getAppOptions(applications).length}
            multiple
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputValue={appSearchValue}
            onInputChange={(e, value, reason) => {
              if (reason !== 'reset') setAppSearchValue(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Applications"
                placeholder="Select all applications"
                error={Boolean(formErrors.applications)}
                helperText={formErrors.applications}
              />
            )}
            onChange={(e, value) => {
              setSelectedAppOptions(value);
              setFormErrors({ ...formErrors, applications: '' });
            }}
            disableCloseOnSelect
          />
          <Autocomplete
            sx={{ my: 1 }}
            multiple
            id="merchants"
            value={selectedMerchantOptions}
            options={[...selectedMerchantOptions, ...merchantOptions]}
            loading={isMerchantsLoading}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={x => x}
            renderInput={params => (
              <TextField
                {...params}
                label="Merchants"
                placeholder="Select all merchants"
                error={Boolean(formErrors.merchants)}
                helperText={formErrors.merchants}
              />
            )}
            onInputChange={(e, value) => debouncedMerchantSearch(value)}
            onChange={(e, value) => {
              setSelectedMerchantOptions(value);
              setFormErrors({ ...formErrors, merchants: '' });
            }}
          />
          <TextInput
            label="Multiplier"
            value={multiplier}
            handleChange={val => {
              setMultiplier(val);
              setFormErrors({ ...formErrors, multiplier: '' });
            }}
            hasError={Boolean(formErrors.multiplier)}
            errorText={formErrors.multiplier}
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={value => {
              setStartDate(value);
              setFormErrors({ ...formErrors, startDate: '' });
            }}
            onEndDateChange={value => {
              setEndDate(value);
              setFormErrors({ ...formErrors, endDate: '' });
            }}
            startDateError={Boolean(formErrors.startDate)}
            endDateError={Boolean(formErrors.endDate)}
            startDateHelperText={formErrors.startDate}
            endDateHelperText={formErrors.endDate}
          />
          <div className="flex items-center justify-end w-full h-20 mt-4">
            <div className="flex items-center justify-center w-full h-auto">
              {error && (
                <div>
                  <p style={{ color: 'red' }}>{error}</p>
                </div>
              )}
            </div>
            <Button
              className="min-h-[50px] max-h-[50px] px-5 mr-2.5 bg-light-grey text-dark-grey font-semibold hover:bg-dark-purple hover:text-white"
              onClick={clearForm}
            >
              CLEAR
            </Button>
            <Button
              className={`min-h-[50px] max-h-[50px] px-5 font-semibold leading-snug ${
                isCreateReqLoading
                  ? 'bg-light-grey2 text-light-purple'
                  : 'bg-light-purple text-white hover:bg-dark-purple'
              }`}
              disabled={isCreateReqLoading}
              onClick={handleOnSubmit}
            >
              {isCreateReqLoading ? 'Creating Campaign...' : 'Create Campaign'}
            </Button>
          </div>
          {!!failedCampaignsToCreate.length && (
            <Paper variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Merchant</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {failedCampaignsToCreate.map(failedCampaign => renderFailedCampaignRow(failedCampaign))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateBoostedOfferCampaignForm;
