import React, { FC } from 'react';
import { Button } from '@mui/material';
import leftArrows from '../../../static/images/leftArrows.png';
import rightArrows from '../../../static/images/rightArrows.png';
import './FeaturedMerchantButtons.scss';

export interface FeaturedMerchantButtonsProps {
  onAdd: () => void;
  onRemove: () => void;
  isLoading: boolean;
}

const AddOrRemoveButtons: FC<React.PropsWithChildren<FeaturedMerchantButtonsProps>> = ({
  onAdd,
  onRemove,
  isLoading,
}) => {
  return (
    <div className="button-group priority-buttons">
      <Button variant="contained" color="primary" disabled={isLoading} onClick={onAdd}>
        Add
        <img className="right-arrows" src={rightArrows} alt="2 arrows pointing right" />
      </Button>
      <Button variant="contained" color="primary" disabled={isLoading} onClick={onRemove}>
        <img className="left-arrows" src={leftArrows} alt="2 arrows pointing left" />
        Remove
      </Button>
    </div>
  );
};

export default AddOrRemoveButtons;
