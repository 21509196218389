import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading/Loading';
import './Login.scss';

const Login: FC = () => {
  const auth0 = useAuth0();
  const { isAuthenticated, isLoading, loginWithRedirect, user } = auth0;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate((location.state && location.state.from) || '/');
    }
    // redirect to Auth0 if user is not authenticated
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: location.state?.from,
        },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location, navigate, user, auth0]);

  if (isLoading) {
    return <Loading />;
  }

  return null;
};

export default Login;
