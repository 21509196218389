import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Edit, Undo } from '@mui/icons-material';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { useUpdateApplicationManagementMutation } from 'reduxState/store/applicationManagement/api';
import { ConfirmEdit } from './ConfirmEdit';
import { ApplicationManagement } from '../../../reduxState/store/applicationManagement/types';
import '../scss/AppName.scss';

export interface AppNameProps {
  applicationId: string;
  Name: string;
  applicationManagement: ApplicationManagement | null;
}

export const AppName: FC<React.PropsWithChildren<AppNameProps>> = ({ applicationId, Name, applicationManagement }) => {
  const { user } = useAuth0();
  const [isDisabled, setDisabled] = useState(true);
  const [applicationName, setApplicationName] = useState(Name);
  const [updateApplicationManagement] = useUpdateApplicationManagementMutation();

  useEffect(() => {
    if (Name) setApplicationName(Name);
  }, [Name]);

  const saveApplicationName = (): void => {
    if (!applicationManagement?.Name) return;
    applicationManagement.Name = applicationName;
    updateApplicationManagement({
      appId: applicationId,
      applicationManagement,
      author: user!.email!,
    });
  };

  const toggleDisabled = (): void => setDisabled(prevState => !prevState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setApplicationName(event.currentTarget.value);
  };

  const cancelEdit = (): void => {
    setApplicationName(Name);
    setDisabled(true);
  };

  const handleSave = (): void => {
    saveApplicationName();
    toggleDisabled();
  };

  return (
    <div className="application-name settings-form-group ">
      <TextField
        id="applicationName"
        className={`application-name-input ${!isDisabled ? 'user-editing' : ''}`}
        label="Application Name"
        value={applicationName}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        inputRef={input => input && !isDisabled && input.focus()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {!isDisabled ? (
                <Tooltip title="Cancel editing" role="tooltip">
                  <Undo onClick={cancelEdit} className="edit-icon-undo" data-testid="undo-button" />
                </Tooltip>
              ) : (
                <Edit onClick={toggleDisabled} className="edit-icon" data-testid="edit-button" />
              )}
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        disabled={isDisabled}
        data-testid="app-name-textbox"
      />
      {!isDisabled && (
        <ConfirmEdit
          headerText={'Edit Application Name'}
          confirmText={`Confirm save to update application name:`}
          changeText={applicationName}
          onSave={handleSave}
        />
      )}
    </div>
  );
};
