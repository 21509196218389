export const SERVER_ENDPOINT = import.meta.env.VITE_APP_SERVER_URI || window.env.serverUri;

export const COMMISSIONS_HISTORY_MAX_DOWNLOAD_DAYS_RANGE = 30;

export const DATE_FORMAT = 'yyyy-MM-dd'; // Format for date-fns library

export const DATA_URLS = {
  CLOUD_DATA_BASE_URI: import.meta.env.VITE_APP_CLOUD_DATA_BASE_URI || window.env.cloudDataBaseUri,
};

export const AUTH0_DOMAIN = import.meta.env.VITE_APP_AUTH0_DOMAIN || window.env.auth0Domain;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_APP_AUTH0_CLIENT_ID || window.env.auth0ClientId;
export const AUTH0_AUDIENCE = import.meta.env.VITE_APP_AUTH0_AUDIENCE || window.env.auth0Audience;

export const ACTIVE_DOMAINS_MERCHANTS_TABLE_ROW_HEIGHT = 50;

export const SENTRY_DSN = 'https://2ee2cf8c7e544b6eb45979d668b05378@o170013.ingest.sentry.io/5437601';

export const AUTH0_NAMESPACE = 'https://www.wildlink.me';

export const WF_CLIENT_APP_ID = '3';

export const STAT_COLUMNS_VERSION = '1.1.2';

export const IS_UNDER_MAINTENANCE = import.meta.env.IS_UNDER_MAINTENANCE;
