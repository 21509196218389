import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { allFlags } from './allFlags';
import { FeatureFlagState, UpdateFeatureFlagPayload } from './types';

export const initialFeatureFlagState = (): FeatureFlagState => {
  const allFlagsCopy = JSON.parse(JSON.stringify(allFlags));
  const savedFlagsJSON = localStorage.getItem('feature_flags');

  if (savedFlagsJSON) {
    const savedFlags = JSON.parse(savedFlagsJSON);
    for (const key in savedFlags) {
      if (allFlagsCopy[key]) {
        allFlagsCopy[key] = savedFlags[key];
      }
    }
  }
  localStorage.setItem('feature_flags', JSON.stringify(allFlagsCopy));
  return {
    features: allFlagsCopy,
  };
};

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState: initialFeatureFlagState,
  reducers: {
    updateFeatureFlag(state: FeatureFlagState, action: PayloadAction<UpdateFeatureFlagPayload>) {
      const { featureFlag, enabled } = action.payload;
      state.features[featureFlag].enabled = enabled;
      localStorage.setItem('feature_flags', JSON.stringify(state.features));
    },
  },
});

export const { updateFeatureFlag } = featureFlagSlice.actions;

export const featureFlagReducer = featureFlagSlice.reducer;
