import React, { FC } from 'react';
import TableSortLabel, { TableSortLabelProps } from '@mui/material/TableSortLabel';
import clsx from 'clsx';

import './TableSortLabel.scss';

interface OwnProps extends TableSortLabelProps {
  className?: string;
}

const CustomTableSortLabel: FC<React.PropsWithChildren<OwnProps>> = ({ children, className, ...restProps }) => {
  return (
    <TableSortLabel className={clsx('default-table-sort-label', className)} {...restProps}>
      {children}
    </TableSortLabel>
  );
};

export default CustomTableSortLabel;
