import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FormControl, FormLabel, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ClientApplicationOptions } from 'reduxState/store/application/types';
import { CreateApplicationOption } from '../../constants';

export interface CreateAppOptionsProps {
  items: CreateApplicationOption[];
  options: ClientApplicationOptions;
  handleChange: (option: keyof ClientApplicationOptions) => void;
  isDisabled?: boolean;
}

const CreateAppOptions: FC<CreateAppOptionsProps> = ({ items, options, handleChange, isDisabled }) => {
  return (
    <>
      <Styled.FormLabel>Options</Styled.FormLabel>
      <Styled.GridContainer>
        <Grid container spacing={1} columns={{ xs: 4, sm: 9, md: 8 }}>
          {items.map((item: CreateApplicationOption, index: number) => {
            return (
              <Grid item xs={2} sm={3} md={2} key={index}>
                <Styled.FormControlLabel
                  size="small"
                  control={
                    <Checkbox
                      checked={!!options?.[item.propertyTarget]}
                      onChange={() => {
                        handleChange(item.propertyTarget);
                      }}
                      disabled={isDisabled}
                    />
                  }
                  label={<Styled.Typography>{item.label}</Styled.Typography>}
                />
              </Grid>
            );
          })}
        </Grid>
      </Styled.GridContainer>
    </>
  );
};

export default CreateAppOptions;

const Styled = {
  FormControl: styled((props: any) => <FormControl {...props} />)({
    margin: 1,
  }),
  FormLabel: styled((props: any) => <FormLabel {...props} />)({
    fontFamily: 'inherit',
    fontWeight: 'bold',
    marginBottom: '10px',
  }),
  FormControlLabel: styled((props: any) => <FormControlLabel {...props} />)({
    fontFamily: 'inherit',
  }),
  GridContainer: styled.div({
    marginLeft: '10px',
  }),
  Typography: styled((props: any) => <Typography {...props} />)({
    fontFamily: 'inherit',
    fontSize: '13px',
  }),
};
