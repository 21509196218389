import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import api from './store/api/slice';
import { appGroupCommissionReducer, commissionReducer } from './store/commission/slice';
import { featureFlagReducer } from './store/featureFlag/slice';
import { performanceSummaryReducer } from './store/performanceSummary/slice';
import { userReducer } from './store/user/slice';

// TODO: Remove this localStorage clear once backend has diagnosed and fixed
// the issue that results in requests failing due to app id mismatching
localStorage.removeItem('devices');

export const rootReducer = combineReducers({
  appGroupCommission: appGroupCommissionReducer,
  commission: commissionReducer,
  featureFlag: featureFlagReducer,
  performanceSummary: performanceSummaryReducer,
  user: userReducer,
  [api.reducerPath]: api.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: import.meta.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
