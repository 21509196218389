import { Identity, Role } from 'auth0';
import { ErrorResponse, request } from 'helpers/api';

export interface UserWithRoles {
  email: string;
  name: string;
  picture: string;
  roles: Role[];
  identities: Identity[];
  user_id: string;
}

export const inviteUserToApplication = async (
  appId: string,
  email: string,
  applicationRoleName: string,
  userRoleName: string,
): Promise<any> => {
  try {
    return await request('POST', `/api/user/${appId}`, {
      email,
      applicationRoleName,
      userRoleName,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const requestChangePasswordEmail = async (appId: string, email: string): Promise<any> => {
  try {
    return await request('POST', `/api/user/${appId}/reset-password`, {
      email,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const removeUserFromApplication = async (
  appId: string,
  userId: string,
  applicationRoleName: string,
): Promise<any> => {
  try {
    return await request('delete', `/api/user/${userId}/application/${appId}`, {
      applicationRoleName,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const getAuth0UsersByRole = async (
  appId: string,
  roleName: string,
): Promise<UserWithRoles[] | ErrorResponse | undefined> => {
  try {
    const response = await request('GET', `/api/user/${appId}/role/${roleName}`);
    if (response.ok) {
      const data = await response.json();

      return data;
    }
  } catch (error) {
    console.warn(error.message);
  }
};
