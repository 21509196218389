export const columnDefinitions: Record<string, string> = {
  GrossCommissionAmount: 'Sum of positive commissions for the period selected',
  NetCommissionAmount: 'Sum of all the commissions, including negative commissions for the period selected',
  ShortURLsCreated: 'Total number of links created for the period selected (i.e. from Share & Earn)',
  WildlinkClicks:
    'Total number of clicks originated for the period selected (i.e. activating cashback offers, Couponator activations)',
  GrossSalesAmount: 'Sum of positive sales for the period selected',
  NetSalesAmount: 'Sum of all the sales, including negative sales for the period selected',
  ExtensionInstalls:
    'Total number of new extensions installed during the period selected (Note: for Mobile Safari Extension, an installation is defined by enabling the extension)',
  ExtensionUninstalls:
    'Total number of extensions uninstalled during the selected period (Note: Safari does not report uninstalls or deactivations)',
  DevicesCreated:
    'Approximate count of number of devices created during the selected period (this field is mainly applicable for API clients in lieu of Installs and Uninstalls)',
  OrderCount:
    'Count of the number of distinct order ID and merchant ID values for the given time period. (Note: Commission records may relate 1-to-1 or 1-to-many with Order Count, depending on the merchant)',
};

export const columnDefinitionsFor3WaySplitPartners: Record<string, string> = {
  GrossCommissionAmount: 'Sum of positive partner-split commissions for the period selected, not including user split',
  NetCommissionAmount:
    'Sum of all the partner-split commissions, including negative partner-split commissions for the period selected (Does not include user split)',
};

export const partnersWith3WaySplit = new Set(['260', '144', '271', '97']);
