import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'reduxState/hooks';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import { appGroupDefaultNavTabs, defaultNavigationTabs, NavTab, getRestrictedRoleTabs } from './constants';
import './NavTabs.scss';

interface NavTabsProps {
  appId?: string;
  adminAppId: string;
}

const NavTabs: FC<NavTabsProps> = ({ appId, adminAppId }) => {
  const location = useLocation();
  const theme = useTheme();
  const isInSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const restrictedRole = useAppSelector(selectUserRestrictedRole);

  const restrictedUserRoleTabs = restrictedRole !== null ? getRestrictedRoleTabs(restrictedRole, !!appId) : null;

  const renderTabs = () => {
    const renderTab = (tab: NavTab) => {
      if (appId) {
        return (
          <Link
            key={tab.name}
            to={`/${adminAppId}/app/${appId}/${tab.pathname}`}
            className={`tab ${location.pathname.includes(tab.pathname) ? 'active' : ''}`}
          >
            {tab.name}
          </Link>
        );
      } else {
        return (
          <Link
            key={tab.name}
            to={`/${adminAppId}/${tab.pathname}`}
            className={`tab ${location.pathname.includes(tab.pathname) ? 'active' : ''}`}
          >
            {tab.name}
          </Link>
        );
      }
    };

    if (restrictedRole && restrictedUserRoleTabs) return restrictedUserRoleTabs?.map(renderTab);
    // If appId exists render tabs for single application
    // If not render tabs for app group
    if (appId) {
      return defaultNavigationTabs.map(renderTab);
    } else {
      return appGroupDefaultNavTabs.map(renderTab);
    }
  };

  return !isInSmallScreen ? <div className="tab-list">{renderTabs()}</div> : <></>;
};

export default NavTabs;
