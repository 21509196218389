import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import Error from 'components/Error/Error';
import { findErrorMessage } from 'helpers';
import { useAddConceptsMutation, useGetConceptsQuery, useRemoveConceptsMutation } from 'reduxState/store/concept/api';
import { selectUserEmail } from 'reduxState/store/user/selectors';
import ConceptList from './ConceptList';
import ConfirmationModal from './ConfirmationModal';
import { useAppSelector } from '../../reduxState/hooks';
import { Concept } from '../../reduxState/store/concept/types';

export type selectedConceptsType = Map<Concept['id'], Concept>;

interface ConceptManagerProps {
  adminAppId: string;
  applicationId: string;
}

const ConceptManager = ({ adminAppId, applicationId }: ConceptManagerProps): JSX.Element => {
  const userEmail = useAppSelector(selectUserEmail);

  const [selectedToAdd, setSelectedToAdd] = useState<selectedConceptsType>(new Map());
  const [selectedToRemove, setSelectedToRemove] = useState<selectedConceptsType>(new Map());

  const [error, setError] = useState('');
  const [shouldSyncSiblingApplications, setShouldSyncSiblingApplications] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [addConcepts, { isLoading: addConceptsLoading }] = useAddConceptsMutation();
  const [removeConcepts, { isLoading: removeConceptsLoading }] = useRemoveConceptsMutation();
  const isSaving = addConceptsLoading || removeConceptsLoading;

  const concepts = useGetConceptsQuery({ appId: applicationId });

  const saveConcepts = async () => {
    if (!concepts.data) {
      return;
    }

    let selectedConceptsToAdd = [...selectedToAdd.values()];
    let selectedConceptsToRemove = [...selectedToRemove.values()];

    if (!selectedConceptsToAdd.length && !selectedConceptsToRemove.length) {
      toast.warning('No concepts selected');
      return;
    }

    const {
      data: { isAllowList },
    } = concepts;

    // if isAllowList is false do the opposite
    if (!isAllowList) {
      [selectedConceptsToAdd, selectedConceptsToRemove] = [selectedConceptsToRemove, selectedConceptsToAdd];
    }

    if (selectedConceptsToAdd.length > 0) {
      addConcepts({
        appId: applicationId,
        body: { concepts: selectedConceptsToAdd, author: userEmail, shouldSyncSiblingApplications },
      })
        .unwrap()
        .then(() => toast.success(`${selectedConceptsToAdd.length} concepts ${isAllowList ? 'added' : 'removed'}`))
        .catch(error => toast.error(findErrorMessage(error)));
    }

    if (selectedConceptsToRemove.length > 0) {
      removeConcepts({
        appId: applicationId,
        body: { concepts: selectedConceptsToRemove, author: userEmail, shouldSyncSiblingApplications },
      })
        .unwrap()
        .then(() => toast.success(`${selectedConceptsToRemove.length} concepts ${isAllowList ? 'removed' : 'added'}`))
        .catch(error => toast.error(findErrorMessage(error)));
    }

    setShouldSyncSiblingApplications(false);
  };

  const savingDisabled = isSaving || concepts.isFetching || !!error;

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-row flex-wrap">
        <h1 className="text-muted-dark-purple">Concepts</h1>
        <div className="flex w-full items-center justify-between bg-white p-5 rounded-md">
          {!error && (
            <>
              <div>
                <Button
                  className="hover:!text-white"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/${adminAppId}/app/${applicationId}/bulk-merchant-management`}
                >
                  Bulk add merchants
                </Button>
              </div>
              <div className="flex gap-3 items-center">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={savingDisabled}
                  onClick={() => {
                    if (shouldSyncSiblingApplications) {
                      setIsConfirmModalOpen(true);
                    } else {
                      saveConcepts();
                    }
                  }}
                >
                  Save
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldSyncSiblingApplications}
                      onChange={() => setShouldSyncSiblingApplications(bool => !bool)}
                      disabled={savingDisabled}
                    />
                  }
                  label="Include sibling applications"
                  className="settings-toggle"
                />
              </div>
            </>
          )}
        </div>
      </div>
      {error ? (
        <Error
          retry={() => {
            setError('');
            concepts.refetch();
          }}
        />
      ) : (
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 gap-5 bg-white p-5 rounded-md mt-5">
            <ConceptList
              title="Current"
              isLoading={concepts.isFetching}
              concepts={concepts.data?.concepts.removable ?? []}
              selectedConcepts={selectedToRemove}
              setSelectedConcepts={setSelectedToRemove}
              isSaving={isSaving}
            />
            <ConceptList
              title="Addable"
              isLoading={concepts.isFetching}
              concepts={concepts.data?.concepts.addable ?? []}
              selectedConcepts={selectedToAdd}
              setSelectedConcepts={setSelectedToAdd}
              isSaving={isSaving}
            />
          </div>
        </div>
      )}
      <ConfirmationModal
        title="Confirm Save and Sync"
        content={
          <p>
            Are you sure you want to Save and Sync across all applications? This will apply your changes to{' '}
            <strong>every application</strong> in this app group.
          </p>
        }
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          saveConcepts();
        }}
      />
    </div>
  );
};

export default ConceptManager;
