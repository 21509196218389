import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import {
  SelectedMerchantPerformanceSummary,
  PerformanceSummaryByDate,
  PerformanceSummaryGroupedByMerchant,
} from 'api/performanceSummary/types';
import { PerformanceSummaryColumn, PerformanceSummaryGroupBy } from 'reduxState/store/performanceSummary/types';
import { PerformanceSummaryColumnHeader } from '../PerformanceSummaryColumnHeader/PerformanceSummaryColumnHeader';
import PerformanceSummaryItem from '../PerformanceSummaryItem/PerformanceSummaryItem';
import './PerformanceSummaryTable.scss';

interface PerformanceSummaryTableProps {
  columns: PerformanceSummaryColumn[];
  sortBy: string;
  sortOrder: 'asc' | 'desc' | undefined;
  groupBy: PerformanceSummaryGroupBy;
  sortPerformanceSummary: (queryName: string) => void;
  adminAppId: string;
  performanceSummary:
    | PerformanceSummaryByDate[]
    | PerformanceSummaryGroupedByMerchant[]
    | SelectedMerchantPerformanceSummary[];
  applicationCurrency: string;
  isDataLoading: boolean;
  error: boolean;
}

const PerformanceSummaryTable: FC<React.PropsWithChildren<PerformanceSummaryTableProps>> = ({
  performanceSummary,
  sortBy,
  sortOrder,
  sortPerformanceSummary,
  groupBy,
  adminAppId,
  columns,
  isDataLoading,
  applicationCurrency,
  error,
}) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead className="table-header">
          <TableRow className="table-header-row">
            {columns.map(({ accessor, name, queryName }) => (
              <PerformanceSummaryColumnHeader
                key={accessor}
                accessor={accessor}
                name={name}
                queryName={queryName}
                sortBy={sortBy}
                sortOrder={sortOrder}
                groupBy={groupBy}
                sortPerformanceSummary={sortPerformanceSummary}
                adminAppId={adminAppId}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isDataLoading &&
            Array.isArray(performanceSummary) &&
            performanceSummary.map((summaryItem, index) => {
              return (
                <PerformanceSummaryItem
                  key={`${index}`}
                  summaryItem={summaryItem}
                  columns={columns}
                  currency={applicationCurrency}
                  groupBy={groupBy}
                />
              );
            })}
        </TableBody>
      </Table>
      {isDataLoading && <LinearProgress />}
      {!performanceSummary.length && !isDataLoading && (
        <div className="no-stats">
          {error ? 'Failed to retrieve summary, please try again.' : 'No performance summaries for selected dates.'}
        </div>
      )}
    </TableContainer>
  );
};

export default PerformanceSummaryTable;
