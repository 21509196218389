import { Commission, DisplayCommission } from './types';

export const getDisplayCommissions = (commissions: Commission[] | null): DisplayCommission[] => {
  if (!commissions || !commissions?.length) return [];
  return commissions.map((commission: Commission) => {
    const userCommission = commission.Amounts.find(a => a.SplitPart === 'DEVICE');
    const partnerCommission = commission.Amounts.find(a => a.SplitPart === 'APPLICATION');
    const saleAmountCurrency = commission.SaleAmount.Currency;
    return {
      ...commission,
      CommissionID: commission.CommissionID,
      BaseCommission: {
        amount: partnerCommission?.BaseCommissionAmount || '0',
        currency: partnerCommission?.Currency || '',
      },
      BoostedCommission: {
        amount: partnerCommission?.BoostedCommissionAmount || '0',
        currency: partnerCommission?.Currency || '',
      },
      UserCommission: {
        amount: userCommission?.Amount || '0',
        currency: userCommission?.Currency || '',
      },
      PartnerCommission: {
        amount: partnerCommission?.Amount || '0',
        currency: partnerCommission?.Currency || '',
      },
      SaleAmount: {
        amount: commission.SaleAmount.Amount,
        currency: saleAmountCurrency,
      },
    };
  });
};
