import React, { useEffect, useState, FC } from 'react';
import { Checkbox, MenuItem, ListItemText } from '@mui/material';
import { FilterStatus } from '../../../reduxState/store/commission/types';

export interface FilterStatusMenuProps {
  statusFilters: FilterStatus;
  updateStatusFilters: (status: keyof FilterStatus) => void;
}

export const FilterStatusMenu: FC<React.PropsWithChildren<FilterStatusMenuProps>> = ({
  statusFilters,
  updateStatusFilters,
}) => {
  const [filterList, setFilterList] = useState(Object.keys(statusFilters));

  useEffect(() => {
    setFilterList(Object.keys(statusFilters));
  }, [statusFilters]);

  const toggleStatus = (status: keyof FilterStatus): void => {
    updateStatusFilters(status);
  };

  return (
    <>
      {filterList.map(status => (
        <MenuItem
          dense
          className="status-menu-name"
          key={status}
          onClick={() => toggleStatus(status as keyof FilterStatus)}
        >
          <Checkbox
            edge="start"
            checked={statusFilters[status as keyof FilterStatus]}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': `checkbox-list-label-${status}` }}
          />
          <ListItemText>{status[0] + status.slice(1).toLowerCase()}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
};
