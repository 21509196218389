import React, { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Checkbox,
  Typography,
  Chip,
  Button,
  IconButton,
  Paper,
} from '@mui/material';
import { startCase } from 'lodash';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import { useGetFeedsQuery } from 'reduxState/store/applicationManagement/api';
import FeedActionMenu from './FeedActionMenu';
import { Feed } from '../../../reduxState/store/applicationManagement/types';

export type Props = {
  appId: string;
};

export function FeedTable({ appId }: Props) {
  const { data: feeds, isLoading, isSuccess, error } = useGetFeedsQuery(appId);
  const [selectedFeedIds, setSelectedFeedIds] = useState<number[]>([]);

  const handleSelectFeed = (feed: Feed) => {
    setSelectedFeedIds(current =>
      current.includes(feed.id) ? current.filter(v => v !== feed.id) : [...current, feed.id],
    );
  };

  if (isLoading) {
    return <Loading />;
  } else if (!isSuccess) {
    console.error(error);
    return <Error />;
  } else if (isSuccess && feeds.length === 0) {
    return <Typography>No feeds found</Typography>;
  }

  const handleBulkSelect = () => {
    if (selectedFeedIds.length > 0) {
      setSelectedFeedIds([]);
    } else {
      setSelectedFeedIds(feeds.map(f => f.id));
    }
  };

  const numSelected = selectedFeedIds.length;
  const allAreSelected = numSelected === feeds.length;

  const headerRow = (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={numSelected > 0 && allAreSelected}
          indeterminate={numSelected > 0 && numSelected < feeds.length}
          onChange={handleBulkSelect}
        />
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          Feed
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        <div style={{ visibility: numSelected > 1 ? 'visible' : 'hidden' }}>
          <FeedActionMenu selectedFeeds={feeds.filter(f => selectedFeedIds.includes(f.id))} appId={appId}>
            <Button variant="contained" disableElevation size="small" endIcon={<KeyboardArrowDown />}>
              Actions
            </Button>
          </FeedActionMenu>
        </div>
      </TableCell>
    </TableRow>
  );

  const bodyRows = feeds.map(feed => (
    <TableRow hover key={feed.id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selectedFeedIds.includes(feed.id)} onChange={() => handleSelectFeed(feed)} />
      </TableCell>
      <TableCell>{startCase(feed.kind)}</TableCell>
      <TableCell>
        {feed.disabled && (
          <Chip size="small" variant="outlined" label="disabled" color="error" sx={{ marginRight: '1rem' }} />
        )}
        {feed.isPrioritized && <Chip size="small" variant="outlined" label="prioritized" color="info" />}
      </TableCell>
      <TableCell align="right">
        <div style={{ visibility: numSelected > 1 ? 'hidden' : 'visible' }}>
          <FeedActionMenu selectedFeeds={[feed]} appId={appId}>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </FeedActionMenu>
        </div>
      </TableCell>
    </TableRow>
  ));

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table size="small">
          <TableHead sx={{ backgroundColor: 'primary.light' }}>{headerRow}</TableHead>
          <TableBody>{bodyRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
