import React from 'react';
import { Switch, FormControlLabel, TableCell } from '@mui/material';
import { styled } from '@mui/system';
import { useAppDispatch } from '../../reduxState/hooks';
import { updateFeatureFlag } from '../../reduxState/store/featureFlag/slice';
import { Feature } from '../../reduxState/store/featureFlag/types';

const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
});

export default function FeatureToggle({ name, title, description, enabled }: Feature): JSX.Element {
  const dispatch = useAppDispatch();
  const handleChange = () => {
    dispatch(
      updateFeatureFlag({
        featureFlag: name,
        enabled: !enabled,
      }),
    );
  };
  return (
    <TableCell colSpan={2}>
      <StyledFormControlLabel
        label={title}
        labelPlacement="start"
        control={<Switch color="primary" checked={enabled} onChange={handleChange} name={name} />}
      />
      <p style={{ marginLeft: '10px', fontSize: '13px' }}>{description}</p>
    </TableCell>
  );
}
