import React, { FC } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { generateBarChartOptions } from './helpers/barChartData';
import { generateBarChartData } from './helpers/barChartOptions';
import { DoubleAxisChartData } from '../types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export interface BarGraphProps {
  chartData: DoubleAxisChartData;
  applicationCurrency: string;
}

const BarChart: FC<BarGraphProps> = ({ chartData, applicationCurrency }) => {
  const { y1, y2, labels } = chartData;

  const firstBarChartData = {
    label: y1.label,
    dataPlots: y1.dataPlots,
  };

  const secondBarChartData = {
    label: y2.label,
    dataPlots: y2.dataPlots,
  };

  const firstBarChartOptions = {
    maxValue: y1.maxValue,
    stepSize: y1.stepSize,
  };

  const secondBarChartOptions = {
    maxValue: y2.maxValue,
    stepSize: y2.stepSize,
  };

  const showSecondAxis = y2.dataPlots.length !== 0;
  const barChartData = generateBarChartOptions(labels, firstBarChartData, secondBarChartData, showSecondAxis);
  const barChartOptions = generateBarChartData(
    firstBarChartOptions,
    secondBarChartOptions,
    barChartData,
    labels,
    applicationCurrency,
    showSecondAxis,
  );

  if (!barChartData || !barChartOptions) return <div />;
  return <Bar data={barChartData} options={barChartOptions} />;
};

export default BarChart;
