import React, { FC, useCallback, useState } from 'react';
import { capitalize, startCase, toLower, drop } from 'lodash';
import { toast } from 'react-toastify';
import BoostedOffersFilters from 'components/BoostedOffers/BoostedOffersFilters/BoostedOffersFilters';
import BoostedOffersTable from 'components/BoostedOffers/BoostedOffersTable/BoostedOffersTable';
import { downloadCSV, exportJSONToCSV } from 'helpers';
import { formatUTCDate } from 'helpers/DateHelpers';
import { useGetBoostedOffersQuery, useDeleteBoostedOfferCampaignMutation } from 'reduxState/store/boostedOffers/api';
import { AppOption, BoostedOffer, CampaignsType, BoostedOfferSortBy } from 'reduxState/store/boostedOffers/types';
import { tableHeaders } from '../BoostedOffersTable/constants';

interface CampaignListProps {
  adminAppId: string;
  setCampaignToEdit: (campaign: BoostedOffer | null) => void;
  setIsEditingActiveCampaign: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoostedOffersCampaignList: FC<CampaignListProps> = ({
  adminAppId,
  setCampaignToEdit,
  setIsEditingActiveCampaign,
}) => {
  const [appsToInclude, setAppsToInclude] = useState<AppOption[]>([]);
  const [campaignsType, setCampaignsType] = useState<CampaignsType>('present');
  const [sortBy, setSortBy] = useState<BoostedOfferSortBy>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [campaignsToDelete, setCampaignsToDelete] = useState<BoostedOffer[]>([]);

  const [deleteBoostedOfferCampaign] = useDeleteBoostedOfferCampaignMutation();
  const { data, isFetching, isError } = useGetBoostedOffersQuery({
    sortBy,
    sortOrder,
    adminAppId,
    applicationIds: appsToInclude.map((app: AppOption) => app.id.toString()),
  });

  const sortTable = useCallback(
    (header: BoostedOfferSortBy): void => {
      if (sortBy === header) {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
      } else {
        setSortBy(header);
        setSortOrder('desc');
      }
    },
    [sortBy, sortOrder],
  );

  const toggleCampaignToDelete = (campaign: BoostedOffer, shouldBeDeleted: boolean) => {
    if (shouldBeDeleted) {
      setCampaignsToDelete(Array.from(new Set([...campaignsToDelete, campaign])));
    } else {
      setCampaignsToDelete(campaignsToDelete.filter(campaignToDelete => campaignToDelete.ID !== campaign.ID));
    }
  };

  const exportToCsv = async (): Promise<void> => {
    if (!data?.campaignsData) return;
    const formattedCampaignsData = data.campaignsData[campaignsType].map(campaign => ({
      ...campaign,
      StartDate: formatUTCDate(campaign.StartDate, 'M/dd/yyyy HH:mm zzz'),
      EndDate: formatUTCDate(campaign.EndDate, 'M/dd/yyyy HH:mm zzz'),
      CreatedDate: formatUTCDate(campaign.CreatedDate, 'M/dd/yyyy HH:mm zzz'),
    }));
    const formattedHeaders = tableHeaders.map(({ key, value }) => ({
      accessor: key as keyof BoostedOffer,
      name: value,
    }));

    const csv = exportJSONToCSV(formattedCampaignsData, formattedHeaders);

    if (csv) {
      const formattedAppsIncluded = appsToInclude.length
        ? `_${appsToInclude
            .map(({ label }) => {
              const labelStringArray = startCase(toLower(label)).split(' ');
              return drop(labelStringArray, 1).join('_');
            })
            .join('_')}`
        : '';
      downloadCSV(csv, `${capitalize(campaignsType)}_Boosted_Offers${formattedAppsIncluded}.csv`);
    } else {
      toast.error('There was an error while downloading boosted offer campaigns.');
    }
  };

  const deleteCampaigns = async () => {
    try {
      await Promise.all(campaignsToDelete.map(campaign => deleteBoostedOfferCampaign(campaign.ID)));
      toast.success('Campaigns deleted successfully!');
      setIsDeleteMode(false);
      setCampaignsToDelete([]);
    } catch (err) {
      toast.error(`There was an issue deleting one or more campaigns`);
      console.error(err);
    }
  };

  return (
    <>
      <BoostedOffersFilters
        campaignsType={campaignsType}
        setAppsToInclude={apps => {
          setAppsToInclude(apps);
        }}
        setCampaignsType={setCampaignsType}
        exportToCsv={exportToCsv}
        adminAppId={adminAppId}
        isDeleteMode={isDeleteMode}
        setIsDeleteMode={setIsDeleteMode}
        deleteCampaigns={deleteCampaigns}
      />
      <BoostedOffersTable
        campaignsData={data?.campaignsData || { past: [], present: [], future: [] }}
        campaignsType={campaignsType}
        error={isError ? 'Failed to fetch Boosted Offers.' : ''}
        isLoading={isFetching}
        isDeleteMode={isDeleteMode}
        sortBy={sortBy}
        sortOrder={sortOrder}
        sortTable={sortTable}
        toggleCampaignToDelete={toggleCampaignToDelete}
        setCampaignToEdit={setCampaignToEdit}
        setIsEditingActiveCampaign={setIsEditingActiveCampaign}
      />
    </>
  );
};

export default BoostedOffersCampaignList;
