import React from 'react';
import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { ListItemText, ListItem, Checkbox, Tooltip } from '@mui/material';
import { ListChildComponentProps } from 'react-window';
import { FeaturedMerchantRecord, MerchantType } from '../../../reduxState/store/featuredMerchant/types';
import './Merchant.scss';

export interface MerchantProps extends ListChildComponentProps {
  onSelect: (merchant: MerchantType) => void;
  selected: FeaturedMerchantRecord;
  merchantsToSort: FeaturedMerchantRecord | null;
}

const Merchant = ({ index, style, data, onSelect, selected, merchantsToSort }: MerchantProps): JSX.Element => {
  const merchant: MerchantType = data[index];
  const isChecked = selected.hasOwnProperty(merchant?.ID);
  const isPendingSort = merchantsToSort ? merchantsToSort.hasOwnProperty(merchant?.ID) : false;
  const noMerchantData = merchant.Name.includes('Unavailable');

  return (
    <ListItem
      style={noMerchantData ? { ...style, background: 'pink' } : style}
      className={`featured-merchant-row ${index % 2 ? 'odd-item' : 'even-item'}`}
      key={index}
    >
      <Checkbox checked={isChecked} onChange={(): void => onSelect(merchant)} />
      <ListItemText primary={merchant?.Name} />
      {isPendingSort && (
        <Tooltip title="Save required to complete sort" role="tooltip">
          <NotificationImportant />
        </Tooltip>
      )}
    </ListItem>
  );
};

export default Merchant;
