import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';

export interface TextInputProps {
  label: string;
  handleChange: (value: any) => void;
  value?: string | number | undefined;
  hasError?: boolean;
  errorText?: string;
  helperText?: string;
  required?: boolean;
  placeholderText?: string;
  inputType?: string;
  endAdornment?: React.ReactNode;
  inputProps?: Record<string, string>;
  isDisabled?: boolean;
}

/**
 * Represents a TextField where users can input Text or Numbers.
 *
 * @param props - The properties for TextInput
 * @param props.handleChange - Callback function that will be used when value changes.
 * @param props.value - Value that controls the component.
 * @param props.hasError - Boolean value to display error
 * @param props.errorText - Error message when the TextField has an error.
 * @param props.helperText - Message to give user context of what the TextInput is used for.
 * @param props.placeholderText - Message when TextInput is empty.
 * @param props.inputType - The data type the TextField accepts.
 * @param props.endAdornment - Icon that is shown at the end of the text field. For example, a Percentage symbol.
 * @param props.inputProps - Optional Options for Input from MaterialUI
 * @param props.isDisabled - Boolean value to disable/enable TextInput
 * @param param0
 * @returns
 */

const TextInput: FC<TextInputProps> = ({
  label,
  handleChange,
  value,
  helperText,
  hasError,
  errorText,
  placeholderText,
  inputType,
  inputProps,
  endAdornment,
  isDisabled,
}) => {
  const onChange = (event: any) => {
    handleChange(event.target.value);
  };

  return (
    <div>
      <FormControl error={hasError} sx={{ my: 1, width: `100%` }}>
        <InputLabel shrink={true} htmlFor={label}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={label}
          label={label}
          value={value}
          notched={true}
          onChange={onChange}
          placeholder={placeholderText}
          type={inputType}
          endAdornment={endAdornment}
          inputProps={inputProps}
          role="textbox"
          disabled={isDisabled}
        />
        {hasError && errorText && <FormHelperText>{errorText}</FormHelperText>}
        {helperText && !hasError && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default TextInput;
