import React, { FC, useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { sortBy } from 'lodash';
import { getAuth0UsersByRole, UserWithRoles, removeUserFromApplication } from 'api/user';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';

import UserRow from './UserRow';
import './UsersTable.scss';

interface OwnProps {
  appId: string;
  roleName: string;
}

const UsersTable: FC<React.PropsWithChildren<OwnProps>> = ({ appId, roleName }) => {
  const [users, setUsers] = useState<UserWithRoles[]>([]);
  const [deletingUsersIds, setDeletingUsersIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sortedUsers = sortBy(users, 'email', 'asc');
  const isNoUsers = !sortedUsers.length;

  useEffect(() => {
    if (!roleName) return;

    setIsLoading(true);

    const fetchUsers = async (): Promise<void> => {

      const fetchedUsers = await getAuth0UsersByRole(appId, roleName);
      setIsLoading(false);

      if (!fetchedUsers || !Array.isArray(fetchedUsers)) return setIsError(true);
      setUsers(fetchedUsers);
    };

    fetchUsers();
  }, [appId, roleName]);

  const onRemoveUser = async (user: UserWithRoles) => {
    const isConfirmed = window.confirm(`Are you sure you want to remove all access for ${user!.email!}`);

    if (isConfirmed) {
      setDeletingUsersIds(prevState => [...prevState, user.user_id]);

      // const applicationRoleName = application?.partnerId;

      if (!roleName) {
        return;
      }

      const response = await removeUserFromApplication(appId, user.user_id, roleName);

      if (response && response.ok) {
        setUsers(prevState => prevState.filter(item => item.user_id !== user.user_id));
      } else {
        alert(`Failed to delete a user ${user!.email!}`);
      }

      setDeletingUsersIds(prevState => prevState.filter(item => item !== user.user_id));
    }
  };

  return (
    <TableContainer className="users-table-wrapper">
      {isLoading ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : isError ? (
        <Error />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="users-table-header-cell">Current Users</TableCell>
              <TableCell className="users-table-header-cell">Auth Method</TableCell>
              <TableCell className="users-table-header-cell">Role</TableCell>
            </TableRow>
          </TableHead>
          {!isNoUsers && (
            <TableBody>
              {sortedUsers.map((user, index: number) => {
                const isDeleting = deletingUsersIds.includes(user.user_id);

                return (
                  <UserRow
                    key={user.user_id}
                    appId={appId}
                    user={user}
                    isDeleting={isDeleting}
                    onRemoveUser={onRemoveUser}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      )}

      {isNoUsers && !isError && !isLoading && <div className="no-users">No users found.</div>}
    </TableContainer>
  );
};

export default UsersTable;
