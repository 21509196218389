import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import ApplicationSetting from 'components/Application/ApplicationSetting/ApplicationSetting';
import ApplicationManagement from 'components/ApplicationManagement/ApplicationManagement';
import ConceptManager from 'components/ConceptManager/ConceptManager';
import FeaturedMerchant from 'components/FeaturedMerchant/FeaturedMerchant';
import Loading from 'components/Loading/Loading';
import Merchants from 'components/Merchants/Merchants';
import NotFound from 'components/NotFound/Notfound';
import RedirectToPath from 'components/Route/RedirectToPath';
import { hasWildfirePermissionsSelector, userRolesSelector } from 'helpers/auth0';
import CommissionHistory from 'pages/CommissionHistory/CommissionHistory';
import CommissionHistoryDownload from 'pages/CommissionHistoryDownload/CommissionHistoryDownload';
import PerformanceSummary from 'pages/PerformanceSummary/PerformanceSummary';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { Application as ApplicationType } from 'reduxState/store/user/types';
import { getHighestRestrictedUserRole } from 'reduxState/store/user/utils';
import { getRestrictedPages } from './constants';
import './Application.scss';

const Application = (): JSX.Element | null => {
  const params = useParams();
  const appId = params.appId ?? '';
  const adminAppId = params.adminAppId ?? '';

  const { isLoading, user } = useAuth0();
  const hasWFPermissions = hasWildfirePermissionsSelector(user);

  const { data: applicationsData, isFetching: isFetchingApps } = useGetUserApplicationsQuery();
  const applications: ApplicationType[] = applicationsData?.applications || [];
  const application: ApplicationType =
    applications.find((app: ApplicationType) => app.id === appId) || ({} as ApplicationType);

  if (applications?.length === 0 && isFetchingApps) {
    return <Loading />;
  }

  if (isEmpty(application) && !isLoading && !isFetchingApps) {
    return <NotFound />;
  }

  const userRoles = userRolesSelector(user);
  const highestRestrictedRole = getHighestRestrictedUserRole(userRoles);

  let restrictedPagesSet = new Set<string>([]);

  if (highestRestrictedRole && !hasWFPermissions) {
    restrictedPagesSet = new Set<string>(getRestrictedPages(highestRestrictedRole));
  }

  const restrictedPage = <div>You do not have access to view this page.</div>;

  const appName = application.name ?? '';
  return (
    <div className="application">
      {application ? (
        <Routes>
          <Route
            path="summary"
            element={
              !restrictedPagesSet.has('performance-summary') ? (
                <PerformanceSummary applicationId={appId} appName={appName} adminAppId={adminAppId} />
              ) : (
                restrictedPage
              )
            }
          />
          <Route
            path="settings/*"
            element={
              !restrictedPagesSet.has('settings') ? <ApplicationSetting applicationId={appId} /> : restrictedPage
            }
          />
          <Route
            path="commission-history"
            element={
              !restrictedPagesSet.has('commission-history') ? (
                <CommissionHistory applicationId={appId} appName={appName} adminAppId={adminAppId} />
              ) : (
                restrictedPage
              )
            }
          />
          <Route
            path="commission-history-advanced-search"
            element={
              !restrictedPagesSet.has('commission-history') ? (
                <CommissionHistory
                  applicationId={appId}
                  appName={appName}
                  advancedSearch={true}
                  adminAppId={adminAppId}
                />
              ) : (
                restrictedPage
              )
            }
          />
          <Route
            path="merchants/*"
            element={
              !restrictedPagesSet.has('merchants') ? <Merchants appName={appName} appId={appId} /> : restrictedPage
            }
          />
          <Route
            path="concepts"
            element={
              applications?.length && hasWFPermissions ? (
                <ConceptManager adminAppId={adminAppId} applicationId={appId} />
              ) : (
                restrictedPage
              )
            }
          />
          <Route
            path="commission-download"
            element={<CommissionHistoryDownload appName={appName} applicationId={appId} />}
          />
          <Route
            path="featured-merchant"
            element={applications?.length && hasWFPermissions ? <FeaturedMerchant appId={appId} /> : restrictedPage}
          />
          <Route
            path="application-management"
            element={
              applications?.length && hasWFPermissions ? (
                <ApplicationManagement applicationId={appId} />
              ) : (
                restrictedPage
              )
            }
          />
          <Route path="/apps/*" element={<RedirectToPath path={`/${adminAppId}/app/${appId}/summary`} />} />
        </Routes>
      ) : null}
    </div>
  );
};

export default Application;
