import React, { useEffect, useState } from 'react';
import { MenuItem, ListItemText, Switch } from '@mui/material';

import { AppGroupCommissionColumn, CommissionColumn } from '../../../reduxState/store/commission/types';
import { PerformanceSummaryColumn } from '../../../reduxState/store/performanceSummary/types';
import './ChooseColumnsMenu.scss';

export type ColumnOptions = CommissionColumn | PerformanceSummaryColumn | AppGroupCommissionColumn;

export interface ChooseColumnsMenuProps<T extends ColumnOptions> {
  columns: T[];
  updateColumns: (columns?: T[]) => void;
  callbackVisible?: boolean;
  toggleCallback?: () => void;
  isInAppGroupOverview: boolean;
}

export const ChooseColumnsMenu = <T extends ColumnOptions>({
  columns,
  updateColumns,
  callbackVisible,
  toggleCallback,
  isInAppGroupOverview,
}: ChooseColumnsMenuProps<T>): JSX.Element => {
  const [editedColumns, setEditedColumns] = useState(columns);

  useEffect(() => {
    setEditedColumns(columns);
  }, [columns]);

  const toggleColumn = ({ accessor }: T): void => {
    const nextColumns = editedColumns.map(column => {
      if (column.accessor === accessor) {
        return {
          ...column,
          visible: !column.visible,
        };
      }

      return column;
    });

    setEditedColumns(nextColumns);
    if (nextColumns) updateColumns(nextColumns);
  };

  return (
    <>
      {editedColumns.map(column => (
        <MenuItem
          dense
          className="column-menu-name"
          key={column.accessor}
          onClick={() => toggleColumn(column)}
          disabled={column.nonEditable}
        >
          <ListItemText>{column.name}</ListItemText>
          <Switch className="column-menu-switch" checked={column.visible} disabled={column.nonEditable} />
        </MenuItem>
      ))}
      {!isInAppGroupOverview && (
        <MenuItem dense className="column-menu-name" onClick={toggleCallback}>
          <ListItemText>Resend Callback</ListItemText>
          <Switch className="column-menu-switch" checked={callbackVisible} />
        </MenuItem>
      )}
    </>
  );
};
