import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button, Checkbox, Chip, FormControlLabel, InputLabel } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import Loading from 'components/Loading/Loading';
import { useAppSelector } from 'reduxState/hooks';
import { useGetConceptsQuery, useAddConceptsMutation, useRemoveConceptsMutation } from 'reduxState/store/concept/api';
import { useGetMerchantsQuery } from 'reduxState/store/merchant/api';
import { selectUserEmail } from 'reduxState/store/user/selectors';

const isValidMerchantId = (merchantId: string) => {
  // Checks if the merchantId only consists digits, commas and spaces
  // Allows empty string to be valid
  return /^[\d,\s]*$/.test(merchantId);
};

const BulkMerchantManagement = (): JSX.Element => {
  const userEmail = useAppSelector(selectUserEmail);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [merchantIds, _setMerchantIds] = useState<number[]>([]);
  const setMerchantIds = debounce(_setMerchantIds, 500);

  const [shouldSyncSiblingApplications, setShouldSyncSiblingApplications] = useState(false);
  const [inputError, setInputError] = useState<string | null>(null);

  const { appId } = useParams();

  if (!appId) {
    throw new Error('appId is required');
  }

  const { data: conceptsData } = useGetConceptsQuery({ appId });
  const isAllowList = conceptsData?.isAllowList;

  const { data: merchantsData, isFetching: isFetchingMerchants } = useGetMerchantsQuery(
    {
      appId,
      merchantIds,
    },
    { skip: isEmpty(merchantIds) },
  );

  const [addConcepts, { isLoading: isAddingConceptsLoading }] = useAddConceptsMutation();
  const [removeConcepts, { isLoading: isRemovingConceptsLoading }] = useRemoveConceptsMutation();

  const removeMerchantId = (id: number) => {
    const newIds = merchantIds.filter(merchantId => merchantId !== id);
    _setMerchantIds(newIds);
  };

  const handleChange = (value: string) => {
    setInputError(null);
    const values = value.split(/[,\s]+/).map(v => v.trim());
    if (values.every(isValidMerchantId)) {
      const numberIds = values
        .filter(id => id !== '')
        .map(Number)
        .filter(id => !isNaN(id));
      setMerchantIds(numberIds);
    } else {
      setInputError('Invalid merchant ID. Only include digits, commas, and spaces.');
    }
  };

  const handleSaveMerchantIds = async () => {
    if (isEmpty(merchantIds)) return;

    let promise: typeof addConcepts | typeof removeConcepts;

    if (isAllowList) {
      promise = addConcepts;
    } else {
      promise = removeConcepts;
    }

    try {
      await promise({
        appId,
        body: {
          merchantIds,
          shouldSyncSiblingApplications,
          author: userEmail,
        },
      });
      toast.success('Successfully added concepts');
      _setMerchantIds([]);
      // To clear out the merchant IDs input
      if (textAreaRef.current) {
        textAreaRef.current.value = '';
      }
      setShouldSyncSiblingApplications(false);
    } catch (error) {
      toast.error('Failed to add concepts. Please try again.');
    }
  };

  return (
    <div className="font-montserrat">
      <h1 className="text-muted-dark-purple">Bulk Merchant Management</h1>
      <div className="bg-white rounded-md p-8">
        <InputLabel className="text-sm font-bold mb-3" htmlFor="merchantIds">
          Merchant IDs
        </InputLabel>
        {merchantsData?.merchants && merchantsData?.merchants.length > 0 && merchantIds.length > 0 && (
          <div className="overflow-y-scroll w-full rounded-md mb-3 p-3 border-[1px] border-light-grey2 max-h-[200px]">
            {merchantsData.merchants
              .filter(merchant => merchantIds.includes(merchant.ID))
              .map(merchant => (
                <Chip
                  className="bg-soft-wildfire-purple text-white cursor-default text-[10px] mr-2 mb-2 h-[20px] !px-0"
                  key={merchant.ID}
                  label={`${merchant.ID} - ${merchant.Name}`}
                  onDelete={() => removeMerchantId(merchant.ID)}
                  deleteIcon={
                    <span className="flex items-center justify-center bg-muted-dark-purple rounded-full !text-white h-[15px] w-[15px] !text-sm hover:bg-medium-purple">
                      ×
                    </span>
                  }
                />
              ))}
          </div>
        )}
        <TextareaAutosize
          id="merchantIds"
          ref={textAreaRef}
          className={`w-full border-[1px] border-light-grey2 p-3 rounded-md mb-1 ${!!inputError ? 'border-red' : ''}`}
          placeholder="Enter one or multiple merchant IDs, separated by commas or spaces"
          minRows={4}
          onChange={e => handleChange(e.target.value)}
        />
        {!!inputError && <p className="text-red text-xs mb-3 italic font-bold">{inputError}</p>}
        <FormControlLabel
          className="w-full mb-3"
          control={
            <Checkbox
              className="!border-[1px] !hover:bg-none"
              checked={shouldSyncSiblingApplications}
              onChange={() => setShouldSyncSiblingApplications(bool => !bool)}
              sx={{
                '&:hover': { backgroundColor: 'transparent !important' },
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                },
              }}
            />
          }
          label="Add for all sibling applications"
        />
        <Button
          className="w-[205px] h-[40px]"
          variant="contained"
          color="primary"
          disabled={isEmpty(merchantIds) || !!inputError || isAddingConceptsLoading || isRemovingConceptsLoading}
          onClick={handleSaveMerchantIds}
        >
          {isAddingConceptsLoading || isRemovingConceptsLoading || isFetchingMerchants ? (
            <Loading className="text-white" size={24} />
          ) : (
            'Bulk Add Merchants'
          )}
        </Button>
      </div>
    </div>
  );
};

export default BulkMerchantManagement;
