import React, { FC, useState, MouseEvent } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { ListItemText, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import './DropdownMenu.scss';

/**
 * Represents a Button with a label. When clicked, it will render a Menu with the passed options.
 *
 * @param {object} props - The properties for DropdownMenu.
 * @param {string} props.id - String value to indicate where the menu should anchor to.
 * @param {string} props.label - The label of the Button.
 * @param {Array<{label: string, value: string}>} props.options - The options that the Menu will render when the button is clicked.
 * @param {function} props.handleOnChange - Callback function for when the value is changed.
 * @param {string} [props.className] - String value to override default styling for the component.
 *
 * @example
 * ```tsx
 * <DropdownMenu
 *  id='VIEW_BY_MENU'
 *  label={`View By ${groupBy === 'day' ? 'Day' : 'Month'}`}
 *  options={[
 *    {
 *      label: 'View By Day',
 *      value: 'day'
 *    },
 *    {
 *      label: 'View By Month',
 *      value: 'month'
 *    }
 *  ]}
 *  handleOnChange={handleOnViewChange}
 * />
 * ```
 */

interface DropdownMenuOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface DropdownMenuProps {
  id: string;
  label: string;
  options: DropdownMenuOption[];
  handleOnChange: (value: any) => void;
  className?: string;
}

const DropdownMenu: FC<React.PropsWithChildren<DropdownMenuProps>> = ({
  id,
  label,
  options,
  handleOnChange,
  className,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorElement);

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleOnMenuClick = (value: any) => {
    handleOnChange(value);
    handleClose();
  };

  return (
    <>
      <Button
        className={clsx('dropdown-menu-button', className)}
        id={id}
        aria-controls={id}
        aria-haspopup="true"
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu id={id} anchorEl={anchorElement} keepMounted open={open} onClose={handleClose}>
        {options.map((option: DropdownMenuOption, index: number) => {
          return (
            <MenuItem
              key={index}
              data-testid={`dropdown-option-${index}`}
              disabled={option.disabled}
              onClick={() => handleOnMenuClick(option.value)}
            >
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default DropdownMenu;
