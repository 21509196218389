import React, { FC } from 'react';
import { ParsedMerchantCommissionRate } from '../../../reduxState/store/merchant/types';
import './CommissionRate.scss';

const CommissionRate: FC<React.PropsWithChildren<{ rate: ParsedMerchantCommissionRate }>> = ({ rate }) => {
  return (
    <div className="commission-rate">
      <div className="left">{rate.name}</div>
      <div className="right">{rate.parsedRate}</div>
    </div>
  );
};

export default CommissionRate;
