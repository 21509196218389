import { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { exportJSONToCSV, downloadCSV } from 'helpers';
import { PaymentDetail, PaymentDetailCommission } from '../../../reduxState/store/payment/types';

interface PrintWindowProps {
  onClose: () => void;
  windowTitle: string;
}

export const PRINT_EVENT = 'PRINT_EVENT';
export const CSV_DOWNLOAD_EVENT = 'CSV_DOWNLOAD_EVENT';

const PrintWindow: FC<React.PropsWithChildren<PrintWindowProps>> = ({ children, onClose, windowTitle }) => {
  const [localContainer, setLocalContainer] = useState<HTMLDivElement | null>(null);

  const copyStyles = (src: Document, target: Document): void => {
    Array.from(src.styleSheets).forEach(styleSheet => {
      if (styleSheet.href) {
        const linkElement = src.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = styleSheet.href;
        target.head.appendChild(linkElement);
      } else if (styleSheet.ownerNode) {
        target.head.appendChild(styleSheet.ownerNode.cloneNode(true));
      }
    });
  };

  useEffect(() => {
    const externalWindow = window.open('', '', 'width=1200,height=800');
    const container = document.createElement('div');

    const onPrintEvent = (): void => {
      setTimeout(() => {
        if (externalWindow) {
          externalWindow.print();
        }
      });
    };

    const onCSVDownloadEvent = ({ detail }: CustomEventInit<PaymentDetail>): void => {
      if (externalWindow && detail) {
        const columns = Object.keys(detail.Commissions[0]) as Array<keyof PaymentDetailCommission>;
        const csv = exportJSONToCSV(
          detail.Commissions,
          columns.map(key => ({
            accessor: key,
            // add spaces between words for correct CSV header
            name: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
          })),
        );
        downloadCSV(csv, `${windowTitle}.csv`, externalWindow.document);
      }
    };

    document.body.addEventListener(PRINT_EVENT, onPrintEvent);
    document.body.addEventListener(CSV_DOWNLOAD_EVENT, onCSVDownloadEvent);

    setLocalContainer(container);

    if (externalWindow) {
      copyStyles(window.document, externalWindow.document);
      // container for portal
      externalWindow.document.body.appendChild(container);

      // reset margin inherited from app
      // https://bugzilla.mozilla.org/show_bug.cgi?id=521204 - remove display: flex
      const style = externalWindow.document.createElement('style');
      style.innerHTML = `
      html, body {
        margin: 0;
        display: initial;
      }`;
      externalWindow.document.head.appendChild(style);

      if (windowTitle) {
        externalWindow.document.title = windowTitle;
      }

      externalWindow.addEventListener('beforeunload', () => {
        onClose();
      });
    } else {
      onClose();
    }

    return (): void => {
      document.body.removeEventListener(PRINT_EVENT, onPrintEvent);
      document.body.removeEventListener(CSV_DOWNLOAD_EVENT, onCSVDownloadEvent);

      if (externalWindow) {
        externalWindow.close();
      }
    };
  }, [onClose, windowTitle]);

  if (localContainer) {
    return ReactDOM.createPortal(children, localContainer);
  }

  return null;
};

export default PrintWindow;
