import { ApplicationManagement, GetApplicationManagementArgs, UpdateApplicationManagementArgs, Feed } from './types';
import api from '../api/slice';

const applicationManagementApi = api.injectEndpoints({
  endpoints: builder => ({
    getApplicationManagement: builder.query<ApplicationManagement, GetApplicationManagementArgs>({
      query: ({ appId }) => `application/${appId}/management`,
    }),
    updateApplicationManagement: builder.mutation<{ rowsAffected: number }, UpdateApplicationManagementArgs>({
      query: ({ appId, applicationManagement, author }) => ({
        url: `application/${appId}/management`,
        method: 'PUT',
        body: {
          ...applicationManagement,
          Author: author,
        },
      }),
    }),
    getFeeds: builder.query<Feed[], string>({
      query: appId => `application/${appId}/feed`,
      providesTags: ['feeds'],
      transformResponse: (response: Feed[]) => response.sort((a, b) => a.kind.localeCompare(b.kind)),
    }),
    updateFeeds: builder.mutation<void, { appId: string; feeds: Feed[] }>({
      query: ({ appId, feeds }) => ({
        url: `application/${appId}/feed`,
        method: 'PATCH',
        body: feeds,
      }),
      invalidatesTags: ['feeds'],
    }),
  }),
});

export const {
  useGetApplicationManagementQuery,
  useUpdateApplicationManagementMutation,
  useGetFeedsQuery,
  useUpdateFeedsMutation,
} = applicationManagementApi;
export default applicationManagementApi;
