import React, { FC } from 'react';
import COLORS from 'constants/colors';
import styled from '@emotion/styled';
import { FiberManualRecord } from '@mui/icons-material';

export interface BarChartHeaderProps {
  legendLabels: string[];
  applicationCurrency: string;
}

const BarChartHeader: FC<BarChartHeaderProps> = ({ legendLabels, applicationCurrency }) => {
  const generateLegendLabel = (label: string): string => {
    switch (label) {
      case 'Order Count':
      case 'Merchant Clicks': {
        return label;
      }
      default: {
        return `${label} - ${applicationCurrency}`;
      }
    }
  };

  return (
    <Styled.Container>
      <div>
        <h3>Top 10 Merchants</h3>
      </div>
      <Styled.LegendContainer>
        {legendLabels &&
          legendLabels.map((label: string, index: number) => {
            return (
              <Styled.LabelContainer index={index} key={label}>
                <Styled.FiberManualRecord fontSize="small" index={index} />
                <Styled.LegendLabel>{generateLegendLabel(label)}</Styled.LegendLabel>
              </Styled.LabelContainer>
            );
          })}
      </Styled.LegendContainer>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '95%',
    margin: '20px 5px',
    fontFamily: `'Montserrat', sans-serif`,
  }),
  LegendContainer: styled.div({
    display: 'inherit',
    width: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  LegendLabel: styled.span({
    fontFamily: 'inherit',
    fontSize: '16px',
  }),
  LabelContainer: styled((props: any) => <div {...props} />)(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: !props.index ? '7px' : undefined,
  })),
  FiberManualRecord: styled((props: any) => <FiberManualRecord {...props} />)(props => ({
    color: !props.index ? COLORS.lightPurple : COLORS.graphBlue,
  })),
};

export default BarChartHeader;
