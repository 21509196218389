import { useRef, useState, useEffect } from 'react';

const useHorizontalScroll = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkForScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scroll = (scrollOffset: number) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += scrollOffset;
      // since this is designed to use smooth scrolling, this check must be delayed to allow the scroll to finish
      setTimeout(checkForScroll, 300);
    }
  };

  useEffect(() => {
    checkForScroll();
    window.addEventListener('resize', checkForScroll);

    return () => {
      window.removeEventListener('resize', checkForScroll);
    };
  }, []);

  return { scrollContainerRef, canScrollLeft, canScrollRight, scroll, checkForScroll };
};

export default useHorizontalScroll;
