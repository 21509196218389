import COLORS from 'constants/colors';
import { ScriptableContext, ChartData } from 'chart.js';

export interface yAxisData {
  dataPlots: number[];
  label: string;
}

export const generateMultiAxisChartData = (
  chartLabels: string[],
  y1ChartData: yAxisData,
  y2ChartData: yAxisData,
): ChartData<'line'> => {
  return {
    labels: chartLabels,
    datasets: [
      {
        yAxisID: 'y1',
        label: y1ChartData.label,
        data: y1ChartData.dataPlots,
        borderColor: COLORS.lightPurple,
        pointBorderColor: 'white',
        pointRadius: 5,
        pointBackgroundColor: COLORS.lightPurple,
        fill: true,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, 'rgba(84, 68, 147, .4)'); // top rgba for COLORS.lightPurple #423774
          gradient.addColorStop(1, 'rgba(84, 68, 147, .02)'); // bottom rgba for COLORS.lightPurple #423774
          return gradient;
        },
        tension: 0.35,
      },
      {
        yAxisID: 'y2',
        label: y2ChartData.label,
        data: y2ChartData.dataPlots,
        borderColor: COLORS.graphBlue,
        pointBorderColor: 'white',
        pointRadius: 5,
        pointBackgroundColor: COLORS.graphBlue,
        fill: true,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, 'rgba(23, 135, 191, .4)'); // top rgba for COLORS.graphBlue / #15a3fe
          gradient.addColorStop(1, 'rgba(23, 135, 191, .02)'); // bottom rbga for COLORS.graphBlue #15a3fe
          return gradient;
        },
        tension: 0.35, // Adjust the "curvature" of the lines, less tension = more sharp
      },
    ],
  };
};
