import React from 'react';
import { Button } from '@mui/material';

interface ConfirmationModalProps {
  isOpen: boolean;
  title?: string;
  content: string | React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, content, isOpen, onClose, onConfirm }) => {
  if (!isOpen) return <></>;

  return (
    <div className="fixed inset-0 backdrop-blur-[2px] backdrop-brightness-[50%] flex items-center justify-center z-50 shadow-lg">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg">
        {title && <h2 className="text-xl font-bold mb-4">{title}</h2>}
        <div className="mb-6">{content}</div>
        <div className="flex justify-end space-x-4">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
