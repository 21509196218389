import React, { FC } from 'react';
import { ArrowCircleUpTwoTone, ArrowCircleDownTwoTone } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import { calculatePercentageChange } from 'helpers';

interface StatCardProps {
  title: string;
  currentVal: number;
  previousVal: number;
  type: 'dollar' | 'percentage' | 'count';
  currency?: string;
  durationDescription: string;
}

/**
 * Represents a card displaying a statistical value with a title. It compares
 * the current value with a previous value and indicates whether there has been an increase
 * or decrease. The card can display values in dollar amounts, percentages, or counts.
 *
 *
 * @param props - The properties for the StatCard component.
 * @param props.title - The title of the card.
 * @param props.currentVal - The current value of the statistic.
 * @param props.previousVal - The previous value of the statistic for the previous interval.
 * @param props.type - The format type of the value (dollar, percentage, or count).
 * @param props.durationDescription - Description of the time duration over which
 *     the change occurred.
 *
 * @example
 * ```tsx
 * <StatCard
 *   title="Revenue"
 *   currentVal={1500}
 *   previousVal={1000}
 *   type="dollar"
 *   durationDescription="1 month"
 * />
 * ```
 */

const StatCard: FC<React.PropsWithChildren<StatCardProps>> = ({
  title,
  currentVal,
  previousVal,
  type,
  currency,
  durationDescription,
}) => {
  if ((!currentVal && !previousVal) || !isFinite(currentVal) || !isFinite(previousVal)) return <></>;

  if (durationDescription.startsWith('1 ')) {
    durationDescription = durationDescription.slice(2);
  }

  const isIncrease = currentVal > previousVal;

  return (
    <Card className="stat-card" data-testid={title.split(' ').join('-')}>
      <CardContent>
        <h6 className="uppercase stat-title">{`${title}${type === 'dollar' && currency ? ` (${currency})` : ''}`}</h6>
        <p className="stat-amount">
          {type === 'percentage'
            ? `${parseFloat(currentVal.toFixed(2)).toString()}%`
            : Math.floor(currentVal).toLocaleString()}
        </p>
        <span className="change">
          <span className={isIncrease ? 'green' : 'red'}>
            {isIncrease ? (
              <ArrowCircleUpTwoTone fontSize="small" style={{ verticalAlign: 'bottom' }} />
            ) : (
              <ArrowCircleDownTwoTone fontSize="small" style={{ verticalAlign: 'bottom' }} />
            )}
            {` ${
              type === 'percentage'
                ? parseFloat((currentVal - previousVal).toFixed(2)).toString()
                : calculatePercentageChange(previousVal, currentVal)
            }% `}
          </span>
          {isIncrease ? 'over' : 'under'} previous {durationDescription}
        </span>
      </CardContent>
    </Card>
  );
};

export default StatCard;
