import React, { FC, Key, ReactElement, ReactNode } from 'react';
import { UnfoldMore, ExpandMore } from '@mui/icons-material';
import { TableHead } from '@mui/material';
import clsx from 'clsx';
import { ColumnType } from 'components/Merchants/ActiveDomainsMerchantsTable/ActiveDomainsMerchantsTable';
import TableCell from 'components/TableCell/TableCell';
import TableRow from 'components/TableRow/TableRow';
import TableSortLabel from 'components/TableSortLabel/TableSortLabel';

export interface ActiveDomainsMerchantsTableHeaderProps {
  columns: Array<ColumnType>;
  sortBy: string | null;
  sortOrder: 'asc' | 'desc';
  sortHandler: (accessor: string) => void;
}

const ActiveDomainsMerchantsTableHeader: FC<React.PropsWithChildren<ActiveDomainsMerchantsTableHeaderProps>> = ({
  columns,
  sortHandler,
  sortOrder,
  sortBy,
}) => {
  const renderContent = ({ accessor, name }: ColumnType): ReactElement => {
    const isActive = sortBy === accessor;

    return (
      <TableSortLabel
        active
        direction={isActive ? sortOrder : 'asc'}
        className={clsx('header-table-sort-label active', isActive && 'purple-text')}
        IconComponent={isActive ? ExpandMore : UnfoldMore}
        onClick={(): void => sortHandler(accessor)}
      >
        {name}
      </TableSortLabel>
    );
  };

  const renderCell = (content: ReactNode, key: Key): ReactElement => {
    return (
      <TableCell key={key} className="table-header-cell">
        {content}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow className="table-header-row">
        {columns.map(column => {
          return renderCell(renderContent(column), column.accessor);
        })}
      </TableRow>
    </TableHead>
  );
};

export default ActiveDomainsMerchantsTableHeader;
