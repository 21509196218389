import { MerchantSearchResult } from 'reduxState/store/performanceSummary/types';

export function isMerchantSearchResult(obj: any): obj is MerchantSearchResult {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.ID === 'number' &&
    typeof obj.Type === 'string' &&
    typeof obj.Kind === 'string' &&
    typeof obj.Value === 'string' &&
    typeof obj.Disabled === 'boolean' &&
    typeof obj.Score === 'number'
  );
}

export function isMerchantSearchResultsList(arr: any): arr is MerchantSearchResult[] {
  return Array.isArray(arr) && arr.every(isMerchantSearchResult);
}
