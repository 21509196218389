import { Application, AppGroup, UserApplication } from './types';
import api from '../api/slice';

const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserApplications: builder.query<{ applications: Application[]; appGroups: AppGroup[] }, void>({
      query: () => '/application',
      transformResponse: (response: UserApplication) => {
        const { appGroups, applications } = response;
        const appGroupCopy = [...appGroups];
        const applicationsCopy = [...applications];
        const sortedApplications = applicationsCopy?.sort((a, b) => a.name.localeCompare(b.name));
        const sortedAppGroups = appGroupCopy?.sort((a, b) => a.adminName.localeCompare(b.adminName));

        return {
          appGroups: sortedAppGroups,
          applications: sortedApplications,
        };
      },
    }),
  }),
});

export const { useGetUserApplicationsQuery } = userApi;
export default userApi;
