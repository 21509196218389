import { AppGroupCommissionColumn, CommissionColumn } from './types';

export const initialCommissionColumns: CommissionColumn[] = [
  {
    accessor: 'CommissionID',
    name: 'ID',
    visible: true,
    nonEditable: true,
  },
  {
    accessor: 'CreatedDate',
    name: 'Created Date',
    visible: true,
  },
  {
    accessor: 'EventDate',
    name: 'Event Date',
    visible: true,
  },
  {
    accessor: 'MerchantID',
    name: 'Merchant ID',
    visible: true,
  },
  {
    accessor: 'MerchantName',
    name: 'Merchant Name',
    visible: true,
  },
  {
    accessor: 'SaleAmount',
    name: 'Sale Amount',
    visible: true,
  },
  {
    accessor: 'PartnerCommission',
    name: 'Partner Commission',
    visible: true,
  },
  {
    accessor: 'BaseCommission',
    name: 'Base Commission',
    visible: false,
  },
  {
    accessor: 'BoostedCommission',
    name: 'Boosted Commission',
    visible: false,
  },
  {
    accessor: 'Status',
    name: 'Status',
    visible: true,
  },
  {
    accessor: 'DeviceID',
    name: 'Device ID',
    visible: false,
  },
  {
    accessor: 'DeviceXID',
    name: 'Device XID',
    visible: false,
  },
  {
    accessor: 'MerchantOrderID',
    name: 'Order ID',
    visible: false,
  },
  {
    accessor: 'MerchantSKU',
    name: 'SKU',
    visible: false,
  },
  {
    accessor: 'CouponCodes',
    name: 'Coupon Codes',
    visible: false,
  },
  {
    accessor: 'ShoppingTripCode',
    name: 'Shopping Trip Code',
    visible: false,
  },
  {
    accessor: 'TrackingCode',
    name: 'Tracking Code',
    visible: false,
  },
];

export const initialAppGroupCommissionColumns: AppGroupCommissionColumn[] = [
  {
    accessor: 'CommissionID',
    name: 'ID',
    visible: true,
    nonEditable: true,
  },
  {
    accessor: 'CreatedDate',
    name: 'Created Date',
    visible: true,
  },
  {
    accessor: 'EventDate',
    name: 'Event Date',
    visible: true,
  },
  {
    accessor: 'ApplicationID',
    name: 'Application ID',
    visible: true,
  },
  {
    accessor: 'ApplicationName',
    name: 'Application Name',
    visible: true,
  },
  {
    accessor: 'MerchantID',
    name: 'Merchant ID',
    visible: true,
  },
  {
    accessor: 'MerchantName',
    name: 'Merchant Name',
    visible: true,
  },
  {
    accessor: 'SaleAmount',
    name: 'Sale Amount',
    visible: true,
  },
  {
    accessor: 'PartnerCommission',
    name: 'Partner Commission',
    visible: true,
  },
  {
    accessor: 'Status',
    name: 'Status',
    visible: true,
  },
  {
    accessor: 'DeviceID',
    name: 'Device ID',
    visible: false,
  },
  {
    accessor: 'MerchantOrderID',
    name: 'Order ID',
    visible: false,
  },
  {
    accessor: 'TrackingCode',
    name: 'Tracking Code',
    visible: false,
  },
];
