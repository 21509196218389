import { createSelector } from '@reduxjs/toolkit';
import { UserState } from './types';
import { RootState } from '../../';

const userStateSelector = (state: RootState): UserState => state.user;

export const selectUserToken = createSelector(userStateSelector, (userState: UserState) => userState.accessToken || '');
export const selectUserEmail = createSelector(userStateSelector, (userState: UserState) => userState.email);
export const selectUserRestrictedRole = createSelector(
  userStateSelector,
  (userState: UserState) => userState.restrictedRole || null,
);
