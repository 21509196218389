import React, { FC, useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Popover, Typography } from '@mui/material';
import { FeaturedMerchantRecord } from '../../../reduxState/store/featuredMerchant/types';
import { FeaturedMerchantSortType, MerchantType } from '../../../reduxState/store/featuredMerchant/types';
import downArrow from '../../../static/images/downArrow.png';
import upArrow from '../../../static/images/upArrow.png';
import './FeaturedMerchantButtons.scss';

export interface SortButtonsProps {
  onSort: (merchant: MerchantType, sortType: FeaturedMerchantSortType) => void;
  selected: FeaturedMerchantRecord;
  isLoading: boolean;
}

const SortButtons: FC<React.PropsWithChildren<SortButtonsProps>> = ({ onSort, selected, isLoading }) => {
  const [hasError, setError] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  useEffect(() => {
    if (hasError) setTimeout(() => handleClose(), 2000);
  }, [hasError]);

  const runSort = (event: React.MouseEvent, sortType: FeaturedMerchantSortType): void => {
    setAnchorEl(event?.currentTarget);
    if (Object.keys(selected).length !== 1) {
      setAnchorEl(event?.currentTarget);
      return setError(true);
    }
    const [firstValue] = Object.values(selected);
    onSort(firstValue, sortType);
  };

  const handleClose = () => {
    setError(false);
    setAnchorEl(null);
  };

  return (
    <div className="sort-buttons">
      {hasError && (
        <>
          <Popover
            id={'sort-error-popover'}
            open={hasError}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div
              className="sort-error-container"
              style={{ display: 'flex', alignItems: 'center', padding: '.25rem .5rem', fontSize: '.75rem' }}
            >
              <ErrorIcon id="sort-error" data-testid="error-icon" />
              <Typography style={{ paddingLeft: '.25rem' }}>Select only one merchant to sort</Typography>
            </div>
          </Popover>
        </>
      )}
      <Button disabled={isLoading || Object.keys(selected).length === 0} onClick={event => runSort(event, 'up')}>
        <img className="up-arrow" src={upArrow} alt="up arrow button" />
      </Button>
      <Button disabled={isLoading || Object.keys(selected).length === 0} onClick={event => runSort(event, 'down')}>
        <img className="down-arrow" src={downArrow} alt="down arrow button" />
      </Button>
    </div>
  );
};

export default SortButtons;
