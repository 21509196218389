import { searchAndSort } from 'helpers';
import { DATA_URLS } from 'helpers/constants';
import {
  FeaturedMerchant,
  FeaturedMerchantCategories,
  GetFeaturedMerchantQueryArgs,
  GetMerchantsForFeaturedMerchantQueryArgs,
  MerchantType,
  SaveAppFeaturedMerchantEditArgs,
  SaveAppSortOrderArgs,
} from './types';
import api from '../api/slice';

const featuredMerchantApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeaturedMerchant: builder.query<FeaturedMerchant, GetFeaturedMerchantQueryArgs>({
      query: ({ appId }) => {
        return {
          url: `featured-merchant/${appId}/featured`,
        };
      },
      providesTags: ['featuredMerchant'],
      transformResponse: (
        response: { 'Application ID': string; 'Featured Merchants': FeaturedMerchantCategories },
        _,
        args,
      ) => {
        const PriorityIDs: number[] = response['Featured Merchants'].Priority || [];
        const GenericIDs: number[] = response['Featured Merchants'].Generic || [];

        const merchantMap: { [key: number]: MerchantType } = {};
        const merchants = args.merchants || [];

        merchants.forEach(merchant => {
          merchantMap[merchant.ID] = merchant;
        });

        const Priority = PriorityIDs
          ? PriorityIDs.map(id => {
            if (merchantMap[id]) return merchantMap[id];
            return { ID: id, Name: `${id} - Unavailable` };
          })
          : [];

        const Generic = GenericIDs
          ? GenericIDs.map(id => {
            if (merchantMap[id]) return merchantMap[id];
            return { ID: id, Name: `${id} - Unavailable` };
          })
          : [];

        return {
          Priority,
          Generic,
        };
      },
    }),
    getMerchantsForFeaturedMerchant: builder.query<MerchantType[], GetMerchantsForFeaturedMerchantQueryArgs>({
      queryFn: async arg => {
        try {
          const response = await fetch(DATA_URLS.CLOUD_DATA_BASE_URI + `/${arg.appId}/merchant/1`);
          const data = (await response.json()) as MerchantType[];
          const result = searchAndSort(data, arg.searchValue, null, 'asc') || [];

          return { data: result };
        } catch (error) {
          console.log(error);
          return { error };
        }
      },
    }),
    saveAppFeaturedMerchantEdit: builder.mutation<{ Success: number }, SaveAppFeaturedMerchantEditArgs>({
      query: args => {
        return {
          url: `featured-merchant/${args.appId}/featured/update`,
          method: 'PUT',
          body: args.body,
        };
      },
      invalidatesTags: ['featuredMerchant'],
    }),
    createAppFeaturedMerchant: builder.mutation<void, SaveAppFeaturedMerchantEditArgs>({
      query: args => {
        return {
          url: `featured-merchant/${args.appId}/featured/create`,
          method: 'POST',
          body: args.body,
        };
      },
      invalidatesTags: ['featuredMerchant'],
    }),

    saveAppSortOrder: builder.mutation<{ Success: number }, SaveAppSortOrderArgs>({
      query: args => {
        return {
          url: `featured-merchant/${args.appId}/featured/update/all`,
          method: 'PUT',
          body: args.body,
        };
      },
      invalidatesTags: ['featuredMerchant'],
    }),
  }),
});

export const {
  useGetFeaturedMerchantQuery,
  useGetMerchantsForFeaturedMerchantQuery,
  useSaveAppFeaturedMerchantEditMutation,
  useCreateAppFeaturedMerchantMutation,
  useSaveAppSortOrderMutation,
} = featuredMerchantApi;
export default featuredMerchantApi;
