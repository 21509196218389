import COLORS from 'constants/colors';
import { ChartData } from 'chart.js';

export interface BarChartData {
  label: string;
  dataPlots: number[];
}

export const generateBarChartOptions = (
  chartLabels: string[],
  firstBarData: BarChartData,
  secondBarData: BarChartData,
  showSecondAxis: boolean,
): ChartData<'bar'> => {
  // There should always be atleast 1 dataset
  const datasets = [
    {
      yAxisID: showSecondAxis ? 'y1' : 'y',
      label: firstBarData.label,
      data: firstBarData.dataPlots,
      borderColor: COLORS.lightPurple,
      backgroundColor: COLORS.lightPurple,
      borderWidth: 1,
    },
  ];

  if (showSecondAxis) {
    datasets.push({
      yAxisID: 'y2',
      label: secondBarData.label,
      data: secondBarData.dataPlots,
      borderColor: COLORS.graphBlue,
      backgroundColor: COLORS.graphBlue,
      borderWidth: 1,
    });
  }
  return {
    labels: chartLabels,
    datasets,
  };
};
