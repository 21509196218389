import React, { FC } from 'react';
import { ExpandMore, UnfoldMore } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { columnDefinitions, columnDefinitionsFor3WaySplitPartners, partnersWith3WaySplit } from './constants';
import TableCell from '../../TableCell/TableCell';
import TableSortLabel from '../../TableSortLabel/TableSortLabel';

interface PerformanceSummaryColumnHeaderProps {
  accessor: string | number;
  name: string;
  sortBy: string;
  sortOrder: 'desc' | 'asc' | undefined;
  groupBy: string;
  queryName: string;
  adminAppId: string;
  sortPerformanceSummary: (queryName: string) => void;
}

export const PerformanceSummaryColumnHeader: FC<React.PropsWithChildren<PerformanceSummaryColumnHeaderProps>> = ({
  accessor,
  name,
  sortBy,
  sortOrder,
  groupBy,
  queryName,
  adminAppId,
  sortPerformanceSummary,
}) => {
  const isActive = sortBy === queryName;
  switch (accessor) {
    case 'ReportDate': {
      return (
        <TableCell key={accessor} className="table-header-cell">
          <span>{groupBy === 'month' ? 'Month' : 'Day'}</span>
        </TableCell>
      );
    }
    case 'ApplicationName':
    case 'MerchantName':
    case 'MerchantID': {
      return (
        <TableCell key={accessor} className="table-header-cell">
          <span>{name}</span>
        </TableCell>
      );
    }
    default: {
      if (accessor in columnDefinitions) {
        let tooltipMessage = columnDefinitions[accessor];

        // Override message if its a 3-Way partner
        if (partnersWith3WaySplit.has(adminAppId) && accessor in columnDefinitionsFor3WaySplitPartners) {
          tooltipMessage = columnDefinitionsFor3WaySplitPartners[accessor];
        }

        return (
          <TableCell key={accessor} className="table-header-cell">
            <TableSortLabel
              active
              direction={isActive ? sortOrder : 'asc'}
              className={clsx('header-table-sort-label active', isActive && 'purple-text')}
              IconComponent={isActive ? ExpandMore : UnfoldMore}
              onClick={(): void => sortPerformanceSummary(queryName)}
            >
              <Tooltip
                title={<p style={{ fontSize: '16px' }}>{tooltipMessage}</p>}
                placement="top"
                arrow
                enterDelay={500}
              >
                <div>{name}</div>
              </Tooltip>
            </TableSortLabel>
          </TableCell>
        );
      } else {
        return (
          <TableCell key={accessor} className="table-header-cell">
            <TableSortLabel
              active
              direction={isActive ? sortOrder : 'asc'}
              className={clsx('header-table-sort-label active', isActive && 'purple-text')}
              IconComponent={isActive ? ExpandMore : UnfoldMore}
              onClick={(): void => sortPerformanceSummary(queryName)}
            >
              {name}
            </TableSortLabel>
          </TableCell>
        );
      }
    }
  }
};
