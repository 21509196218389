import { User } from '@auth0/auth0-react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reduxState';
import { UserState } from './types';

const getInitialUserState = (): UserState => {
  return {
    accessToken: '',
    user: {},
    email: '',
    applications: [],
    appGroups: [],
    isLoading: false,
    error: null,
    restrictedRole: null,
  };
};

export const initialUserState = getInitialUserState();

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User; token: string; email: string }>) => {
      state.accessToken = action.payload.token;
      state.user = action.payload.user;
      state.email = action.payload.email;
    },
    setUserRestrictedRole: (state, action: PayloadAction<{ restrictedRole: string }>) => {
      state.restrictedRole = action.payload.restrictedRole;
    },
  },
});

export const userReducer = userSlice.reducer;

export const selectUserEmail = (state: RootState) => state.user.user.email ?? 'missing user email';
export const selectUserToken = (state: RootState) => state.user.accessToken ?? '';
export const { setUser, setUserRestrictedRole } = userSlice.actions;
