import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagState } from './types';
import { RootState } from '../../';

export const selectFeatureFlagState = (state: RootState): FeatureFlagState => state.featureFlag;

export const featureFlagSelector = createSelector(
  selectFeatureFlagState,
  (_: RootState, name: string) => name,
  (featureFlagState, name) => featureFlagState.features[name] || {},
);

export const allFeatureFlagsSelector = createSelector(
  selectFeatureFlagState,
  featureFlagState => featureFlagState.features || {},
);
