import { parseMerchantCommissionRates } from 'helpers';
import { DATA_URLS } from 'helpers/constants';
import {
  ActiveDomain,
  GetActiveDomainArgs,
  GetMerchantCommissionRatesArgs,
  GetMerchantsArgs,
  GetMerchantsResponse,
  Merchant,
  MerchantCommissionRate,
  ParsedMerchantCommissionRate,
} from './types';
import api from '../api/slice';

const merchantApi = api.injectEndpoints({
  endpoints: builder => ({
    getMerchants: builder.query<{ merchants: Merchant[] }, GetMerchantsArgs>({
      query: ({ appId, merchantIds }) => {
        return {
          url: `merchant/${appId}?id=${merchantIds.join('&id=')}`,
        };
      },
      transformResponse: (response: GetMerchantsResponse) => ({ merchants: response.Merchants || [] }),
    }),
    getMerchantCommissionRates: builder.query<
      {
        merchantCommissionRates: MerchantCommissionRate[];
        parsedMerchantCommissionRates: ParsedMerchantCommissionRate[];
      },
      GetMerchantCommissionRatesArgs
    >({
      query: ({ appId, merchantId }) => {
        return {
          url: `merchant/${appId}/${merchantId}/commission`,
        };
      },
      transformResponse: (response: MerchantCommissionRate[]) => ({
        merchantCommissionRates: response || [],
        parsedMerchantCommissionRates: parseMerchantCommissionRates(response) || [],
      }),
    }),
    getActiveDomains: builder.query<ActiveDomain[], GetActiveDomainArgs>({
      queryFn: async arg => {
        try {
          const response = await fetch(DATA_URLS.CLOUD_DATA_BASE_URI + `/${arg.appId}/active-domain/1`);
          const data = (await response.json()) as ActiveDomain[];
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetMerchantsQuery,
  useLazyGetMerchantsQuery,
  useGetMerchantCommissionRatesQuery,
  useLazyGetMerchantCommissionRatesQuery,
  useGetActiveDomainsQuery,
  useLazyGetActiveDomainsQuery,
} = merchantApi;
export default merchantApi;
