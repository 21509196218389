import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { orderBy } from 'lodash';
import { PerformanceSummaryByDate, PerformanceSummaryGroupedByMerchant } from 'api/performanceSummary/types';
import { BoostedOffer } from 'reduxState/store/boostedOffers/types';
import { PerformanceSummaryReportSumByApp, PerformanceSummaryReport } from 'reduxState/store/performanceSummary/utils';
import {
  AppGroupCsvCommissionWithMerchantName,
  CommissionWithMerchantName,
  CsvCommissionColumn,
  CsvCommissionWithMerchantName,
} from '../reduxState/store/commission/types';
import {
  ActiveDomainMerchant,
  MerchantCommissionRate,
  ParsedMerchantCommissionRate,
} from '../reduxState/store/merchant/types';
import { ConvertedActiveDomainMerchantWithActiveDomainInfo } from '../reduxState/store/merchant/types';
import { PaymentDetailCommission } from '../reduxState/store/payment/types';
import { MerchantPerformanceSummary } from '../reduxState/store/performanceSummary/types';
import { Application } from '../reduxState/store/user/types';

export const parseCommissionRate = (
  commissionRate: ActiveDomainMerchant['MaxRate'] | ActiveDomainMerchant['DefaultRate'] | null,
): string => {
  if (!commissionRate) {
    return 'N/A';
  }

  const amount = parseFloat(commissionRate.Amount);

  if (isNaN(amount)) {
    return '';
  }

  if (commissionRate.Kind === 'PERCENTAGE') {
    return `${amount.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })}%`;
  }

  if (commissionRate.Kind === 'FLAT') {
    return amount.toLocaleString(undefined, {
      style: 'currency',
      currency: commissionRate.Currency,
    });
  }

  return '';
};

export const parseAmount = (amount: string | number): string => {
  const amountNum = parseFloat(String(amount));
  const negative = amountNum < 0;
  const absoluteAmount = Math.abs(amountNum);
  const displayAmount = absoluteAmount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (negative) {
    return `($${displayAmount})`;
  }

  return `$${displayAmount}`;
};

export const parseCurrencyAmount = (amount: string | number, currency: string, localeString?: string): string => {
  // added localeString for testing
  const amountNumber = parseFloat(String(amount));
  const negative = amountNumber < 0;
  const absoluteAmount = Math.abs(amountNumber);
  const currencyType = currency || 'USD';
  const displayAmount = absoluteAmount.toLocaleString(localeString || undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return negative ? `(${displayAmount}) ${currencyType}` : `${displayAmount} ${currencyType}`;
};

export const isElementInViewport = (element: HTMLElement | null): boolean | undefined => {
  if (element) {
    const rect = element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

export const normalizeString = (str: string, toLowerCase = true): string => {
  // https://stackoverflow.com/a/37511463
  const normalizedString = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return toLowerCase ? normalizedString.toLowerCase() : normalizedString;
};

export const moveItemInArray = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const clamp = (value: number, max: number): number => {
    return Math.max(0, Math.min(max, value));
  };

  const from = clamp(fromIndex, array.length - 1);
  const to = clamp(toIndex, array.length - 1);

  if (from === to) {
    return array;
  }

  const target = array[from];
  const delta = to < from ? -1 : 1;
  const updatedArray = [...array];

  for (let i = from; i !== to; i += delta) {
    updatedArray[i] = updatedArray[i + delta];
  }

  updatedArray[to] = target;

  return updatedArray;
};

export const downloadCSV = (csv: string, filename: string, activeDocument: Document = document): void => {
  // CSV file
  const csvFile = new Blob([csv], { type: 'text/csv' });

  // Download link
  const downloadLink = activeDocument.createElement('a');

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = 'none';

  // Add the link to DOM
  activeDocument.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();
};

export interface Columns<T> {
  accessor: keyof T;
  name: string;
}

export const exportJSONToCSV = <
  T extends
    | AppGroupCsvCommissionWithMerchantName
    | ConvertedActiveDomainMerchantWithActiveDomainInfo
    | CommissionWithMerchantName
    | CsvCommissionWithMerchantName
    | CsvCommissionColumn
    | PaymentDetailCommission
    | PerformanceSummaryByDate
    | PerformanceSummaryGroupedByMerchant
    | MerchantPerformanceSummary
    | BoostedOffer
    | PerformanceSummaryReport
    | PerformanceSummaryReportSumByApp
>(
  json: T[],
  columns: Columns<T>[],
): string => {
  const replacer = (key: string, value: string | null): string => (value === null ? '' : value); // specify how you want to handle null values here

  const header = columns.map(column => column.name);

  const csv = json.map(row => columns.map(column => JSON.stringify(row[column.accessor], replacer)).join(','));
  csv.unshift(header.join(','));

  return csv.join('\r\n');
};

// TODO: find a way to let TypeScript know that the argument's array will contain objects without using the `object` type
// eslint-disable-next-line @typescript-eslint/ban-types
export const searchAndSort = <T extends object>(
  arrayOfValues: Array<T>,
  searchValue: string,
  sortBy: string | number | null,
  sortOrder: 'asc' | 'desc',
): Array<T> => {
  let result = [...arrayOfValues];

  if (searchValue) {
    result = result.filter(objectWithValues =>
      Object.values(objectWithValues).some(value => {
        if (!value) return false;

        const resultValue = value
          .toString()
          .toLowerCase()
          .trim()
          .includes(
            searchValue
              .toString()
              .toLowerCase()
              .trim(),
          );

        return resultValue;
      }),
    );
  }

  if (sortBy) {
    result = orderBy(result, [sortBy], sortOrder);
  }

  return result;
};

export const sortMerchantCommissionRates = (commissionRates: MerchantCommissionRate[]): MerchantCommissionRate[] => {
  const percentages = commissionRates.filter(rate => rate.Kind === 'PERCENTAGE');
  const flats = commissionRates.filter(rate => rate.Kind === 'FLAT');
  const sorter = (a: MerchantCommissionRate, b: MerchantCommissionRate): number =>
    parseFloat(b.Amount) - parseFloat(a.Amount);
  return [...percentages.sort(sorter), ...flats.sort(sorter)];
};

export const parseMerchantCommissionRates = (
  commissionRates: MerchantCommissionRate[],
): ParsedMerchantCommissionRate[] => {
  return sortMerchantCommissionRates(commissionRates).map(rate => {
    const name = rate.DisplayName === '' ? rate.Name : rate.DisplayName;
    let parsedRate;
    if (rate.Kind === 'PERCENTAGE') {
      const defaultPercentageRate = parseFloat(rate.Amount).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      });
      parsedRate = `${defaultPercentageRate}%`;
    } else if (rate.Kind === 'FLAT') {
      const defaultFlatRate = parseFloat(rate.Amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: rate.Currency,
      });
      parsedRate = `${defaultFlatRate}`;
    }
    return {
      name,
      parsedRate,
      isDefault: rate.IsDefault,
      kind: rate.Kind,
    };
  });
};

export const timeConversion = (duration: number): string => {
  const portions: string[] = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(duration / msInHour);
  if (hours > 0) {
    portions.push(hours + 'h');
    duration = duration - hours * msInHour;
  }

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(duration / msInMinute);
  if (minutes > 0) {
    portions.push(minutes + 'm');
    duration = duration - minutes * msInMinute;
  }

  const seconds = Math.trunc(duration / 1000);
  if (seconds > 0) {
    portions.push(seconds + 's');
  }

  return portions.join(' ');
};

/** Calculates the percentage change between two values and returns a string representing that percentage.
 *
 *  Optionally accepts a third parameter representing the number of decimal places to round the percentage to. (default: 2)
 * @param originalValue - number
 * @param newValue - number
 * @param decimalPlaces - number (*optional* | default: 2)
 */
export function calculatePercentageChange(originalValue: number, newValue: number, decimalPlaces = 2): string {
  const difference = newValue - originalValue;
  let percentageChange = (difference / originalValue) * 100;

  // if the percentage change is Infinity, it means the original value was 0, which means there was a change of 100%
  if (percentageChange === Infinity) {
    percentageChange = 100;
  } else if (percentageChange === -Infinity) {
    percentageChange = -100;
  }

  return parseFloat(percentageChange.toFixed(decimalPlaces)).toString(); // round to the allotted amount of decimal places, and remove any trailing zeroes
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function findErrorMessage(err: any): string {
  if (!err) return 'An empty response was received.';
  const errorMessages: string[] = [];

  function searchErrorMessages(obj: any) {
    if (typeof obj === 'object') {
      for (const key in obj) {
        const value = obj[key];
        if (
          typeof value === 'string' &&
          (key.toLowerCase().includes('error') || key.toLowerCase().includes('message'))
        ) {
          errorMessages.push(value);
        } else if (typeof value === 'object') {
          searchErrorMessages(value);
        }
      }
    }
  }

  if (typeof err === 'string') {
    try {
      err = JSON.parse(err);
    } catch {
      // Return the string directly if it's not JSON
      return 'An error has occurred: ' + err;
    }
  }
  searchErrorMessages(err);

  if (errorMessages.length > 0) {
    // Return the longest error message
    return errorMessages.reduce((longest, current) => (current.length > longest.length ? current : longest), '');
  } else {
    // Return the default message with JSON stringified original object
    return 'An error has occurred: ' + JSON.stringify(err);
  }
}

export const findRtkErrorMessage = (error: FetchBaseQueryError | SerializedError): string => {
  if ('data' in error) {
    const data = error.data as { ErrorMessage: string };
    return data.ErrorMessage;
  } else {
    return (error as { message: string }).message;
  }
};

// Finds application name from a list of applications with a string application ID
export const findAppNameFromId = (applications: Application[], ID: string): string => {
  const foundApplication = applications?.find((app: Application) => app.id === ID);
  if (foundApplication) {
    return foundApplication.name;
  }
  return ID;
};

export const parseVersion = (version: string): string => {
  if (version === 'APIVersion(0)') return '';
  return version;
};
