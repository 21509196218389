import { RestrictedUserRoles } from './constants';

export const getHighestRestrictedUserRole = (roles: string[]): string | null => {
  const rolesSet = new Set(roles);
  // Entity 74 Administrator
  if (rolesSet.has(RestrictedUserRoles.ADMIN)) return RestrictedUserRoles.ADMIN;
  // Entity 74 Business Team
  if (rolesSet.has(RestrictedUserRoles.BUSINESS)) return RestrictedUserRoles.BUSINESS;
  // Entity 74 Finance
  if (rolesSet.has(RestrictedUserRoles.FINANCE)) return RestrictedUserRoles.FINANCE;
  // Entity 74 Operations
  if (rolesSet.has(RestrictedUserRoles.OPERATIONS)) return RestrictedUserRoles.OPERATIONS;

  return null;
};
