/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AUTH0_NAMESPACE } from './constants';

const hasWildfirePermissionsSelector = (user: any): boolean =>
  user?.[`${AUTH0_NAMESPACE}/roles`]?.includes('wfPermissions');

const isSuperAdminSelector = (user: any): boolean => user?.[`${AUTH0_NAMESPACE}/roles`]?.includes('wf_superadmin');
const userRolesSelector = (user: any): string[] => user?.[`${AUTH0_NAMESPACE}/roles`];

const USERS_IDENTITIES_MAP: {
  [key: string]: string;
} = {
  'Username-Password-Authentication': 'Email',
  'google-oauth2': 'Google / Gmail',
};

export { hasWildfirePermissionsSelector, isSuperAdminSelector, userRolesSelector, USERS_IDENTITIES_MAP };
