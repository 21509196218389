import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import { chartOptions } from 'components/PerformanceSummary/PerformanceSummaryChart/constants';
import {
  initialSelectedMerchantColumns,
  initialGroupByMerchantColumns,
  initialAPIColumns,
  initialNonAPIColumns,
} from './constants';
import {
  PerformanceSummaryState,
  PerformanceSummaryVisibleColumns,
  SetStateSummaryColumnsPayload,
  SetFilterValuesPayload,
} from './types';
import { ChartHeaderOption } from './types';

const initializePerformanceSummaryColumns = (): PerformanceSummaryVisibleColumns => {
  const localStoragePerformanceSummaryColumns = localStorage.getItem('performance_summary_columns_dictionary');
  const initialPerformanceSummaryColumns: PerformanceSummaryVisibleColumns = {
    apiDateColumns: initialAPIColumns,
    nonAPIDateColumns: initialNonAPIColumns,
    groupByMerchant: initialGroupByMerchantColumns,
    selectedMerchant: initialSelectedMerchantColumns,
  };

  // Return Default Visible Columns
  if (!localStoragePerformanceSummaryColumns) {
    return initialPerformanceSummaryColumns;
  }

  const savedPerformanceSummaryColumns: PerformanceSummaryVisibleColumns = JSON.parse(
    localStoragePerformanceSummaryColumns,
  );

  for (const key in initialPerformanceSummaryColumns) {
    if (!savedPerformanceSummaryColumns[key]) {
      // sets Default columns if it doesn't exist
      savedPerformanceSummaryColumns[key] = initialPerformanceSummaryColumns[key];
    } else if (savedPerformanceSummaryColumns[key]?.length !== initialPerformanceSummaryColumns[key]?.length) {
      // check if there are different numbers of columns between saved columns and default columns. if so, use default column order
      return initialPerformanceSummaryColumns;
    }
  }

  return savedPerformanceSummaryColumns;
};

export const initialPerformanceSummaryState: PerformanceSummaryState = {
  columns: initializePerformanceSummaryColumns(),
  filterValues: {
    startDate: subMonths(new Date(), 1).toString(),
    endDate: new Date().toString(),
    sortBy: '',
    sortOrder: 'asc',
    groupBy: 'day',
  },
  didUserChangeDates: false,
  activeLineChartOption: chartOptions[0],
  isLoading: false,
  error: null,
};

const performanceSummarySlice = createSlice({
  name: 'performanceSummary',
  initialState: initialPerformanceSummaryState,
  reducers: {
    setPerformanceSummaryColumns(state: PerformanceSummaryState, action: PayloadAction<SetStateSummaryColumnsPayload>) {
      const { columns, groupBy } = action.payload;

      switch (groupBy) {
        case 'apiDateColumns': {
          state.columns.apiDateColumns = columns;
          break;
        }
        case 'nonAPIDateColumns': {
          state.columns.nonAPIDateColumns = columns;
          break;
        }
        case 'groupByMerchant': {
          state.columns.groupByMerchant = columns;
          break;
        }
        case 'selectedMerchant': {
          state.columns.selectedMerchant = columns;
          break;
        }
      }

      localStorage.setItem('performance_summary_columns_dictionary', JSON.stringify(state.columns));
    },
    setFilterValues(state: PerformanceSummaryState, action: PayloadAction<SetFilterValuesPayload>) {
      state.filterValues = {
        ...state.filterValues,
        ...action.payload,
      };
    },
    setActiveLineChartOption(state: PerformanceSummaryState, action: PayloadAction<ChartHeaderOption>) {
      state.activeLineChartOption = action.payload;
    },
    // should only be called when user changes startDate/endDate
    setPerformanceSummaryDidUserChangeDates(state: PerformanceSummaryState, action: PayloadAction<boolean>) {
      state.didUserChangeDates = action.payload;
    },
  },
});

export const {
  setPerformanceSummaryColumns,
  setFilterValues,
  setActiveLineChartOption,
  setPerformanceSummaryDidUserChangeDates,
} = performanceSummarySlice.actions;

export const performanceSummaryReducer = performanceSummarySlice.reducer;
