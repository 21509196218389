import React, { FC, ReactElement, useCallback } from 'react';
import { LinearProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { ACTIVE_DOMAINS_MERCHANTS_TABLE_ROW_HEIGHT } from 'helpers/constants';
import { ActiveDomainMerchantWithActiveDomainInfo } from '../../../reduxState/store/merchant/types';
import ActiveDomainMerchantRow from '../ActiveDomainMerchant/ActiveDomainMerchant';
import ActiveDomainsMerchantsTableHeader from '../ActiveDomainsMerchantsTableHeader/ActiveDomainsMerchantsTableHeader';
import './ActiveDomainsMerchantsTable.scss';

export type ColumnType = {
  accessor: string;
  name: string;
};

export interface ActiveDomainsMerchantsTableProps {
  columns: Array<ColumnType>;
  activeDomainsMerchants: Array<ActiveDomainMerchantWithActiveDomainInfo>;
  searchValue: string;
  initialScrollOffset: number;
  saveScrollOffset: (index: number) => void;
  sortBy: string | null;
  setSortBy: (sortBy: string) => void;
  sortOrder: 'asc' | 'desc';
  setSortOrder: (sortOrder: 'asc' | 'desc') => void;
  isLoading: boolean;
}

export const ActiveDomainsMerchantsTable: FC<React.PropsWithChildren<ActiveDomainsMerchantsTableProps>> = ({
  columns,
  activeDomainsMerchants,
  searchValue,
  initialScrollOffset,
  saveScrollOffset,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  isLoading,
}) => {
  const sortActiveDomainsMerchants = useCallback(
    (accessor: string): void => {
      if (sortBy === accessor) {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
      } else {
        setSortBy(accessor);
        setSortOrder('asc');
      }
    },
    [sortBy, setSortBy, sortOrder, setSortOrder],
  );

  const noActiveDomainsMerchants = (): ReactElement | null => {
    if (!activeDomainsMerchants.length && !isLoading && searchValue) {
      return <div className="no-active-domains">No merchants found.</div>;
    }

    return null;
  };

  return (
    <div className="active-domains-merchants-table-wrapper">
      <Table component="div" className="table">
        <ActiveDomainsMerchantsTableHeader
          columns={columns}
          sortBy={sortBy}
          sortOrder={sortOrder}
          sortHandler={sortActiveDomainsMerchants}
        />
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <TableBody component="div" className="table-body">
            <AutoSizer>
              {/* @ts-ignore */}
              {({ height, width }): ReactElement => {
                return (
                  <List
                    height={height}
                    width={width}
                    itemCount={activeDomainsMerchants.length}
                    itemSize={ACTIVE_DOMAINS_MERCHANTS_TABLE_ROW_HEIGHT}
                    itemData={{
                      items: activeDomainsMerchants,
                      columns,
                    }}
                    onItemsRendered={({ visibleStartIndex }): void => {
                      saveScrollOffset(visibleStartIndex * ACTIVE_DOMAINS_MERCHANTS_TABLE_ROW_HEIGHT);
                    }}
                    initialScrollOffset={initialScrollOffset}
                  >
                    {ActiveDomainMerchantRow}
                  </List>
                );
              }}
            </AutoSizer>
          </TableBody>
        )}
      </Table>
      {!isLoading && noActiveDomainsMerchants()}
    </div>
  );
};

export default ActiveDomainsMerchantsTable;
