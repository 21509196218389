import { formatUTCDate } from 'helpers/DateHelpers';
import { BoostedOffer, CampaignsData } from './types';

export const getCampaignsData = (campaigns: BoostedOffer[]): CampaignsData => {
  const campaignsData: CampaignsData = {
    past: [],
    present: [],
    future: [],
  };

  if (!campaigns.length) return campaignsData;

  const today = formatUTCDate(new Date(), 'yyy-MM-dd HH:mm');
  campaigns.forEach((campaign: BoostedOffer) => {
    const startDate = formatUTCDate(new Date(campaign.StartDate), 'yyy-MM-dd HH:mm');
    const endDate = formatUTCDate(new Date(campaign.EndDate), 'yyy-MM-dd HH:mm');

    if (endDate < today) {
      campaignsData.past.push(campaign);
    } else if (startDate <= today && endDate >= today) {
      campaignsData.present.push(campaign);
    } else if (startDate > today) {
      campaignsData.future.push(campaign);
    }
  });

  return campaignsData;
};
