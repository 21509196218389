import React, { FC, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, FormControlLabel, Menu, MenuItem, RadioGroup, Checkbox } from '@mui/material';
import './MenuWithCheckboxes.scss';

export interface CheckboxOption {
  label: string;
  value: string;
}

interface MenuWithCheckboxesProps {
  label: string;
  options: CheckboxOption[];
  selectedOptions: string[];
  onSelect: (option: CheckboxOption) => void;
  className?: string;
}

const MenuWithCheckboxes: FC<MenuWithCheckboxesProps> = ({ label, options, selectedOptions, onSelect, className }) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div className={`menu-button-dropdown ${className ? className : ''}`}>
      <Button
        sx={{ color: 'black' }}
        aria-controls="menu"
        aria-haspopup
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        className="dropdown-button"
      >
        {label}
      </Button>
      <Menu anchorEl={anchorElement} open={!!anchorElement} onClose={handleClose}>
        <RadioGroup>
          {options.map((option: any, index) => (
            <MenuItem key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => onSelect(option)}
                    color="primary"
                  />
                }
                label={option.label}
                className="dropdown-menu-item"
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Menu>
    </div>
  );
};

export default MenuWithCheckboxes;
