import { PaymentDetailCommission, PaymentDetail, Payment } from './types';

export const isPaymentDetailCommission = (
  commission: PaymentDetailCommission,
): commission is PaymentDetailCommission => {
  const {
    CommissionID,
    TrackingCode,
    CommissionAmount,
    CommissionCurrency,
    EventDate,
    EventType,
    SaleAmount,
    SaleCurrency,
    MerchantName,
  } = commission;
  if (
    (typeof CommissionAmount ||
      typeof CommissionCurrency ||
      typeof TrackingCode ||
      typeof EventDate ||
      typeof EventType ||
      typeof SaleAmount ||
      typeof SaleCurrency ||
      typeof MerchantName) !== 'string' ||
    typeof CommissionID !== 'number'
  ) {
    return false;
  }
  return true;
};

export const isPaymentDetail = (detail: Record<string, any>): detail is PaymentDetail => {
  const { ReportDate, Commissions, TotalSalesAmount, TotalCommissionAmount, TotalCommissionCurrency } = detail;

  if (
    (typeof ReportDate !== 'string' ||
      typeof TotalSalesAmount !== 'string' ||
      typeof TotalCommissionAmount !== 'string' ||
      typeof TotalCommissionCurrency) !== 'string'
  ) {
    return false;
  }

  for (let i = 0; i < Commissions.length; i++) {
    const commission = Commissions[i];
    const checkResult = isPaymentDetailCommission(commission);

    if (!checkResult) {
      return false;
    }
  }

  return true;
};

export const isPayment = (payment: Payment): payment is Payment => {
  const { CreatedDate, Status, PaymentID } = payment;

  if ((typeof CreatedDate || typeof Status) !== 'string' || typeof PaymentID !== 'number') {
    return false;
  }

  return true;
};
