import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Autocomplete, TextField } from '@mui/material';

export interface DropdownSelectOption {
  label: string;
  value: any;
  group?: string;
}

export interface DropdownSearchSelectProps {
  value: DropdownSelectOption | null;
  label: string;
  options: DropdownSelectOption[];
  placeholder: string;
  noOptionsText?: string;
  helperMessage?: string;
  hasError?: boolean;
  errorMessage?: string;
  groupBy?: (option: DropdownSelectOption) => string;
  handleChange: (value: DropdownSelectOption | null) => void;
  isDisabled?: boolean;
}

/**
 * Represents a Textfield that has a searchable dropdown menu when users input text values. Default option
 * will be the passed in placeholder string with a value of ''. The value for the options could be any value.
 * The user will be able to clear the field and will default to the placeholder option.
 *
 * @param props - The properties for DropdownSearchSelect.
 * @param props.value - The selected value the user chooses, defaulted to props.placeholder option when null.
 * @param props.label - The label of the TextField.
 * @param props.options - The options that users are able to search and select from.
 * @param props.placeholder - The string value that the TextField will default to.
 * @param props.noOptionsText - The message that will be displayed when there are no search results.
 * @param props.helperMessage - The message under the TextField to give context of what the values will do.
 * @param props.hasError - Boolean value to know when to display an error message.
 * @param props.errorMessage - The message that will be used when the TextField hasError=true.
 * @param props.groupBy - An optional function to group passed in Options.
 * @param props.handleChange - Callback function for when the value is changed.
 * @param props.isDisabled - Boolean value to disable the TextField
 *
 * @example
 * ```tsx
 * <DropdownSearchSelect
 *  value={null}
 *  label="Nerf Rate"
 *  options={[
 *    {
 *      label: 'United States'
 *      value: 'US'
 *    },
 *    {
 *      label: 'Mexico'
 *      value: 'MX'
 *    }
 *  ]}
 *  placeHolder='Select a Country'
 *  noOptionsText="Country Not Found"
 *  helperMessage=""
 *  hasError={false}
 *  errorMessage="Please select a valid Country."
 *  handleChange={(option) => changeCountry(option)}
 *  isDisabled={false}
 * />
 * ```
 */

const DropdownSearchSelect: FC<DropdownSearchSelectProps> = ({
  options,
  label,
  placeholder,
  hasError,
  errorMessage,
  helperMessage,
  groupBy,
  handleChange,
  isDisabled,
  value,
}) => {
  return (
    <Styled.Container>
      <Autocomplete
        id="single-autocomplete"
        data-testid="autocomplete-search"
        options={options}
        getOptionDisabled={option => !option.value}
        groupBy={groupBy}
        getOptionLabel={option => option.label}
        value={value}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        ListboxProps={{
          sx: {
            maxHeight: '400px',
          },
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                notched: true,
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
              }}
              error={hasError}
              label={label}
              sx={{ my: 1, width: '100%' }}
              helperText={hasError ? errorMessage : helperMessage}
              disabled={isDisabled}
              placeholder={placeholder}
            />
          );
        }}
      />
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div({
    marginBottom: '5px',
  }),
};

export default DropdownSearchSelect;
