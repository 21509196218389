import React, { FC } from 'react';
import TableHead from '@mui/material/TableHead';
import DraggableTableHeader from 'components/DraggableTableHeader/DraggableTableHeader';
import { initialAppGroupCommissionColumns, initialCommissionColumns } from 'reduxState/store/commission/constants';
import { CombinedColumn } from 'reduxState/store/commission/types';

export interface CommissionHistoryTableHeaderProps {
  columns: CombinedColumn[];
  className?: string;
  callbackVisible: boolean;
  updateColumns: (columns?: CombinedColumn[]) => void;
  isInAppGroupOverview: boolean;
}

export const CommissionHistoryTableHeader: FC<CommissionHistoryTableHeaderProps> = ({
  columns,
  className,
  callbackVisible,
  updateColumns,
  isInAppGroupOverview,
}) => {
  const onReorganize = (updatedColumns: CombinedColumn[]): void => {
    const newColumns: CombinedColumn[] = [...updatedColumns];
    const newColumnsSet = new Set(updatedColumns.map(x => x.accessor));
    const initialColumns = isInAppGroupOverview ? initialAppGroupCommissionColumns : initialCommissionColumns;
    initialColumns.forEach((commissionColumn: CombinedColumn) => {
      if (!newColumnsSet.has(commissionColumn.accessor)) {
        newColumns.push({
          ...commissionColumn,
          visible: false,
        });
      }
    });
    updateColumns(newColumns);
  };

  return (
    <TableHead component="div" sx={{ display: 'table-header-group' }} className={`table-header-container ${className}`}>
      <div className={`table-header-row`} style={{ display: 'flex', flex: '1' }}>
        <DraggableTableHeader initialColumns={columns} onReorganize={onReorganize} callbackVisible={callbackVisible} />
      </div>
    </TableHead>
  );
};
