const COLORS = {
  white: '#fff',
  black: '#54565a',
  lightGrey: '#e5e5e5',
  lightGrey2: '#adacac',
  lightGrey3: '#f4f4f4',
  lightGreen: '#33cccc',
  darkGrey: '#868686',
  darkGrey2: '#2c2e30',
  pastelGrey: '#d0d1d2',
  hrefBlue: '#269b9b',
  orange: '#f68935',
  lightPurple: '#544493',
  purple: '#4a3981',
  mediumPurple: '#423574',
  mediumDarkPurple: '#423774',
  darkPurple: '#382f64',
  wildfirePurple: '#3f51b5',
  graphPurple: '#5051F9',
  graphBlue: '#1787bf',
  red: '#e56579',
};

export default COLORS;
