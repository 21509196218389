import React, { FC } from 'react';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import { PRINT_EVENT, CSV_DOWNLOAD_EVENT } from 'components/Portal/PrintWindow/PrintWindow';
import { useGetPaymentDetailQuery } from 'reduxState/store/payment/api';
import { isPaymentDetail } from 'reduxState/store/payment/typeGuards';
import { AppGroup } from 'reduxState/store/user/types';
import './PaymentReport.scss';

interface PaymentReportProps {
  appGroup: AppGroup;
  paymentId: number;
  displayDate: string;
}

const PaymentReport: FC<React.PropsWithChildren<PaymentReportProps>> = ({ appGroup, paymentId, displayDate }) => {
  const { adminName } = appGroup;

  const { data: paymentDetail, isFetching, error } = useGetPaymentDetailQuery({
    appId: appGroup.applications[0].id,
    paymentId,
  });

  const print = (): void => {
    setTimeout(() => document.body.dispatchEvent(new Event(PRINT_EVENT)));
  };

  const downloadCSV = (): void => {
    document.body.dispatchEvent(
      new CustomEvent(CSV_DOWNLOAD_EVENT, {
        detail: paymentDetail,
      }),
    );
  };

  if (isFetching) {
    return (
      <div className="payment-report-loading">
        <Loading />
        <div className="detail">
          <div>Loading...</div>
          <div>This may take some time for large reports.</div>
        </div>
      </div>
    );
  }

  if (error && 'status' in error) {
    switch (error?.status) {
      case 404:
        return (
          <div className="payment-report-loading">
            <div className="detail">
              <div>This payment report is still being generated. Please check back later!</div>
            </div>
          </div>
        );
      default:
        return <Error errorStatusCode={typeof error.status === 'number' ? error.status : 520} />;
    }
  } else if (error || !paymentDetail || !isPaymentDetail(paymentDetail)) return <Error />;

  // Here we will include some conditionally rendered information
  // If the report was created after the rollout date then we render the new fields
  // If the report was created before the rollout date then we do not show the new fields
  const rolloutDate = '2022-06-12T22:25:05.698551Z';
  let paymentReportCreatedAfterRollout = false;

  if (paymentDetail.ReportDate > rolloutDate) {
    paymentReportCreatedAfterRollout = true;
  }

  let appCurrency;
  let saleCurrencyHeader;
  let commissionCurrencyHeader;
  let totalSaleAmount = (
    <td>
      {parseFloat(paymentDetail.TotalSalesAmount).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
    </td>
  );

  if (paymentReportCreatedAfterRollout) {
    appCurrency = <span>&nbsp; {paymentDetail.TotalCommissionCurrency}</span>;
    totalSaleAmount = <td></td>;
    saleCurrencyHeader = <th>Sale&nbsp;Currency</th>;
    commissionCurrencyHeader = <th>Commission&nbsp;Currency</th>;
  }

  let baseCommissionHeader;
  let boostedCommissionHeader;
  const hasBoostedCommissionData =
    paymentDetail.TotalBaseCommissionAmount && paymentDetail.TotalBoostedCommissionAmount;

  if (hasBoostedCommissionData) {
    baseCommissionHeader = <th>Base&nbsp;Commission</th>;
    boostedCommissionHeader = <th>Boosted&nbsp;Commission</th>;
  }

  return (
    <div className="payment-report">
      <div className="head">
        <div className="info">
          <div className="wildlink">
            <img className="logo" src="https://storage.googleapis.com/wildlink/web/logo/purple-logo-text.png" alt="" />
            <div className="address">
              153 S. Sierra Ave #1282
              <br />
              Solana Beach, CA 92075
            </div>
          </div>
          <div className="controls-wrapper">
            <button className="action-button" onClick={print}>
              Print
            </button>
            <button className="action-button" onClick={downloadCSV}>
              Download CSV
            </button>
            <div className="date">{displayDate}</div>
          </div>
        </div>
        <div className="summary">
          <div>
            Payment Details
            {adminName && ` for ${adminName}`}
          </div>
          <div>These amounts have been rounded to the nearest penny. Please download the CSV for precise amounts.</div>
          <div>
            This preview may not display all commissions if there are an extremely large amount to show. For a full
            list, please download the CSV for the report.
          </div>
          <div className="total">
            <div>
              Total Amount Paid: $
              {parseFloat(paymentDetail.TotalCommissionAmount).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              {appCurrency}
            </div>
            {hasBoostedCommissionData && (
              <>
                <div>
                  Total Base Commission Paid: $
                  {parseFloat(paymentDetail.TotalBaseCommissionAmount!).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  {appCurrency}
                </div>
                <div>
                  Total Boosted Commission Paid: $
                  {parseFloat(paymentDetail.TotalBoostedCommissionAmount!).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  {appCurrency}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Event&nbsp;Type</th>
            <th>Merchant&nbsp;Name</th>
            <th>Sale&nbsp;Amount</th>
            {saleCurrencyHeader}
            {baseCommissionHeader}
            {boostedCommissionHeader}
            <th>Commission</th>
            {commissionCurrencyHeader}
            <th>Event&nbsp;Date</th>
            <th>Application&nbsp;ID</th>
            <th>Merchant&nbsp;Order&nbsp;ID</th>
          </tr>
        </thead>
        <tbody>
          {paymentDetail.Commissions.slice(0, 400).map(
            (commission): JSX.Element => (
              <tr key={commission.CommissionID}>
                <td>{commission.CommissionID}</td>
                <td>{commission.EventType}</td>
                <td>{commission.MerchantName}</td>
                <td>
                  {parseFloat(commission.SaleAmount).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </td>
                {paymentReportCreatedAfterRollout && <td>{commission.SaleCurrency}</td>}
                {hasBoostedCommissionData && (
                  <td>
                    {parseFloat(commission.BaseCommissionAmount!).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                )}
                {hasBoostedCommissionData && (
                  <td>
                    {parseFloat(commission.BoostedCommissionAmount!).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                )}
                <td>
                  {parseFloat(commission.CommissionAmount).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </td>
                {paymentReportCreatedAfterRollout && <td>{commission.CommissionCurrency}</td>}
                <td>{commission.EventDate}</td>
                <td>{commission.ApplicationID}</td>
                <td>{commission.MerchantOrderID}</td>
              </tr>
            ),
          )}
          <tr className="sum">
            <td></td>
            <td></td>
            {paymentReportCreatedAfterRollout && <td></td>}
            {!paymentReportCreatedAfterRollout && <td className="total-text">Total</td>}
            {totalSaleAmount}
            {paymentReportCreatedAfterRollout && <td className="total-text">Total</td>}
            <td>
              {parseFloat(paymentDetail.TotalCommissionAmount).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentReport;
