import COLORS from 'constants/colors';
import { ChartOptions, ChartData } from 'chart.js';
import { generateNumberAbbreviationLabel, isPrimeNumber, generateDayLabels, generateMonthLabels } from '.';

interface yAxisChartOptions {
  maxValue: number;
  stepSize: number;
}

export const generateMultiAxisChartOptions = (
  y1ChartOptions: yAxisChartOptions,
  y2ChartOptions: yAxisChartOptions,
  data: ChartData<'line'>,
  xAxisLabels: string[],
  applicationCurrency: string,
  groupBy: string,
): ChartOptions<'line'> => {
  const isLabelLengthAPrimeNumber = isPrimeNumber(xAxisLabels.length - 1);
  const addCurrencyInLabel = new Set(['Gross Commissions', 'Gross Sales']);
  return {
    responsive: true, // Resizes when window grows/shrinks
    maintainAspectRatio: false, // Makes graph fit within a given space
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        padding: 13,
        titleFont: {
          family: 'Montserrat',
          size: 16,
        },
        bodyFont: {
          family: 'Montserrat',
          size: 16,
        },
        mode: 'index',
        callbacks: {
          label: context => {
            const datasetIndex = context.datasetIndex;
            const value = context.formattedValue as string;
            const datasetLabel = data.datasets[datasetIndex]?.label || '';
            if (addCurrencyInLabel.has(datasetLabel)) return ` ${datasetLabel}: ${value} ${applicationCurrency}`;
            return ` ${datasetLabel}: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false, // Hides X-axis grid lines
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          align: 'end',
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: (tickValue, index, values) => {
            switch (groupBy) {
              case 'month':
                return generateMonthLabels(index, xAxisLabels, xAxisLabels.length, isLabelLengthAPrimeNumber);
              case 'day':
                return generateDayLabels(index, xAxisLabels, xAxisLabels.length);
            }
          },
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          drawBorder: false,
        },
        min: 0,
        max: y1ChartOptions.maxValue,
        ticks: {
          stepSize: y1ChartOptions.stepSize,
          color: COLORS.lightPurple,
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: tickValue => {
            const y1MaxValueLength = y1ChartOptions.maxValue.toString().length;
            const label = generateNumberAbbreviationLabel(Number(tickValue), y1MaxValueLength);
            return label;
          },
        },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawBorder: false,
        },
        min: 0,
        max: y2ChartOptions.maxValue,
        ticks: {
          stepSize: y2ChartOptions.stepSize,
          color: COLORS.graphBlue,
          font: {
            family: 'Montserrat',
            size: 16,
          },
          callback: tickValue => {
            const y2MaxValueLength = y2ChartOptions.maxValue.toString().length;
            const label = generateNumberAbbreviationLabel(Number(tickValue), y2MaxValueLength);
            return label;
          },
        },
      },
    },
  };
};
