import React from 'react';
import { CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import './Loading.scss';

const Loading = (props: CircularProgressProps): JSX.Element => (
  <div className="loading" data-testid="loading-icon">
    <CircularProgress {...props} />
  </div>
);

export default Loading;
