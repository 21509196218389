import React, { createContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import classnames from 'classnames';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import Main from 'pages/Main/Main';
import 'react-toastify/dist/ReactToastify.min.css';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const IsMobileContext = createContext(false);

// https://www.notion.so/Design-Internal-References-Links-f9e0cb439dd745a386b3703782fbfaf1
const theme = createTheme({
  palette: {
    primary: {
      main: '#3A2D80',
      light: '#DFDDEE',
    },
    secondary: {
      main: '#1787bf',
      light: '#DFDDEE',
    },
  },
});

const AppContent = (): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = !isDesktop;

  const className = classnames('app', { mobile: isMobile });
  return (
    <IsMobileContext.Provider value={isMobile}>
      <div className={className}>
        <Main />
        <ToastContainer />
      </div>
    </IsMobileContext.Provider>
  );
};

const App = (): JSX.Element => {
  ReactGA.initialize('G-0NEZCTKEN8');
  return (
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppContent />
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

export default App;
