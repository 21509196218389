import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Auth0Provider, AppState, User } from '@auth0/auth0-react';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from 'helpers/constants';

const AuthProviderWithNavigate: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const domain = AUTH0_DOMAIN;
  const clientId = AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState, user?: User): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/login`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderWithNavigate;
