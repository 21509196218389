import React, { FC, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';
import { toast } from 'react-toastify';
import AppButtonsList from 'components/AppGroupOverview/AppButtonsList/AppButtonsList';
import AppGroupHeader from 'components/AppGroupOverview/AppGroupHeader/AppGroupHeader';
import AppGroupCommissionHistory from 'components/AppGroupOverview/CommissionHistory/CommissionHistory';
import AppGroupCommissionHistoryDownload from 'components/AppGroupOverview/CommissionHistoryDownload/CommissionHistoryDownload';
import PerformanceSummaryTable from 'components/AppGroupOverview/PerformanceSummaryTable/PerformanceSummaryTable';
import Loading from 'components/Loading/Loading';
import NotFound from 'components/NotFound/Notfound';
import RedirectToPath from 'components/Route/RedirectToPath';
import StatCards from 'components/StatCards/StatCards';
import { Columns } from 'helpers';
import { exportJSONToCSV, downloadCSV } from 'helpers';
import { DATE_FORMAT } from 'helpers/constants';
import { useAppSelector } from 'reduxState/hooks';
import { useGetAppsStatsQuery } from 'reduxState/store/performanceSummary/api';
import { initialNonAPIColumns } from 'reduxState/store/performanceSummary/constants';
import { performanceSummaryFilterValuesSelector } from 'reduxState/store/performanceSummary/selectors';
import {
  getPrevInterval,
  isPerformanceSummaryReportKey,
  isPerformanceSummaryReportSumByAppKey,
  PerformanceSummaryReport,
  PerformanceSummaryReportSumByApp,
} from 'reduxState/store/performanceSummary/utils';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { RestrictedUserRoles } from 'reduxState/store/user/constants';
import { selectUserRestrictedRole } from 'reduxState/store/user/selectors';
import { AppGroup } from 'reduxState/store/user/types';
import './AppGroupOverview.scss';

const AppGroupOverview: FC = () => {
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [viewByApplication, setViewByApplication] = useState(false);
  const params = useParams();
  const adminAppId = params.adminAppId || '';

  const userRestrictedRole = useAppSelector(selectUserRestrictedRole);
  const { startDate, endDate, sortBy, sortOrder, groupBy } = useAppSelector(performanceSummaryFilterValuesSelector);
  const { start: prevStart, end: prevEnd } = getPrevInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });

  const { data: applicationsData, isFetching: isApplicationDataFetching } = useGetUserApplicationsQuery();
  const allAppGroups: AppGroup[] = applicationsData?.appGroups || [];
  const appGroup =
    allAppGroups.find((appGroup: AppGroup) => appGroup.adminId.toString() === adminAppId) || ({} as AppGroup);

  const { data, isFetching, error } = useGetAppsStatsQuery(
    {
      filters: {
        start_date: startDate,
        end_date: endDate,
        sort_by: sortBy,
        sort_order: sortOrder,
        application_ids: selectedApps,
        group_by: viewByApplication ? 'day' : groupBy === 'merchants' ? 'day' : groupBy, // default to day since merchants is currently not supported yet
      },
      adminAppId,
    },
    { skip: !adminAppId || selectedApps.length === 0 },
  );

  const { data: prevIntervalData } = useGetAppsStatsQuery(
    {
      filters: {
        start_date: prevStart.toString(),
        end_date: prevEnd.toString(),
        sort_by: sortBy,
        sort_order: sortOrder,
        application_ids: selectedApps,
        group_by: viewByApplication ? 'day' : groupBy === 'merchants' ? 'day' : groupBy, // default to day since merchants is currently not supported yet
      },
      adminAppId,
    },
    { skip: !adminAppId || selectedApps.length === 0 },
  );

  const downloadCSVFile = () => {
    if ((viewByApplication && !data?.statsByApp) || !data?.flattenedAppStats) {
      return;
    }

    let csv;
    if (viewByApplication) {
      const columnKeys: (keyof PerformanceSummaryReportSumByApp)[] = [];
      initialNonAPIColumns.forEach(({ accessor }) => {
        // replace ReportDate with ApplicationName
        if (accessor === 'ReportDate') {
          columnKeys.push('ApplicationName');
        } else if (typeof accessor === 'string' && isPerformanceSummaryReportSumByAppKey(accessor)) {
          columnKeys.push(accessor);
        }
      });

      csv = exportJSONToCSV(
        data?.statsByApp,
        columnKeys.map((key: keyof PerformanceSummaryReportSumByApp) => ({
          accessor: key,
          name: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        })),
      );
    } else {
      const statReportColumns: Columns<PerformanceSummaryReport>[] = [];
      initialNonAPIColumns.forEach(({ accessor, name }) => {
        if (typeof accessor === 'string' && isPerformanceSummaryReportKey(accessor)) {
          statReportColumns.push({ accessor, name });
        }
      });
      csv = exportJSONToCSV(data?.flattenedAppStats, statReportColumns);
    }

    if (!csv) return toast.error('There was an issue downloading your CSV data. Please try again.');

    const documentName = `${appGroup?.adminName.split(' ').join('_')}_Performance_${format(
      new Date(startDate),
      DATE_FORMAT,
    )}_to_${format(new Date(endDate), DATE_FORMAT)}`;

    downloadCSV(csv, documentName);
  };

  if (isApplicationDataFetching && _.isEmpty(appGroup)) {
    return <Loading />;
  }

  if (!isApplicationDataFetching && _.isEmpty(appGroup)) {
    return <NotFound />;
  }

  const currency = data?.appStats[0]?.ApplicationCurrency || 'USD';
  const currentIntervalDataStats = viewByApplication ? data?.statsByApp || [] : data?.flattenedAppStats || [];
  const prevIntervalDataStats = viewByApplication
    ? prevIntervalData?.statsByApp || []
    : prevIntervalData?.flattenedAppStats || [];

  // for entity 74 restricted users
  const isRestrictedOpsUser = userRestrictedRole === RestrictedUserRoles.OPERATIONS;
  const restrictedPage = <div>You do not have access to view this page.</div>;

  return (
    <div className="app-group-overview">
      <h1 className="text-muted-dark-purple">Welcome back, {appGroup.adminName}!</h1>
      <AppButtonsList appGroup={appGroup} isRestrictedUser={isRestrictedOpsUser} />
      <Routes>
        <Route
          key="app-group-summary"
          path="app-group-summary"
          element={
            !isRestrictedOpsUser
              ? [
                <AppGroupHeader
                  key="app-group-header"
                  appGroup={appGroup}
                  selectedApps={selectedApps}
                  viewByApplication={viewByApplication}
                  setViewByApplication={setViewByApplication}
                  setSelectedApps={setSelectedApps}
                  downloadCSVFile={downloadCSVFile}
                />,
                <StatCards
                  key="stat-cards"
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  currency={currency}
                  stats={!selectedApps.length ? [] : currentIntervalDataStats}
                  prevIntervalStats={!selectedApps.length ? [] : prevIntervalDataStats}
                  horizontalScroll
                />,
                <PerformanceSummaryTable
                  key="performance-summary-table"
                  selectedApps={selectedApps}
                  adminAppId={adminAppId}
                  currency={currency}
                  flattenedAppGroupPerformanceSummary={!selectedApps.length ? [] : currentIntervalDataStats}
                  viewByApplication={viewByApplication}
                  isLoading={isFetching}
                  error={error}
                />,
              ]
              : restrictedPage
          }
        />
        <Route
          key="app-group-commission-history"
          path="app-group-commission-history"
          element={<AppGroupCommissionHistory appGroup={appGroup} />}
        />
        <Route
          key="app-group-commission-download"
          path="app-group-commission-download"
          element={<AppGroupCommissionHistoryDownload adminId={adminAppId} appName={appGroup.adminName} />}
        />
        <Route
          key="app-group-overview"
          path="/*"
          element={
            <RedirectToPath
              path={
                isRestrictedOpsUser ? `/${adminAppId}/app-group-commission-history` : `/${adminAppId}/app-group-summary`
              }
            />
          }
        />
      </Routes>
    </div>
  );
};

export default AppGroupOverview;
