import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxState';
import { AppGroupCommissionState, CommissionState } from './types';

export const selectCommissionState = (state: RootState): CommissionState => state.commission;
export const selectAppGroupCommissionState = (state: RootState): AppGroupCommissionState => state.appGroupCommission;

export const commissionColumnsSelector = createSelector(selectCommissionState, commissionState => {
  const commissionStateColumns = commissionState.columns;
  return commissionStateColumns || [];
});

export const appGroupCommissionColumnsSelector = createSelector(
  selectAppGroupCommissionState,
  appGroupCommissionState => {
    const commissionStateColumns = appGroupCommissionState.columns;
    return commissionStateColumns || [];
  },
);
