import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import COLORS from 'constants/colors';
import styled from '@emotion/styled';
import ApplicationThumbnail from 'components/Application/ApplicationThumbnail/ApplicationThumbnail';
import Loading from 'components/Loading/Loading';
import { useAppSelector } from 'reduxState/hooks';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { selectUserToken } from 'reduxState/store/user/slice';
import { AppGroup } from 'reduxState/store/user/types';

const ApplicationGroups: FC = () => {
  const navigate = useNavigate();
  const accessToken = useAppSelector(selectUserToken);

  const { data: applicationsData, isLoading: isAppsLoading } = useGetUserApplicationsQuery(undefined, {
    skip: !accessToken,
  });
  const appGroups: AppGroup[] = applicationsData?.appGroups || [];

  if (appGroups.length === 1 && !isAppsLoading) {
    navigate(`/${appGroups[0].adminId}`);
  }

  const renderContent = () => {
    if (isAppsLoading) return <Loading />;

    if (!appGroups.length) {
      return <Styled.Empty>You don't have any application groups.</Styled.Empty>;
    }

    return appGroups.map((appGroup: AppGroup) => {
      const { adminName, adminId } = appGroup;
      return <ApplicationThumbnail name={adminName} linkPath={`/${adminId}`} key={adminId} />;
    });
  };

  return (
    <Styled.ApplicationGroups>
      <Styled.Title>Application Groups</Styled.Title>
      <Styled.ApplicationList>{renderContent()}</Styled.ApplicationList>
    </Styled.ApplicationGroups>
  );
};

export default ApplicationGroups;

const Styled = {
  ApplicationGroups: styled.div({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  }),
  Title: styled.h2({
    width: '100%',
    marginBottom: '30px',
    paddingBottom: '10px',
    borderBottom: `2px solid rgba(134, 134, 134, .1)`,
    color: COLORS.mediumDarkPurple,
    fontSize: '42px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
  }),
  ApplicationList: styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    column-gap: 25px;
  `,
  Empty: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
  `,
};
