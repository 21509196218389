import React from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Toolbar } from '@mui/material';
import { isEmpty } from 'lodash';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { useGetUserApplicationsQuery } from 'reduxState/store/user/api';
import { Application, AppGroup } from 'reduxState/store/user/types';
import ApplicationDropdownMenu from './ApplicationDropdownMenu/ApplicationDropdownMenu';
import NavTabs from './NavTabs/NavTabs';
import OverflowMenu from './OverflowMenu/OverflowMenu';
import logo from '../../static/images/white-flame-logo.png';
import './Header.scss';

const Header = (): React.ReactElement => {
  const { user, isLoading } = useAuth0();
  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  const { adminAppId, appId } = useParams();

  const { data: applicationsData } = useGetUserApplicationsQuery();

  if (!applicationsData) return <></>;

  const allAppGroups: AppGroup[] = applicationsData?.appGroups || [];
  const appGroup =
    allAppGroups.find((appGroup: AppGroup) => appGroup?.adminId.toString() === adminAppId) || ({} as AppGroup);
  const appGroupApps: Application[] = appGroup?.applications || [];

  const homeUrl = !hasWfPermissions && allAppGroups.length === 1 ? `/${allAppGroups[0].adminId}` : '/';

  const isApplicationDropdownVisible = !!adminAppId && !isEmpty(appGroup);
  const isNavTabsVisible = !!adminAppId && !isEmpty(appGroup);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar className="header">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={homeUrl} className="wf-logo" role="button">
              <div className="logo-img-container">
                <img src={logo} alt="logo" />
              </div>
            </Link>
            <div className="flex items-center relative top-[-2px]">
              {isApplicationDropdownVisible && !isLoading && (
                <ApplicationDropdownMenu
                  appId={appId}
                  adminAppId={adminAppId}
                  applications={appGroupApps}
                  appGroupName={appGroup.adminName}
                  key="application-dropdown"
                />
              )}
              {isNavTabsVisible && <NavTabs appId={appId} adminAppId={adminAppId} key="nav-tabs" />}
            </div>
          </div>
          <OverflowMenu appId={appId} hasWfPermissions={hasWfPermissions} adminAppId={adminAppId} />
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default Header;
