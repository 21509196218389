import React, { FC } from 'react';
import TableCell from 'components/TableCell/TableCell';
import TruncatedTableCellContent from 'components/TableCell/TruncatedTableCellContent';
import TableRow from 'components/TableRow/TableRow';
import { parseCurrencyAmount } from 'helpers';
import { formatUTCDate } from 'helpers/DateHelpers';
import {
  PerformanceSummaryColumn,
  PerformanceSummaryGroupBy,
} from '../../../reduxState/store/performanceSummary/types';
import { PossiblePerformanceSummaryTypes } from '../types';

interface OwnProps {
  summaryItem: PossiblePerformanceSummaryTypes;
  columns: PerformanceSummaryColumn[];
  currency: string;
  groupBy: PerformanceSummaryGroupBy;
}

const PerformanceSummaryItem: FC<React.PropsWithChildren<OwnProps>> = ({ summaryItem, columns, groupBy, currency }) => {
  const dateFormat = groupBy === 'month' ? 'MMM-yyyy' : 'dd-MMM-yyyy';

  // Since the incoming stats are a union type, we need to confirm that the column.accessor exist on the incoming stat.
  // If it doesnt exist and the columns to render with it, it will default to an empty string
  const renderContent = (columnAccessor: string): JSX.Element => {
    switch (columnAccessor) {
      // BasePerformanceSummaryTypes
      case 'NetSalesAmount':
      case 'GrossSalesAmount':
      case 'NetCommissionAmount':
      case 'BaseCommissionAmount':
      case 'BoostedCommissionAmount':
      case 'GrossCommissionAmount': {
        return <span>{parseCurrencyAmount(summaryItem[columnAccessor], currency)}</span>;
      }

      case 'OrderCount': {
        return 'OrderCount' in summaryItem ? <span>{summaryItem[columnAccessor]}</span> : <></>;
      }
      case 'ApplicationID': {
        return 'ApplicationID' in summaryItem ? <span>{summaryItem[columnAccessor]}</span> : <></>;
      }

      // ReportDate is in SelectedMerchantPerformanceSummary & PerformanceSummaryByDate
      case 'ReportDate': {
        return 'ReportDate' in summaryItem ? (
          <span>{formatUTCDate(new Date(summaryItem['ReportDate']), dateFormat)}</span>
        ) : (
          <></>
        );
      }

      // PerformanceSummaryByDate properties
      case 'ShortURLsCreated': {
        return 'ShortURLsCreated' in summaryItem ? <span>{summaryItem['ShortURLsCreated']}</span> : <></>;
      }
      case 'DevicesCreated': {
        return 'DevicesCreated' in summaryItem ? <span>{summaryItem['DevicesCreated']}</span> : <></>;
      }
      case 'WildlinkClicks': {
        return 'WildlinkClicks' in summaryItem ? <span>{summaryItem['WildlinkClicks']}</span> : <></>;
      }
      case 'ExtensionInstalls': {
        return 'ExtensionInstalls' in summaryItem ? <span>{summaryItem['ExtensionInstalls']}</span> : <></>;
      }
      case 'ExtensionUninstalls': {
        return 'ExtensionUninstalls' in summaryItem ? <span>{summaryItem['ExtensionUninstalls']}</span> : <></>;
      }

      // PerformanceSummaryGroupedByMerchant & SelectedMerchantPerformanceSummary properties
      case 'MerchantID': {
        return 'MerchantID' in summaryItem ? <span>{summaryItem['MerchantID']}</span> : <></>;
      }
      case 'MerchantClicks': {
        return 'MerchantClicks' in summaryItem ? <span>{summaryItem['MerchantClicks']}</span> : <></>;
      }
      case 'MerchantName': {
        return 'MerchantName' in summaryItem ? <span>{summaryItem['MerchantName']}</span> : <></>;
      }

      case 'ApplicationName': {
        return 'ApplicationName' in summaryItem ? (
          <TruncatedTableCellContent
            content={summaryItem['ApplicationName']}
            tooltipMessage={summaryItem['ApplicationName']}
            canCopy={true}
          />
        ) : (
          <></>
        );
      }

      // Makes sure that we don't skip a property that exists
      default: {
        const summaryItemCopy = { ...summaryItem } as Record<string, any>;
        return columnAccessor in summaryItemCopy ? <span>{summaryItemCopy[columnAccessor]}</span> : <></>;
      }
    }
  };

  return (
    <TableRow>
      {columns.map(column => (
        <TableCell key={column.accessor}>{renderContent(column.accessor.toString())}</TableCell>
      ))}
    </TableRow>
  );
};

export default PerformanceSummaryItem;
