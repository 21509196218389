import React, { FC, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { Routes, Route, useNavigate, useMatch, useParams } from 'react-router-dom';
import { Error as ErrorIcon, GetApp, Search } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Error from 'components/Error/Error';
import MerchantRateDetails from 'components/Merchants/MerchantRateDetails/MerchantRateDetails';
import TextField from 'components/TextField/TextField';
import { downloadCSV, exportJSONToCSV, parseCommissionRate } from 'helpers';
import { useGetActiveDomainsQuery } from 'reduxState/store/merchant/api';
import { getActiveDomainInfo } from 'reduxState/store/merchant/utils';
import ActiveDomainsMerchantsTable from './ActiveDomainsMerchantsTable/ActiveDomainsMerchantsTable';
import {
  ActiveDomainMerchantWithActiveDomainInfo,
  ConvertedActiveDomainMerchantWithActiveDomainInfo,
} from '../../reduxState/store/merchant/types';
import './Merchants.scss';

export interface MerchantsProps {
  appId: string;
  appName: string;
}

interface ActiveDomainMerchantColumn {
  accessor: keyof ConvertedActiveDomainMerchantWithActiveDomainInfo;
  name: string;
}

const columns: ActiveDomainMerchantColumn[] = [
  { accessor: 'ID', name: 'ID' },
  { accessor: 'Domain', name: 'Domain' },
  { accessor: 'Name', name: 'Name' },
  { accessor: 'MaxRate', name: 'Max Rate' },
];

const Merchants: FC<React.PropsWithChildren<MerchantsProps>> = ({ appId, appName }) => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [tableScrollOffset, setTableScrollOffset] = useState(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const params = useParams();
  const adminAppId = params.adminAppId ?? '';

  const [downloading, setDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const isExact = useMatch(`/${adminAppId}/app/${appId}/merchants`) !== null;
  const navigate = useNavigate();
  const { data, error: activeDomainError, isFetching: isActiveDomainsFetching, refetch } = useGetActiveDomainsQuery({
    appId: appId,
  });

  const sortedActiveDomains = useMemo(() => getActiveDomainInfo(data || [], sortBy, sortOrder, searchValue), [
    data,
    sortBy,
    sortOrder,
    searchValue,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeHandler = useCallback(
    _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setSearchValue(event.target.value);
    }, 500),
    [],
  );

  const parseMerchantRates = (merchants: ActiveDomainMerchantWithActiveDomainInfo[]) => {
    const convertedActiveDomainsMerchants: ConvertedActiveDomainMerchantWithActiveDomainInfo[] = [];
    merchants.forEach((merchant: ActiveDomainMerchantWithActiveDomainInfo) => {
      const data = {
        ID: merchant.ID,
        Domain: merchant.Domain,
        Name: merchant.Name,
        DefaultRate: parseCommissionRate(merchant.DefaultRate),
        DerivedRate: parseCommissionRate(merchant.DerivedRate),
        MaxRate: parseCommissionRate(merchant.MaxRate),
      };
      convertedActiveDomainsMerchants.push(data);
    });
    return convertedActiveDomainsMerchants;
  };

  const exportToCsv = () => {
    setDownloading(true);

    const convertedMerchants = parseMerchantRates(sortedActiveDomains);
    const csv = exportJSONToCSV(convertedMerchants, columns);

    if (csv) {
      downloadCSV(csv, `${appName.split(' ').join('_')}_Merchants.csv`);
    } else {
      setDownloadError(true);
      setTimeout((): void => setDownloadError(false), 3000);
    }

    setDownloading(false);
  };

  if (activeDomainError) {
    toast.error('Failed to retrieve active domains.');
    console.error(activeDomainError);
  }

  return activeDomainError ? (
    <Error retry={() => refetch()} />
  ) : (
    <div className="merchants">
      <div className="merchants-header">
        <div className="merchants-title-block">
          <h1
            className="text-muted-dark-purple cursor-pointer"
            onClick={(): void => navigate(`/${adminAppId}/app/${appId}/merchants`)}
          >
            Merchants
          </h1>
        </div>

        {isExact && (
          <TextField
            className="table-filter-field merchants-table-filter-field"
            variant="outlined"
            placeholder="Search merchants"
            InputProps={{
              endAdornment: <Search />,
            }}
            onChange={(event): void => {
              event.persist();
              setInputValue(event.target.value);
              onChangeHandler(event);
            }}
            value={inputValue}
          />
        )}

        {isExact && (
          <Button
            className="button-table-action"
            disabled={sortedActiveDomains.length === 0 || downloadError || downloading}
            onClick={exportToCsv}
          >
            {downloadError ? <ErrorIcon /> : <GetApp />}
            <div className="text">Export</div>
            {downloading && <CircularProgress className="progress" size={24} />}
          </Button>
        )}
      </div>
      <Routes>
        <Route path=":merchantId" element={<MerchantRateDetails applicationId={appId} />} />
      </Routes>
      <ActiveDomainsMerchantsTable
        columns={columns}
        activeDomainsMerchants={sortedActiveDomains}
        searchValue={searchValue}
        initialScrollOffset={tableScrollOffset}
        saveScrollOffset={setTableScrollOffset}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isLoading={isActiveDomainsFetching}
      />
    </div>
  );
};

export default Merchants;
