import React, { useState } from 'react';
import { FilterNone, Check } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import './TruncatedTableCellContent.scss';

interface TruncatedTableCellContentProps {
  content: string | number | undefined;
  canCopy?: boolean;
  tooltipMessage?: string;
  style?: 'primary' | 'secondary';
}

const TruncatedTableCellContent = ({
  content,
  canCopy,
  tooltipMessage,
  style = 'primary',
}: TruncatedTableCellContentProps): JSX.Element => {
  const [wasCopiedRecently, setCopiedRecently] = useState<boolean>(false);
  const contentText = content?.toString() || '';
  const handleCopy = (e: React.MouseEvent) => {
    setCopiedRecently(true);
    navigator.clipboard.writeText(contentText);
    setTimeout(() => setCopiedRecently(false), 1000);
  };

  const renderContent = () => (
    <div className="table-cell-no-scroll">
      <span className={`truncate-text ${style === 'secondary' ? 'secondary' : ''}`}>{contentText}</span>
      {canCopy && (
        <span className={`copy-icon ${wasCopiedRecently ? 'copied' : ''}`} title="Copy" onClick={handleCopy}>
          {wasCopiedRecently ? <Check fontSize="small" /> : <FilterNone fontSize="small" />}
        </span>
      )}
    </div>
  );

  return tooltipMessage ? (
    <Tooltip sx={{ fontSize: '14px' }} title={content}>
      {renderContent()}
    </Tooltip>
  ) : (
    renderContent()
  );
};

export default TruncatedTableCellContent;
