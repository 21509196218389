import React, { FC } from 'react';
import classNames from 'classnames';
import { Merchant, ActiveDomainMerchant } from '../../../reduxState/store/merchant/types';
import './MerchantLogoPlaceholder.scss';

export interface MerchantLogoPlaceholderProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  merchant: Merchant | {};
}

const MerchantLogoPlaceholder: FC<React.PropsWithChildren<MerchantLogoPlaceholderProps>> = ({ merchant }) => {
  const { Name = ' ', ID = 0 } = merchant as Merchant | ActiveDomainMerchant;

  const fallbackColorMap = ['seafoam', 'green', 'blue', 'pink', 'purple', 'orange'];

  const merchantLogoPlaceholderClass = classNames('merchant-logo-placeholder', {
    [fallbackColorMap[ID % 6]]: ID !== 0,
  });

  return <div className={merchantLogoPlaceholderClass}>{Name[0]}</div>;
};

export default MerchantLogoPlaceholder;
