import React, { FC, useState } from 'react';
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import '../scss/ConfirmEdit.scss';

export interface ConfirmEditProps {
  headerText: string;
  confirmText: string;
  changeText: string;
  onSave: () => void;
}

export const ConfirmEdit: FC<React.PropsWithChildren<ConfirmEditProps>> = ({
  confirmText,
  changeText,
  onSave,
  headerText,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    onSave();
    handleClose();
  };

  return (
    <div className="confirm-edit-modal">
      <Button onClick={handleOpen} className="save-button" variant="outlined">
        Save
      </Button>
      <Modal
        aria-labelledby="confirm-edit-modal-title"
        aria-describedby="confirm-edit-modal-body"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        role="alert"
      >
        <Fade in={open}>
          <Box className="confirm-edit-modal-box">
            <div className="confirm-edit-modal-header">
              <Typography id="confirm-edit-modal-title" className="confirm-edit-modal-title">
                {headerText}
              </Typography>
            </div>
            <div className="confirm-edit-modal-body">
              <Typography>{confirmText}</Typography>
              <Typography className="change-text" style={{ overflowWrap: 'break-word' }}>
                {changeText}
              </Typography>
              <div className="action-button-container ">
                <Button className="confirm-button" onClick={handleSave} variant={'outlined'}>
                  Confirm
                </Button>
                <Button className="cancel-button" onClick={handleClose} variant={'outlined'}>
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
